import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import PageLoader from "components/common/PageLoader";
import PayoneerCreateAccount from "./Payoneer/PayoneerCreateAccount/PayoneerCreateAccount";
import PayoneerStatusCard from "./Payoneer/PayoneerStatusCard/PayoneerStatusCard";
import { AlertType } from "components/common/Snackbar";

import { getEmployeePayoneerStatus } from "services/Payoneer";
import { showSnackbar } from "store/actions/Utility";

import backButtonIcon from "assets/images/left-arrow.svg";

import "./EmployeePayoneer.css";

function EmployeePayoneer() {
  const [loading, setLoading] = useState(false);
  const [payoneerDetail, setPayoneerDetail] = useState(null);
  const [isError, setIsError] = useState(false);
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function EmployeePayoneerStatus() {
    try {
      setLoading(true);
      const payoneerDetail = await getEmployeePayoneerStatus();
      setPayoneerDetail(payoneerDetail);
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    EmployeePayoneerStatus();
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Wallet</title>
      </Helmet>
      {userAdditionalInfo?.Allowed_For_Payoneer ? (
        <>
          <div className="employee-payoneer">
            <div className="employee-paystubs-details-dA">
              <div>
                <div className="employee-paystubs-details-dAA">
                  <Box component="img" src={backButtonIcon} onClick={() => navigate(-1)} />
                  <div className="employee-paystubs-details-slips">Edge Wallet</div>
                </div>
                <div className="employee-payment-methods-details">Add and view your salary account details</div>
              </div>
            </div>
            {loading && !payoneerDetail?.status ? (
              <PageLoader />
            ) : (
              <>
                {isError ? null : payoneerDetail?.status === "Not registered" ? (
                  <PayoneerCreateAccount />
                ) : (
                  <>{payoneerDetail?.consentStatus && <PayoneerStatusCard payoneerAccountDetail={payoneerDetail} />}</>
                )}
              </>
            )}
          </div>
        </>
      ) : null}
    </>
  );
}

export default EmployeePayoneer;
