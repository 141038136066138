import { Divider, Tooltip, IconButton, Avatar } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { EmployeeExperience } from "../../../store/actions/EmployeeExperience";

import "./EmployeeExperience.css";
import { usePermissions } from "../../Hook";
import { baseUrl } from "../../../util/APIBaseUrl";
import { convertDate, getValue } from "../CommonUtils";
import PageLoader from "components/common/PageLoader";

function EmployeeExperienceCom(props) {
  const dispatch = useDispatch();

  const { loggedInUser } = usePermissions();

  const EmployeeExp = useSelector((state) => state.EmployeeExperience);

  React.useEffect(() => {
    const employeeId = loggedInUser?.type === "employee" ? loggedInUser.userId : props.employeeId;
    dispatch(EmployeeExperience(`employee/${employeeId}/employmentHistory`));
  }, []);
  return (
    <>
      {EmployeeExp.isLoadingData ? (
        <PageLoader />
      ) : (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "24px",
            padding: "10px 25px 0px 25px",
          }}
        >
          <div className="employee-exp-title">Experiences at Edge</div>
          <div className="employee-exp-list">
            {EmployeeExp?.error || !EmployeeExp?.prevExperiences?.length ? (
              <div>No Previous Experience</div>
            ) : (
              <>
                {EmployeeExp?.prevExperiences?.map((exp) => (
                  <>
                    <div className="employee-exp-row">
                      <div className="employee-exp-row-left">
                        <Tooltip title="Customer logo">
                          <IconButton>
                            <Avatar
                              // className="employee-exp-customer-logo"
                              alt="User Details"
                              src={`${baseUrl}/customer/${exp?.customer?.customerID}/logo?id=${exp?.customer?.pictureVersion}&variant=thumbnail`}
                            />
                          </IconButton>
                        </Tooltip>
                        <div>
                          <div className="employee-exp-row-title">{getValue(exp?.customer?.companyName)}</div>
                          <div className="employee-exp-row-design">Client Associate</div>
                        </div>
                      </div>
                      <div className="employee-exp-row-right">
                        {" "}
                        {convertDate(exp?.joiningDate)} - {exp?.endDate ? convertDate(exp?.endDate) : "Present"}
                      </div>
                    </div>
                    <Divider light />
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default EmployeeExperienceCom;
