import { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ContractIcon from "../../../Employee/EmployeeSvgIcons/ContractIcon";
import TextFieldV2 from "../../../../common/TextFieldV2/TextFieldV2";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel } from "@material-ui/core";
import { baseUrl } from "../../../../../util/APIBaseUrl";
import { useParams } from "react-router-dom";
import postRequest from "../../../../../util/APIHelperPost";
import { EmployeeDocumentsRequest } from "../../../../../store/actions/EmployeePortal/EmployeeDocuments";
import { useMemo } from "react";
import NdaIcon from "../../../Employee/EmployeeSvgIcons/NdaIcon";
import PersonalIdIcon from "../../../Employee/EmployeeSvgIcons/PersonalIdIcon";
import DocumentIcon from "../../../../common/Widgets/DocumentIcon";
import { documentTpeEnum } from "../../../../common/Widgets/DocumentTpe.enum";
import PageLoader from "../../../../common/PageLoader";

function EditDocument(props) {
  const { openEditDocument, setOpenEditDocument, editDocument } = props;
  const params = useParams();
  const dispatch = useDispatch();

  const [documentDetails, setDocumentDetails] = useState({});

  const handleCloseDocument = () => {
    setOpenEditDocument(false);
  };

  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);

  const documentOptions = useMemo(() => {
    const options = platformConfig?.payload?.DOCUMENT_TYPE?.split(",") || [];
    options.push("Other");
    return options;
  }, [platformConfig]);

  useEffect(() => {
    if (editDocument) {
      setDocumentDetails({
        // ...documentDetails,
        documentName: editDocument?.documentName,
        documentId: editDocument?.documentId,
        documentType: editDocument?.documentType,
        shareWithEmployee: editDocument?.shareWithEmployee || false,
        shareWithCustomer: editDocument?.shareWithCustomer || false,
      });
    }
  }, [editDocument, documentOptions]);

  const CustomPopperComponent = (props) => {
    return (
      <Paper
        {...props}
        square
        style={{
          marginTop: 4,
          zIndex: 9999,
          border: "0.5px solid #B2B4E2",
          borderRadius: "12px",
          boxShadow: "0px 1px 1px rgba(2, 0, 110, 0.22), 0px 1px 6px rgba(2, 0, 110, 0.25)",
        }}
      >
        {props.children}
      </Paper>
    );
  };

  const handleSave = async () => {
    const body = JSON.stringify({
      documentName: documentDetails?.documentName,
      documentType: documentDetails?.documentType,
      shareWithEmployee: documentDetails?.shareWithEmployee,
      shareWithCustomer: documentDetails?.shareWithCustomer,
    });
    const response = await postRequest(
      `${baseUrl}/employees/${params?.id}/document/${editDocument?.documentId}`,
      body,
      true,
      "PATCH"
    );

    if (!response?.ok) return;

    dispatch(EmployeeDocumentsRequest(`employees/${params?.id}/document`));
    handleCloseDocument();
  };

  return (
    <>
      {loadingplatformConfig && <PageLoader />}
      <Dialog
        open={openEditDocument}
        onClose={handleCloseDocument}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            py: "35px",
            px: "24px",
            width: "375px",
            boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
            borderRadius: "12px !important",
          },
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {documentDetails?.documentType === documentTpeEnum?.Contract ? (
              <ContractIcon />
            ) : documentDetails?.documentType === documentTpeEnum?.Nda ? (
              <NdaIcon />
            ) : documentDetails?.documentType === documentTpeEnum?.Educational ? (
              <PersonalIdIcon />
            ) : (
              <DocumentIcon />
            )}
          </Grid>

          <Grid item>
            <Typography
              sx={{
                fontFamily: "Poppins-Regular !important",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "32px",
                color: "#1C1B1F",
              }}
            >
              Edit Document
            </Typography>
          </Grid>

          <Grid item>
            <FormControl variant="outlined" fullWidth error>
              {documentDetails?.documentType ? (
                <Autocomplete
                  id="free-solo-demo"
                  value={documentDetails?.documentType}
                  freeSolo
                  sx={{
                    "& label + .MuiInput-formControl": {
                      marginTop: "1vw",
                    },
                  }}
                  //   onChange={(event, option) => {
                  //     handleAccHolderType('accHolderType', option);
                  //   }}
                  PaperComponent={CustomPopperComponent}
                  options={documentOptions}
                  //   getOptionLabel={(option) => option.Name || ''}
                  onChange={(e, option) => {
                    setDocumentDetails({
                      ...documentDetails,
                      documentType: option,
                    });
                  }}
                  renderOption={(
                    props,
                    option,

                    { selected }
                  ) => {
                    return (
                      <li {...props}>
                        <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            spacing: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              mb: 0.5,
                              fontFamily: "Poppins-Medium",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              letterSpacing: "0.1px",
                              color: "#292A3D",
                            }}
                          >
                            {option}
                          </Typography>
                        </Box>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #B2B4E2",
                        borderRadius: "12px",
                      }}
                    >
                      <TextFieldV2
                        // error={checkError('accholderType', 'dropdown')}
                        {...params}
                        label="Document Type"
                        variant="filled"
                        type={"text"}
                        // name="accholderType"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Box>
                  )}
                />
              ) : null}
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl variant="outlined" fullWidth error>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #B2B4E2",
                  borderRadius: "12px",
                }}
              >
                <TextFieldV2
                  // error={checkError('accHolderName', 'textField')}
                  value={documentDetails?.documentName}
                  onChange={(e) => {
                    setDocumentDetails({
                      ...documentDetails,
                      documentName: e.target.value,
                    });
                  }}
                  label={"Document Name"}
                  variant="filled"
                  type={"text"}
                  name="accHolderName"
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
            </FormControl>
          </Grid>

          <Grid item>
            {documentDetails?.shareWithEmployee != undefined ? (
              <FormControl variant="outlined" fullWidth error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <Stack direction="row" justifyContent="start" alignItems="center">
                    <Checkbox
                      checked={documentDetails?.shareWithEmployee}
                      onChange={(event) => {
                        setDocumentDetails({
                          ...documentDetails,
                          shareWithEmployee: event.target.checked,
                        });
                      }}
                    />

                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium !important",
                        fontWeight: "500 !important",
                        fontSize: "16px",
                        color: "grey !important",
                      }}
                    >
                      Share With Employee
                    </Typography>
                  </Stack>
                </Box>
              </FormControl>
            ) : null}
          </Grid>

          <Grid item>
            {documentDetails?.shareWithCustomer != undefined ? (
              <FormControl variant="outlined" fullWidth error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <Stack direction="row" justifyContent="start" alignItems="center">
                    <Checkbox
                      checked={documentDetails?.shareWithCustomer}
                      onChange={(event) => {
                        setDocumentDetails({
                          ...documentDetails,
                          shareWithCustomer: event.target.checked,
                        });
                      }}
                    />

                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium !important",
                        fontWeight: "500 !important",
                        fontSize: "16px",
                        color: "grey !important",
                      }}
                    >
                      Share With Customer
                    </Typography>
                  </Stack>
                </Box>
              </FormControl>
            ) : null}
          </Grid>

          <Grid item xs>
            <Stack direction="row" spacing={1} justifyContent={"flex-start"}>
              <Button
                variant="text"
                sx={{
                  borderRadius: "99px",
                  height: "40px",
                }}
                onClick={handleCloseDocument}
              >
                <Typography variant="h5" color="#3D43BB">
                  Cancel
                </Typography>
              </Button>

              <Button
                variant="contained"
                disableElevation
                sx={{
                  borderRadius: "99px",
                  width: "99px",
                  height: "40px",
                }}
                onClick={handleSave}
              >
                <Typography variant="h6" color="white">
                  Save
                </Typography>
              </Button>
            </Stack>
          </Grid>

          <Grid item>
            <Snackbar
              fullWidth
              // open={openAlert}
              autoHideDuration={2500}
              // onClose={handleAlertClose}
              sx={{
                "&.MuiSnackbar-root": {
                  position: "relative",
                  bottom: 0,
                  left: 0,
                },
              }}
            >
              <Alert
                //   open={openAlert}
                fullWidth
                variant="filled"
                icon={true}
                sx={{
                  backgroundColor: "#313033",
                  padding: "initial",
                  px: "15px",
                  py: "8px",
                  fontSize: "14px",
                  textAlign: "left",

                  minWidth: "320px",

                  borderRadius: "12px !important",
                  color: "#ffffff !important",
                  "&.MuiPaper-root": {
                    color: "#ffffff !important",
                    boxShadow: "0px 4px 8px rgba(2, 0, 110, 0.18)",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  'Enter Required Fields'
                </Typography>
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default EditDocument;
