import { IconButton, Tooltip } from "@mui/material";
import React from "react";
//redux dispatch
import { useDispatch } from "react-redux";
//Icons
import EditRoundedIcon from "@mui/icons-material/EditRounded";

function Edit(props) {
  const { selectedId, handleSelectedRow, navigateTo } = props;

  //functions
  const navigateToEditPage = () => {
    if (selectedId != undefined && navigateTo != undefined) handleSelectedRow(selectedId, navigateTo);
  };

  return (
    <Tooltip title="Edit">
      <IconButton
        size="small"
        id="edit-emp"
        sx={{ "&:hover": { backgroundColor: "white" } }}
        onClick={navigateToEditPage}
      >
        <EditRoundedIcon fontSize="small" color="muted" sx={{ "&:hover": { color: "#1161D5" } }} />
      </IconButton>
    </Tooltip>
  );
}

export default Edit;
