import React from "react";

import { convertUTCTimeIntoLocalUserTime } from "components/Screens/CommonUtils";

import LastLoginIcon from "assets/images/login-icon.svg";

import "./LastActive.css";

const LastActive = ({ lastActiveEmail: email, lastActive: lastActiveTime = "" }) => {
  console.log("lastActiveTime", lastActiveTime);
  return (
    <div className="last-login-container">
      <img className="pr-025 align-self-start" src={LastLoginIcon} alt="login" />

      <span className="last-login-title align-self-start">Last Active:</span>

      <div className={` flex flex-col`}>
        {email && <span className="last-login-email align-self-start">{email}</span>}
        <span className="last-login-data align-self-start">{convertUTCTimeIntoLocalUserTime(lastActiveTime)}</span>
      </div>
    </div>
  );
};

export default LastActive;
