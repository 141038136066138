import React from "react";

const EditDocIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1018 4.83395L6.52208 9.41219C6.21821 9.68094 6.03023 10.0569 6 10.4009V12.0022L7.54799 12.0036C7.94866 11.9753 8.32161 11.7888 8.62109 11.4462L13.1675 6.89979L11.1018 4.83395ZM12.1626 3.77346L14.2282 5.83913L14.8986 5.16872C14.9635 5.10376 15 5.01567 15 4.92381C15 4.83195 14.9635 4.74386 14.8986 4.6789L13.3208 3.10114C13.2566 3.03641 13.1692 3 13.078 3C12.9868 3 12.8994 3.03641 12.8352 3.10114L12.1626 3.77346ZM16.5 9.75V15C16.5 15.8284 15.8284 16.5 15 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15V3C1.5 2.17157 2.17157 1.5 3 1.5H8.25V3H3V15H15V9.75H16.5ZM13.078 1.5C13.5691 1.5 14.0398 1.69606 14.3836 2.04257L15.9593 3.61824C16.3055 3.9645 16.5 4.43413 16.5 4.92381C16.5 5.41349 16.3055 5.88312 15.9593 6.22938L9.71813 12.4678C9.19427 13.0721 8.45167 13.4434 7.60095 13.5017H4.5V12.7517L4.50244 10.3405C4.56633 9.54963 4.934 8.81425 5.49487 8.32007L11.7715 2.04347C12.1173 1.69562 12.5875 1.5 13.078 1.5Z"
        fill="#4950C7"
      />
    </svg>
  );
};

export default EditDocIcon;
