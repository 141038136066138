import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import React from "react";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
//router
import { UnAssignEmployee } from "../../../../../../store/actions/Workforce/UnAssignEmployee";
import PageLoader from "../../../../../common/PageLoader";
//common utils
import { convertDate, getError, getValue } from "../../../../CommonUtils";

function EmploymentInformation(props) {
  const { handleDisableCompanyName } = props;
  const dispatch = useDispatch();
  //component state
  const [openAlert, setOpenAlert] = React.useState(false);
  const [userData, setUserData] = React.useState(JSON.parse(localStorage.getItem("loggedInUser")));
  //redux state
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const unAssignEmployee = useSelector((state) => state.UnAssignEmployee.unAssignEmployee);
  const loadingUnAssignInfo = useSelector((state) => state.UnAssignEmployee.loadingUnAssignInfo);

  //functions

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleUnAssign = () => {
    let unassignedRequest = "";

    handleDisableCompanyName(false);
    if (getEmployeeDetails?.payload?.employeeId) {
      if (getEmployeeDetails?.payload?.status == "assigned") {
        unassignedRequest = `unassign`;
      } else if (getEmployeeDetails?.payload?.status == "pending") {
        unassignedRequest = `unAssignPendingEmployee`;
      }

      dispatch(
        UnAssignEmployee({
          request: unassignedRequest,
          employeeId: getEmployeeDetails?.payload?.employeeId,
        })
      );
    }
  };

  React.useEffect(() => {
    if (unAssignEmployee?.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [unAssignEmployee]);

  return (
    <>
      {(loadingUnAssignInfo || loadingGET_EMPLOYEE_Details) && <PageLoader />}
      <Grid item container direction="column" rowSpacing={1.5} xs={12}>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Company Name
            </Typography>
            <Typography variant="h5" textAlign="end" id="company-name">
              {getValue(getEmployeeDetails.payload?.companyName)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="end" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Department
            </Typography>
            <Typography variant="h5" textAlign="end" id="department">
              {getValue(getEmployeeDetails.payload?.department)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Job Title
            </Typography>
            <Typography variant="h5" textAlign="end" id="job-title">
              {getValue(getEmployeeDetails.payload?.jobTitle)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Hired Date
            </Typography>
            <Typography variant="h5" textAlign="end" id="start-date">
              {convertDate(getEmployeeDetails.payload?.startDate)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Company Address
            </Typography>

            <Typography variant="h5" textAlign="end" pt="3px" id="address">
              {getValue(getEmployeeDetails.payload?.companyAddress ? getEmployeeDetails.payload.companyAddress : "-")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {Object.keys(userData).length &&
      userData?.type == "edge" &&
      (getEmployeeDetails.payload?.status == "assigned" || getEmployeeDetails.payload?.status == "pending") ? (
        <Grid item xs={12} pt={6} container justifyContent="center" alignItems="center">
          <Grid item>
            <Snackbar
              open={openAlert}
              autoHideDuration={5500}
              onClose={handleAlertClose}
              sx={{
                "&.MuiSnackbar-root": {
                  position: "relative",
                  bottom: 9,
                  left: 0,
                },
              }}
            >
              <Alert onClose={handleAlertClose} severity="error">
                {unAssignEmployee?.ok == false
                  ? getError(unAssignEmployee?.payload?.error)
                  : " REQUIRED FIELD MISSING!"}
              </Alert>
            </Snackbar>
          </Grid>

          <Grid item>
            <Button
              id="unassignFromEmployer"
              variant="contained"
              disableElevation
              // color={
              //     getEmployeeDetails.payload?.status == "assigned"
              //         ? "warning"
              //         : "pending"
              // }
              onClick={handleUnAssign}
              sx={{
                backgroundColor: getEmployeeDetails.payload?.status == "assigned" ? "warning" : "pending.light",

                width: "220px",
                borderRadius: "5px",
                "&:hover": {
                  backgroundColor: getEmployeeDetails.payload?.status == "assigned" ? "primary" : "pending.dark",
                },
              }}
            >
              <Typography
                id="unassign-emp"
                variant="h6"
                sx={{
                  color: getEmployeeDetails.payload?.status == "assigned" ? "white" : "pending.main",
                }}
              >
                Unassign from Employer
              </Typography>
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default EmploymentInformation;
