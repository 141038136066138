import React, { useState } from "react";
import { Alert, Box, Divider, Snackbar, Stack, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { MONTH_NAMES } from "../../../constants";

import {
  EditEmployeeProfile,
  ResetEditEmployeeProfile,
} from "../../../store/actions/EmployeePortal/EditEmployeeProfile";
import EmployeeDetailsEditView from "./EmployeeDetailsEditView";
import "./EmployeeDetails.css";
import { convertDate, getValue } from "../CommonUtils";
import { GetEmployeeById } from "../../../store/actions/EmployeePortal/GetEmployeeDetials";
import { usePermissions } from "../../Hook";
import { useEffect } from "react";

function EmployeeDetails(props) {
  // props
  const { employeeDetails } = props;
  // constants
  const dispatch = useDispatch();
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const editUser = useSelector((state) => state.EditEmployeeProfile);
  const ref = React.useRef();

  React.useEffect(() => {
    if (ref.current && !editUser.isLoading) {
      ref.current = editUser.isLoading;
      setOpenEdit(false);
    } else {
      ref.current = editUser.isLoading;
    }
  }, [editUser]);

  const SuccessMessage = (message) => {
    return (
      <Snackbar
        open={openAlert}
        autoHideDuration={25000}
        // onClose={() => setOpenAlert(false)}
        sx={{
          "&.MuiSnackbar-root": {
            position: "relative",
            bottom: 0,
            left: 0,
          },
        }}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity="success"
          variant="filled"
          icon={false}
          sx={{
            backgroundColor: "#313033",
            padding: "initial",
            px: "15px",
            py: "8px",
            fontSize: "14px",
            textAlign: "left",
            minWidth: "347px",

            borderRadius: "12px !important",
            color: "#ffffff !important",
            "&.MuiPaper-root": {
              color: "#ffffff !important",
              boxShadow: "0px 4px 8px rgba(2, 0, 110, 0.18)",
            },
          }}
        >
          <Typography fontSize="12px">{message}</Typography>
        </Alert>
      </Snackbar>
    );
  };

  // custom hook
  const { loggedInUser } = usePermissions();

  const [userObject, setUserObject] = React.useState({
    phoneNumber: employeeDetails?.phoneNumber,
    email: employeeDetails?.email,
    addressLine1: employeeDetails?.addressLine1,
    dateOfBirth: employeeDetails?.dateOfBirth,
  });

  React.useEffect(() => {
    setUserObject({
      phoneNumber: employeeDetails?.phoneNumber,
      email: employeeDetails?.email,
      addressLine1: employeeDetails?.addressLine1,
      dateOfBirth: employeeDetails?.dateOfBirth,
    });
  }, [employeeDetails]);

  const handleSave = () => {
    if (openEdit) {
      // TODO: Validate
      dispatch(
        EditEmployeeProfile({
          request: `employee/${loggedInUser?.userId}`,
          data: userObject,
        })
      );
    } else {
      setOpenEdit(true);
    }
  };

  const onChange = (name, value) => {
    setUserObject({ ...userObject, [name]: value });
  };

  useEffect(() => {
    if (editUser?.editedEmployee?.ok) {
      dispatch(
        GetEmployeeById({
          employeeId: loggedInUser?.userId,
        })
      );
      setOpenAlert(true);
      dispatch(ResetEditEmployeeProfile({}));
    }
  }, [editUser]);

  return (
    <div className="employee-details-d1">
      <div className="employee-details-d11">
        <div>
          <div className="employee-details-name" id="employee-name">
            {getValue(employeeDetails?.firstName) + " " + getValue(employeeDetails?.lastName)}
          </div>
          <div className="employee-details-title">{getValue(employeeDetails?.jobTitle)}</div>
        </div>
        {SuccessMessage("Information Successfully Updated")}

        <Stack direction="row" spacing={2}>
          {/* <SuccessMessage message="Information Successfully Updated" /> */}
          {openEdit && (
            <button className="employee-details-editbtn" style={{ width: "70px" }} onClick={() => setOpenEdit(false)}>
              cancel
            </button>
          )}

          <button type="submit" className="employee-details-editbtn" onClick={() => handleSave(!openEdit)}>
            {openEdit ? "Save" : "Edit"}
          </button>
        </Stack>
        {/* <div style={{display:"flex",justifyContent:"flex-end",spacing="2"}}>
        
        </div> */}
      </div>
      {!openEdit && (
        <div className="employee-details-d12">
          <br />
          <div className="employee-details-d121">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.9745 4.25431C10.5605 4.90384 10.3747 5.63969 9.85658 6.3379C9.73374 6.50344 9.59215 6.66654 9.42114 6.84627C9.33906 6.93254 9.27506 6.99742 9.14118 7.13142C8.83714 7.43576 8.58155 7.69149 8.37441 7.89862C8.27396 7.99906 8.95978 9.36953 10.2908 10.7018C11.621 12.0334 12.9914 12.7197 13.0924 12.6187L13.8586 11.8521C14.2806 11.4297 14.504 11.2259 14.8111 11.0244C15.4495 10.6056 16.1458 10.4918 16.733 11.0169C18.6504 12.3885 19.7354 13.2301 20.267 13.7824C21.3038 14.8597 21.1679 16.518 20.2729 17.464C19.9625 17.792 19.569 18.1858 19.1042 18.6338C16.2926 21.4469 10.359 19.735 5.81145 15.183C1.26291 10.63 -0.448123 4.69577 2.35753 1.88861C2.86122 1.377 3.0273 1.21101 3.51785 0.727647C4.43118 -0.172285 6.16595 -0.313196 7.22051 0.728537C7.77521 1.27649 8.65955 2.41433 9.9745 4.25431ZM15.2722 13.2661L14.5058 14.0329C13.2031 15.3364 10.9845 14.2253 8.87703 12.1157C6.76808 10.0047 5.65827 7.787 6.96142 6.48396C7.16829 6.27711 7.42363 6.02162 7.72744 5.71751C7.85002 5.59481 7.90609 5.53798 7.97339 5.46724C8.06509 5.37087 8.14044 5.28623 8.20077 5.21133C7.0354 3.58799 6.2432 2.57364 5.81614 2.15176C5.59558 1.93389 5.10172 1.97401 4.92042 2.15265C4.43686 2.62912 4.27792 2.78798 3.77626 3.29748C1.97194 5.10279 3.35321 9.89332 7.22519 13.7691C11.096 17.6437 15.8858 19.0256 17.7038 17.2068C18.1614 16.7655 18.5342 16.3924 18.8212 16.089C19.0286 15.8699 19.0657 15.4176 18.8271 15.1697C18.4298 14.7568 17.457 13.9984 15.7774 12.7922C15.6549 12.8907 15.5044 13.0337 15.2722 13.2661Z"
                fill="#02006E"
              />
            </svg>

            <div className="ep-company-details-d3-title-value">
              <div className="ep-company-details-d3-title">Phone Number</div>
              <div className="ep-company-details-d3-value " id="phone-number">
                {" "}
                {getValue(employeeDetails?.phoneNumber)}
              </div>
            </div>
          </div>
          <Divider light sx={{ my: 1 }} />
          <div className="employee-details-d121">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 6V3H16V4H14V3H6V4H4V3H2V6H18ZM18 8H2V18H18V8ZM16 1H18C19.1046 1 20 1.89543 20 3V18C20 19.1046 19.1046 20 18 20H2C0.89543 20 0 19.1046 0 18V3C0 1.89543 0.89543 1 2 1H4V0H6V1H14V0H16V1ZM7 12H5V10H7V12ZM11 12H9V10H11V12ZM15 12H13V10H15V12ZM7 16H5V14H7V16ZM11 16H9V14H11V16Z"
                fill="#02006E"
              />
            </svg>

            {/* ep-company-details-d3-title */}
            <div className="ep-company-details-d3-title-value">
              <div className="ep-company-details-d3-title">Date of birth</div>
              <div className="ep-company-details-d3-value" id="dob-emp">
                {convertDate(employeeDetails?.dateOfBirth)}
              </div>
            </div>
          </div>
          <Divider light sx={{ my: 1 }} />
          <div className="employee-details-d121">
            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 4.38247V2H2V4.38199L11 8.88245L20 4.38247ZM20 6.61853L11 11.1185L2 6.61811V16H20V6.61853ZM2 0H20C21.1046 0 22 0.89543 22 2V16C22 17.1046 21.1046 18 20 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0Z"
                fill="#02006E"
              />
            </svg>

            <div className="ep-company-details-d3-title-value">
              <div className="ep-company-details-d3-title">Email Address</div>
              <div className="ep-company-details-d3-value" id="emp-email">
                {" "}
                {getValue(employeeDetails?.email)}
              </div>
            </div>
          </div>
          <Divider light sx={{ my: 1 }} />
          <div className="employee-details-d121">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.8913 10L11.89 3.99867L5.88867 10H5.89001V20H17.89V10H17.8913ZM19.89 11.9987V20C19.89 21.1046 18.9946 22 17.89 22H5.89001C4.78545 22 3.89001 21.1046 3.89001 20V11.9987L2.4132 13.4755L1 12.0623L10.477 2.58529C11.2574 1.8049 12.5226 1.8049 13.303 2.58529L22.78 12.0623L21.3668 13.4755L19.89 11.9987Z"
                fill="#02006E"
              />
            </svg>

            <div className="ep-company-details-d3-title-value">
              <div className="ep-company-details-d3-title">Residential Address</div>
              <div className="ep-company-details-d3-value" id="emp-address">
                {getValue(employeeDetails?.addressLine1)}
              </div>
            </div>
          </div>
        </div>
      )}
      {openEdit && (
        <form action="#">
          <EmployeeDetailsEditView userAdditionalInfo={userObject} onChange={onChange} />
        </form>
      )}
      <br />
      <div style={{ position: "relative" }}>
        <div className="employee-details-end"></div>
      </div>
    </div>
  );
}

export default EmployeeDetails;
