import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";

import PageLoader from "components/common/PageLoader/index";

import PersonIcon from "components/Icons/PersonIcon.png";
import PencilIcon from "assets/svgs/PencilIcon";

import { convertDate, getValue } from "components/Screens/CommonUtils/index";

function PersonalInfo() {
  const navigate = useNavigate();

  const params = useParams();

  // redux state
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  // methods
  React.useEffect(() => {}, [getEmployeeDetails]);
  return (
    <>
      {loadingGetEmployeeDetails && <PageLoader />}
      <Grid item container direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 3.5 }}>
        <Grid item>
          <Box display="flex" flexDirection="column" rowGap={1.25}>
            <img src={PersonIcon} alt="Profile" style={{ width: "26px", height: "26px" }} />
            <Typography variant="h3" fontSize="15px">
              Personal Information
            </Typography>
          </Box>
        </Grid>

        <Grid item textAlign="end" alignItems="flex-end">
          {JSON.parse(localStorage.getItem("loggedInUser"))?.type === "edge" ? (
            <Button
              size="small"
              variant="contained"
              disableElevation
              color="primary1Color"
              endIcon={<PencilIcon />}
              sx={{
                borderRadius: "5px",
              }}
              onClick={() => {
                if (getEmployeeDetails?.ok) {
                  navigate("/main/addEmployee", {
                    state: {
                      navigatedFor: "editEmployee",
                      redirectTo: "details",
                      editEmployeeId: params?.id,
                    },
                  });
                }
              }}
            >
              <Typography variant="h5">Edit</Typography>
            </Button>
          ) : null}
        </Grid>
      </Grid>

      <Grid item container direction="column" rowSpacing={1.5} xs={12}>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Hired Date
            </Typography>
            <Typography variant="h5" id="edge-joining-date">
              {convertDate(getEmployeeDetails.payload?.edgeJoiningDate)}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Date of Birth
            </Typography>
            <Typography variant="h5" id="dob">
              {convertDate(getEmployeeDetails.payload?.dateOfBirth)}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Gender
            </Typography>
            <Typography variant="h5" id="gender">
              {getValue(getEmployeeDetails.payload?.gender)}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Personal Email
            </Typography>
            <Box width="60%" pt="3px" sx={{ wordBreak: "break-all" }}>
              <Typography variant="h5" textAlign="end" id="email">
                {getValue(getEmployeeDetails.payload?.email)}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Linkedin Url
            </Typography>
            <Box width="60%" pt="3px" sx={{ wordBreak: "break-all" }}>
              <Typography variant="h5" textAlign="end" id="linkedin">
                {getValue(getEmployeeDetails.payload?.Linkedin_Url)}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="start" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px" width="40%">
              Residential Address
            </Typography>
            <Box width="60%" pt="3px" sx={{ wordBreak: "break-all" }} id="address">
              {getEmployeeDetails.payload?.addressLine1 ? (
                <Typography variant="h5" textAlign="end">
                  {" "}
                  {getValue(getEmployeeDetails.payload?.addressLine1 ? getEmployeeDetails.payload?.addressLine1 : "-")}
                </Typography>
              ) : null}

              {getEmployeeDetails.payload?.city ||
              getEmployeeDetails.payload?.state ||
              getEmployeeDetails.payload?.zip ? (
                <Typography variant="h5" textAlign="end" id="address-zip">
                  {getValue(getEmployeeDetails.payload?.city ? `${getEmployeeDetails.payload?.city}, ` : "") +
                    (getEmployeeDetails.payload?.state ? `${getEmployeeDetails.payload.state}, ` : "") +
                    (getEmployeeDetails.payload?.zip ? getEmployeeDetails.payload?.zip : "")}
                </Typography>
              ) : null}

              {getEmployeeDetails.payload?.country ? (
                <Typography variant="h5" textAlign="end" id="country">
                  {getValue(getEmployeeDetails.payload?.country ? getEmployeeDetails.payload?.country : "")}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="secondary.alt" fontSize="13px">
              Enable Edge Wallet
            </Typography>
            <Box width="60%" pt="3px" sx={{ wordBreak: "break-all" }}>
              <Typography variant="h5" textAlign="end" id="enable-wallet">
                {getEmployeeDetails.payload?.Allowed_For_Payoneer ? getValue("Yes") : getValue("No")}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default PersonalInfo;
