import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  postADocForWorkforceSuccess,
  postADocForWorkforceFailure,
} from "../../../actions/Workforce/EmployeeDetils/Documents";
//constant
import { POST_DOC_FOR_WORKFORCE } from "../../../constants/Workforce/EmployeeDetils/Documents";
//request type
import postRequest from "../../../../util/APIHelperPost";
import { baseUrl } from "../../../../util/APIBaseUrl";

export const postDocReq = async (actions) => {
  return await postRequest(
    `${baseUrl}/${actions.payload.request}`,
    actions.payload.body,
    true,
    "POST",
    true
  )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* postDoc(params) {
  try {
    let response = yield call(postDocReq, params);

    yield put(postADocForWorkforceSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(postADocForWorkforceFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(POST_DOC_FOR_WORKFORCE, postDoc);
}
