import React, { useEffect, useState } from "react";
import TimeLine from "components/common/Timeline";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import { getYearFromDate } from "components/Screens/CommonUtils";

function EducationTab({ employeeId }) {
  const [education, setEducation] = useState(null);

  const getEmployeeEducation = async () => {
    const educationResp = await getRequest(`${baseUrl}/employee/${employeeId}/education`);
    const { ok, payload } = educationResp;
    if (ok) {
      setEducation(payload);
    }
  };

  const getDate = (startDate, endDate) => {
    const startYear = getYearFromDate(startDate);
    const endYear = getYearFromDate(endDate);

    return `${startYear} - ${endYear}`;
  };

  useEffect(() => {
    getEmployeeEducation();
  }, []);

  return (
    <div className="pt-15">
      <p className="heading-h4-semibold color-text-heading-primary">Education</p>
      {education?.map((item) => {
        return (
          <TimeLine
            title={item?.degreeName || "N/A"}
            company={item?.schoolName || "N/A"}
            date={getDate(item?.startDate, item?.endDate)}
          />
        );
      })}
    </div>
  );
}

export default EducationTab;
