import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Chip, Grid, Grow, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Header from "components/Header/Header";
import PaymentBreakDown from "components/Screens/Customers/CustomerDetials/PaymentBreakDown";
import PageLoader from "components/common/PageLoader";
import Filter from "components/Widgets/Filter";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import totalEmployeesIcon from "components/Icons/TotalEmployeesIcon.png";

import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

import { GetEmployeeSalaries } from "store/actions/EmployeeSalaries";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";
import { GetCustomersList, ResetGetCustomersList } from "store/actions/Customers/GetCustomerList";
import { ResetemployeeSalaryInfo } from "store/actions/Workforce/EmployeeDetils/SalaryInfo";
import { ResetAddEmployeeSalaryData } from "store/actions/Workforce/AddEmployee/AddEmployeeSalaryData";
import { ResetAddEmployeeSalary } from "store/actions/Workforce/AddEmployee/AddEmployeeSalary";
import { ResetCalculateEmployeeSalary } from "store/actions/Workforce/AddEmployee/CalculateEmployeeSalary";

let filterRemoved = false;

const styles = {
  position: "absolute",
  width: 300,
  top: 35,
  right: 0,
  left: -275,
  zIndex: 1,
  border: "1px light gray",
  p: 3,
  bgcolor: "background.paper",
};

let newDate = new Date(new Date().setDate("01"));
let dateFlag;

function index(props) {
  const dispatch = useDispatch();
  const openFilterMenu = useRef(null);
  const navigate = useNavigate();

  //component state
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [DropdownData_, setDropdownData_] = React.useState({});
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [apiFilters, setApiFilters] = React.useState({});
  const [sortBy, setSortBy] = React.useState({});
  const [resetPage, setResetPage] = React.useState(false);
  const [searchEmployee, setSearchEmployee] = React.useState("");
  const [navigateToPage, setNavigateToPage] = React.useState(null);
  const [selectedRowID, setSelectedRowID] = React.useState(null);
  const [timer, setTimer] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date(new Date().setDate("01")));
  const [loading, setLoading] = React.useState(false);

  const [page, setPage] = React.useState(0);

  //redux state
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const getCustomerList = useSelector((state) => state.GetCustomerList.getCustomerList);
  const loadingGetCustomerList = useSelector((state) => state.GetCustomerList.loadingGetCustomerList);

  const employeeSalaries = useSelector((state) => state.EmployeeSalaries.employeeSalaries);
  const loadingEmployeeSalaries = useSelector((state) => state.EmployeeSalaries.loadingEmployeeSalaries);

  const loadingGetNoticeBoard = useSelector((state) => state.GetNoticeBoard.loadingGetNoticeBoard);

  const rotate = open ? "rotate(180deg)" : "rotate(0)";

  //functions

  const closeOpenMenus = (e) => {
    if (openFilterMenu.current && open && !openFilterMenu.current.contains(e.target)) {
      setOpen(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleReset = (event) => {
    setOpen(false);
    setResetPage(true);
    setPage(0);
    setSearchEmployee("");
    setApiFilters({});
    setSelectedOptions({ countries: [], customers: [] });
    filterRemoved = true;
  };

  const handlePagination = (pagination) => {
    setRowsPerPage(pagination.rowsPerPage);
    setPage(pagination.page);
  };

  const handleSelectedRow = (selectedEmployeeID, navigateTo) => {
    let loggedInUser = {};
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    dispatch(ResetAddEmployeeSalaryData({}));
    dispatch(ResetAddEmployeeSalary({}));
    dispatch(ResetCalculateEmployeeSalary({}));
    if (
      Object.keys(loggedInUser).length &&
      loggedInUser.userId != undefined &&
      selectedEmployeeID != null &&
      selectedEmployeeID != undefined
    ) {
      dispatch(
        GetEmployeeDetails({
          customerID: loggedInUser?.userId,
          employeeID: selectedEmployeeID,
          request: `employee/${selectedEmployeeID}`,
        })
      );
      setSelectedRowID(selectedEmployeeID);
      setNavigateToPage(navigateTo);
    }
  };

  const handleSearchEmployee = (event) => {
    setSearchEmployee(event.target.value);
    setResetPage(true);
    setApiFilters({
      ...apiFilters,
      name: event.target.value,
    });
    setPage(0);
  };

  const handleDelete = (event, key, option) => {
    event.preventDefault();
    // setResetPage(true);
    setOpen(false);
    if (selectedOptions[key]) {
      let newdict = Object.assign({}, selectedOptions);
      newdict[key] = selectedOptions[key].filter((selectedOption) => selectedOption !== option);
      filterRemoved = true;

      if (!newdict[key].length && key == "customers") {
        dispatch(ResetemployeeSalaryInfo({}));
      }
      // setPage(0);
      setSelectedOptions(newdict);
    }
  };

  const callbackFromMultiSelect = (option, key) => {
    selectedOptions[key] = option;
    setResetPage(true);
    setSelectedOptions({
      ...selectedOptions,
    });
  };

  const handleRequestSortBy = (request) => {
    setSortBy(request);
  };

  const handleExport = async () => {
    setLoading(true);
    const response = await getRequest(
      `${baseUrl}/employeePayrolls?` +
      new URLSearchParams({
        limit: rowsPerPage,
        export: true,
        page: page,
        date: selectedDate,
        orderBy: JSON.stringify({
          field: sortBy?.field,
          direction: sortBy?.direction,
        }),
        filter: JSON.stringify({
          employeeName: [searchEmployee ? searchEmployee : ""],
          country: apiFilters?.countries,
          First_Name: apiFilters?.employeeName,
          companyName: apiFilters?.customers,
        }),
      }),
      true,
      "export"
    );
    setLoading(false);

    if (!response?.ok) {
      return;
    }

    downloadFile(response?.payload, "WorkForce Payrolls");
  };

  const handlePayoneer = async () => {
    setLoading(true);
    const response = await getRequest(
      `${baseUrl}/payoneer/mass-payout-export?` +
      new URLSearchParams({
        date: selectedDate,
        orderBy: JSON.stringify({
          field: sortBy?.field,
          direction: sortBy?.direction,
        }),
        filter: JSON.stringify({
          employeeName: [searchEmployee],
          country: apiFilters?.countries,
          First_Name: apiFilters?.employeeName,
          companyName: apiFilters?.customers,
        }),
      }),
      true,
      "export"
    );
    setLoading(false);

    if (!response?.ok) {
      return;
    }

    downloadFile(response?.payload, "Payoneer Mass Payout");
  };

  const downloadFile = (data, fileName) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const callbackFromDatePicker = (newValue) => {
    newDate = newValue;
    dateFlag = true;
  };

  const onCloseDatePicker = () => {
    if (dateFlag) {
      setSelectedDate(newDate);
      setResetPage(true);
    }
  };

  const searchCustomer = (customerName) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      if (customerName.length >= 3) {
        dispatch(
          GetCustomersList({
            customerName: customerName,
            loaderFlag: false,
          })
        );
      } else {
        dispatch(ResetGetCustomersList({}));
      }
    }, 500);

    setTimer(newTimer);
  };

  //methods

  React.useEffect(() => {
    if (getEmployeeDetails.ok) {
      if (navigateToPage != null) {
        if (selectedRowID != null && navigateToPage == "details") {
          navigate(`/main/details/${selectedRowID}`);
        } else if (navigateToPage == "addEmployee") {
          navigate(`/main/addEmployee`, {
            state: {
              navigatedFor: "editEmployee",
              redirectTo: "workforce",
              editEmployeeId: selectedRowID,
            },
          });
        }
      }
    }
  }, [getEmployeeDetails]);

  React.useEffect(() => {
    let apiFilter = {};
    let arr = [];

    if (selectedOptions && Object.keys(selectedOptions).length) {
      arr = [];
      Object.keys(selectedOptions).map((header, index) => {
        // if (header !== "selectedDate") {
        selectedOptions[header].map((option, index) => {
          arr.push(option.key);
        });

        apiFilter[header] = arr;
        arr = [];
        // }
      });
      setApiFilters(apiFilter);

      dispatch(
        GetEmployeeSalaries({
          request: `${baseUrl}/employeePayrolls`,
          rowsPerPage: rowsPerPage,
          // page: page,
          page: 0,
          searchEmployee: searchEmployee,
          date: selectedDate,
          dist: apiFilter,
          sortBy: {
            field: sortBy.orderBy,
            direction: sortBy.order,
          },
        })
      );
      apiFilter = [];
    } else if (selectedOptions && Object.keys(selectedOptions).length && filterRemoved) {
      // setApiFilters(apiFilter);
      dispatch(
        GetEmployeeSalaries({
          request: `${baseUrl}/employeePayrolls`,
          rowsPerPage: rowsPerPage,
          page: page,
          searchEmployee: searchEmployee,
          date: selectedDate,
          dist: null,
          sortBy: {
            field: sortBy.orderBy,
            direction: sortBy.order,
          },
        })
      );
    }
  }, [selectedOptions]);

  React.useEffect(() => {
    let customers = [];

    if (getCustomerList?.ok) {
      getCustomerList?.payload?.map((data, index) => {
        customers.push({
          key: data.companyName,
          value: data.companyName,
        });
      });
    }
    setDropdownData_({
      ...DropdownData_,
      customers,
    });
  }, [getCustomerList]);

  React.useEffect(() => {
    const triggerCall = setTimeout(() => {
      if (sortBy?.orderBy != undefined && sortBy?.order != undefined) {
        dispatch(
          GetEmployeeSalaries({
            request: `${baseUrl}/employeePayrolls`,
            rowsPerPage: rowsPerPage,
            page: page,
            searchEmployee: searchEmployee,
            date: selectedDate,
            dist: apiFilters,
            sortBy: {
              field: sortBy.orderBy,
              direction: sortBy.order,
            },
          })
        );
      } else {
        dispatch(
          GetEmployeeSalaries({
            request: `${baseUrl}/employeePayrolls`,
            rowsPerPage: rowsPerPage,
            searchEmployee: searchEmployee,
            date: selectedDate,
            page: page,
            dist: apiFilters,
            sortBy: null,
          })
        );
      }
    }, 500);

    return () => clearTimeout(triggerCall);
  }, [rowsPerPage, page, sortBy, searchEmployee, selectedDate]);

  React.useEffect(() => {
    let loggedInUser = {};
    let request = null;
    let countries = [];
    let customers = [];
    let dropdownData = {};

    // let currentDate = new Date().getDate();
    // if (currentDate < "17") {
    //     let payrollMonth = new Date().getMonth();
    //     setSelectedDate(new Date(selectedDate.setMonth(payrollMonth - 1)));
    // }
    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    // if (loggedInUser?.userId != undefined) {
    //     request = `user/${loggedInUser.userId}/summary`;
    //     dispatch(UserAdditionalInfo(request));
    // }

    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
      dropdownData?.payload?.country?.map((data, index) => {
        countries.push({
          key: data.countryCode,
          value: data.countryCode,
        });
      });
    }
    setDropdownData_({
      ...DropdownData_,
      countries,
      customers,
      // selectedDate,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Finances</title>
      </Helmet>
      {loadingAdditionalInfo ||
        loadingEmployeeSalaries ||
        loading ||
        loadingGET_EMPLOYEE_Details ||
        loadingGetCustomerList || loadingGetNoticeBoard ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid container direction="column" rowSpacing={3}>
        <Grid item>
          <Header
            data={{
              heading: "Finances",
              profileWithIcon: null,
            }}
          />
        </Grid>

        {/* DataTable */}

        <Grid item>
          <PaymentBreakDown
            selectedOptions={apiFilters}
            selectedFiltersList={
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                columnSpacing={3}
                style={{
                  left: "-80px",
                }}
              >
                <Grid item>
                  {Object.keys(selectedOptions).map((key, index) => {
                    return selectedOptions[key].map((option, index) => {
                      return (
                        <Chip
                          key={option.key}
                          label={<Typography variant="h6">{option.value}</Typography>}
                          clickable
                          sx={{
                            bgcolor: "#D3E0F4",
                            mx: 0.5,
                            height: 26,
                            borderRadius: "10px",
                            color: "#1161D5",
                            "& .MuiChip-deleteIcon": {
                              color: "#3C7DDC",
                              fontSize: "1.0rem",
                            },
                          }}
                          onDelete={(e) => handleDelete(e, key, option)}
                        />
                      );
                    });
                  })}
                </Grid>
              </Grid>
            }
            paymentDetail={userAdditionalInfo}
            resetPage={resetPage}
            customerPayment={employeeSalaries}
            openDetailsPage={"employeeName"}
            salariesPage={true}
            toolBar={true}
            title={`Payroll Records (${employeeSalaries?.count ? employeeSalaries.count : 0})`}
            titleIcon={totalEmployeesIcon}
            filterButton={
              <Box sx={{ position: "relative" }} ref={openFilterMenu}>
                <FilterListIcon
                  onClick={handleClick}
                  style={{
                    transform: rotate,
                    transition: "all 0.2s linear",
                  }}
                />
                <Grow
                  in={open}
                  style={{
                    transformOrigin: "right 0 0 ",
                  }}
                  {...(open ? { timeout: 350 } : {})}
                  unmountOnExit
                >
                  <Paper elevation={3} sx={styles}>
                    <Filter
                      searchCustomer={searchCustomer}
                      data={DropdownData_}
                      selectedOptions={selectedOptions}
                      handleReset={handleReset}
                      callbackFromMultiSelect={callbackFromMultiSelect}
                    />
                  </Paper>
                </Grow>
              </Box>
            }
            datePicker={
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["month", "year"]}
                  value={selectedDate}
                  // id={data.name}
                  // maxDate={data.maxDate ? data.maxDate : null}
                  // onChange={() => undefined}
                  onClose={onCloseDatePicker}
                  onChange={(newValue) => {
                    callbackFromDatePicker(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        // "& .MuiOutlinedInput-root": {
                        //     borderRadius: "5px",
                        //     fontSize: "12px",
                        //     fontFamily: "Poppins-Medium",
                        //     fontWeight: "500",
                        //     color: "primary",
                        //     // marginTop: "8px",
                        // },

                        "&.MuiTextField-root": {
                          marginTop: 0,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "white",
                          borderRadius: "5px",
                          fontFamily: "Poppins-Medium !important",
                          height: "38px",
                        },

                        "& .MuiSvgIcon-root": {
                          fill: "#1161D5",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            }
            SearchEmployee={
              <TextField
                sx={{
                  "&.MuiTextField-root": {
                    marginTop: 0,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    borderRadius: "5px",
                    fontFamily: "Poppins-Medium !important",
                    height: "38px",
                  },
                  "& .MuiInputBase-input": {
                    // paddingTop: 1,
                    // paddingLeft: 2,
                    // paddingBottom: 1,
                    // fontSize: "18px",
                  },
                }}
                id="userName"
                variant={"outlined"}
                placeholder="Enter Employee Name"
                size="small"
                name="email"
                value={searchEmployee}
                fullWidth={true}
                onChange={handleSearchEmployee}
                margin="normal"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{
                          mx: 1,
                        }}
                      />
                      {/* ), */}
                    </InputAdornment>
                  ),
                }}
              />
            }
            handleSelectedRow={handleSelectedRow}
            handlePagination={handlePagination}
            handleRequestSortBy={handleRequestSortBy}
            ExportButton={
              <>
                <Button
                  disableElevation
                  variant="contained"
                  fullWidth
                  sx={{
                    width: "170px",
                    height: "35px",
                    borderRadius: "5px",
                    color: "white",
                    marginRight: "5px",
                  }}
                  onClick={handlePayoneer}
                >
                  <Typography variant="h6" color="white">
                    Export Payment File
                  </Typography>
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  fullWidth
                  sx={{
                    width: "115px",
                    height: "35px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                  onClick={handleExport}
                >
                  <Typography variant="h6" color="white">
                    Export Payroll
                  </Typography>
                </Button>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default index;
