import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AddEmployeeSalaryData } from "store/actions/Workforce/AddEmployee/AddEmployeeSalaryData";
import { CalculateEmployeeSalary } from "store/actions/Workforce/AddEmployee/CalculateEmployeeSalary";
import { employeeSalaryInfo } from "store/actions/Workforce/EmployeeDetils/SalaryInfo";

import { checkUserAdditionalInfo, convertDate } from "components/Screens/CommonUtils/index.js";

import PageLoader from "components/common/PageLoader/index.js";

const textFiledStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "5px",
    fontSize: "13px",
    fontFamily: "Poppins-Medium",
    fontWeight: "500",
    color: "primary",
  },
};
const salaryDetails = {
  costToCustomer: "",
  edgeFee: "",
  grossPay: "",
  deduction: "",
  netPayBeforeTax: "",
  discount: false,
  startingDate: new Date(new Date().setDate("01")),
  // startingDate: null,
  flatDiscount: 0,
  discountPercentage: 0,
  discountDuration: { key: null, value: "" },
};

const salaryTaxes = {
  tax: "",
  netPay: "",
};

const discountDurationOptions = [
  { key: 1, value: "One Month" },
  { key: 2, value: "Two Months" },
  { key: 3, value: "Three Months" },
  { key: 4, value: "Four Months" },
  { key: 5, value: "Five Months" },
  { key: 6, value: "Six Months" },
  { key: 7, value: "Seven Months" },
  { key: 8, value: "Eight Months" },
  { key: 9, value: "Nine Months" },
  { key: 10, value: "Ten Months" },
  { key: 11, value: "Eleven Months" },
  { key: 12, value: "One Year" },
];

function SalaryDetails(props) {
  const { nextButtonClick } = props;
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { navigatedFor } = state; // Read values passed on state

  //compnent state
  const [employeeSalaryDetails, setEmployeeSalaryDetails] = React.useState(salaryDetails);
  const [employeeSalaryTaxes, setEmployeeSalaryTaxes] = React.useState(salaryTaxes);
  const [calculateTaxFlag, setCalculateTaxFlag] = React.useState(false);

  const [discountField, setDiscountField] = React.useState({
    flatDiscountLabel: "FLAT DISCOUNT",
    requiredflatDiscount: false,
    discountPercentageLabel: "DISCOUNT %",
    requiredDiscountPercentage: false,
    disabled: true,
  });

  //redux state
  const employeeSalary = useSelector((state) => state.SalaryInfo.employeeSalaryInfo);
  const loadingEmployeeSalaryInfo = useSelector((state) => state.SalaryInfo.loadingEmployeeSalaryInfo);

  const addEmployeeSalaryData = useSelector((state) => state.AddemployeeSalaryData.addEmployeeSalaryData);

  const addEmployeeInfo = useSelector((state) => state.AddEmployee.addEmployeeInfo);
  const loadingAddEmployeeInfo = useSelector((state) => state.AddEmployee.loadingAddEmployeeInfo);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const calculateEmployeeSalary = useSelector((state) => state.CalculateEmployeeSalary.calculateEmployeeSalary);
  const loadingCalculateEmployeeSalary = useSelector(
    (state) => state.CalculateEmployeeSalary.loadingCalculateEmployeeSalary
  );
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  //functions

  const handleEmployeeSalaryDetails = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    setEmployeeSalaryDetails({
      ...employeeSalaryDetails,
      [event.target.name]: onlyNums,
      grossPay: parseFloat(employeeSalaryDetails.costToCustomer) - parseFloat(employeeSalaryDetails.edgeFee),
    });
  };

  const handleCostToCustomer = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    setEmployeeSalaryDetails({
      ...employeeSalaryDetails,
      [event.target.name]: onlyNums,
      grossPay: onlyNums - employeeSalaryDetails.edgeFee,
    });
  };

  const callbackFromDatePicker = (event) => {
    setEmployeeSalaryDetails({
      ...employeeSalaryDetails,
      startingDate: event,
    });
  };

  const handleCheckbox = (event) => {
    setEmployeeSalaryDetails({
      ...employeeSalaryDetails,
      discount: event.target.checked,
    });
  };

  const handlePlatformFee = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    setEmployeeSalaryDetails({
      ...employeeSalaryDetails,
      [event.target.name]: onlyNums,
      grossPay: employeeSalaryDetails.costToCustomer - onlyNums,
    });
  };

  const handleDiscount = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.name == "discountPercentage") {
      if (onlyNums <= 100) {
        setEmployeeSalaryDetails({
          ...employeeSalaryDetails,
          [event.target.name]: onlyNums ? parseInt(onlyNums) : "",
          flatDiscount: 0,
        });
      }
    } else {
      setEmployeeSalaryDetails({
        ...employeeSalaryDetails,
        [event.target.name]: onlyNums ? parseInt(onlyNums) : "",
        discountPercentage: 0,
      });
    }
  };

  const handleDropdownOptions = (event) => {
    setEmployeeSalaryDetails({
      ...employeeSalaryDetails,
      discountDuration: event,
    });
  };

  const setDiscountFieldLabel = () => {
    if (employeeSalaryDetails.discount) {
      if (employeeSalaryDetails.discountPercentage != 0) {
        setDiscountField({
          ...discountField,
          requiredDiscountPercentage: true,
          requiredflatDiscount: false,
          flatDiscountLabel: "FLAT DISCOUNT",
          discountPercentageLabel: "DISCOUNT % *",
          disabled: false,
        });
      } else if (employeeSalaryDetails.flatDiscount != 0) {
        setDiscountField({
          ...discountField,
          requiredDiscountPercentage: false,
          requiredflatDiscount: true,
          flatDiscountLabel: "FLAT DISCOUNT *",
          discountPercentageLabel: "DISCOUNT %",
          disabled: false,
        });
      } else {
        setDiscountField({
          ...discountField,
          requiredDiscountPercentage: true,
          requiredflatDiscount: true,
          flatDiscountLabel: "FLAT DISCOUNT *",
          discountPercentageLabel: "DISCOUNT % *",
          disabled: false,
        });
      }
    } else {
      setDiscountField({
        ...discountField,
        requiredDiscountPercentage: false,
        requiredflatDiscount: false,
        flatDiscountLabel: "FLAT DISCOUNT",
        discountPercentageLabel: "DISCOUNT %",
        disabled: true,
      });
      setEmployeeSalaryDetails((prev) => {
        return {
          ...prev,
          discountPercentage: 0,
          flatDiscount: 0,
          startingDate: new Date(),
          discountDuration: { key: null, value: "" },
        };
      });
    }
  };

  const calculateEmployeeSalaryTax = useCallback(() => {
    if (
      employeeSalaryDetails.costToCustomer != "" &&
      employeeSalaryDetails.edgeFee != "" &&
      parseInt(employeeSalaryDetails.edgeFee) < parseInt(employeeSalaryDetails.costToCustomer)
    ) {
      setCalculateTaxFlag(true);

      if (navigatedFor == "addEmployee" && addEmployeeInfo?.payload?.employeeId != undefined) {
        dispatch(
          CalculateEmployeeSalary({
            salaryData: employeeSalaryDetails,
            employeeId: addEmployeeInfo?.payload?.employeeId,
            loaderFlag: false,
          })
        );
      } else if (navigatedFor == "editEmployee") {
        dispatch(
          CalculateEmployeeSalary({
            salaryData: employeeSalaryDetails,
            employeeId: getEmployeeDetails?.payload?.employeeId,
            loaderFlag: false,
          })
        );
      }
    } else {
      setCalculateTaxFlag(false);
    }
  }, [employeeSalaryDetails.costToCustomer, employeeSalaryDetails.edgeFee, employeeSalaryDetails.deduction]);

  //methods

  React.useEffect(() => {
    setDiscountFieldLabel();
  }, [employeeSalaryDetails.discount, employeeSalaryDetails.discountPercentage, employeeSalaryDetails.flatDiscount]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      calculateEmployeeSalaryTax();
    }, 500);
    return () => clearTimeout(timeout);
  }, [employeeSalaryDetails.costToCustomer, employeeSalaryDetails.edgeFee, employeeSalaryDetails.deduction]);

  React.useEffect(() => {
    dispatch(
      AddEmployeeSalaryData({
        employeeSalaryDetails: employeeSalaryDetails,
      })
    );
  }, [employeeSalaryDetails]);

  React.useEffect(() => {
    if (navigatedFor == "editEmployee") {
      setCalculateTaxFlag(true);
      if (Object.keys(employeeSalary).length && employeeSalary.ok) {
        if (Object.keys(employeeSalary.payload).length) {
          setEmployeeSalaryDetails({
            costToCustomer: employeeSalary?.payload?.costToCustomer,
            edgeFee: employeeSalary?.payload?.platformFee,
            grossPay: employeeSalary?.payload?.grossSalary,
            deduction: employeeSalary?.payload?.additionalDeduction,
            startingDate: convertDate(employeeSalary?.payload?.startDate),
            discount: employeeSalary?.payload?.discounted,
            discountPercentage: employeeSalary?.payload?.percentage,
            flatDiscount: employeeSalary?.payload?.flatAmount,
            discountDuration: discountDurationOptions.find(
              (duration) => duration.key == employeeSalary?.payload?.duration
            ),
          });
        } else {
          setEmployeeSalaryDetails({
            costToCustomer: "",
            edgeFee: "",
            grossPay: "",
            deduction: "",
          });
        }
      }
    }
  }, [employeeSalary]);

  React.useEffect(() => {
    let loggedInUser = {};
    let employeeSalaryDetailsRequest = "";
    let employeeSalaryInfoRequest = "";

    let customerId = checkUserAdditionalInfo(userAdditionalInfo) ? userAdditionalInfo?.customerId : null;
    let employeeId = getEmployeeDetails.payload?.employeeId;

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (navigatedFor == "addEmployee") {
      if (Object.keys(addEmployeeSalaryData).length) {
        setEmployeeSalaryDetails(addEmployeeSalaryData?.employeeSalaryDetails);
      } else {
        setEmployeeSalaryDetails({
          costToCustomer: "",
          edgeFee: "",
          grossPay: "",
          deduction: "",
        });
      }
    } else if (navigatedFor == "editEmployee") {
      if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined && getEmployeeDetails?.ok) {
        if (loggedInUser?.type == "customer") {
          employeeSalaryInfoRequest = `customer/${customerId}/employee/${employeeId}/salaryInfo`;
          employeeSalaryDetailsRequest = `customer/${customerId}/employee/${employeeId}/payroll`;
        } else if (loggedInUser?.type == "edge") {
          employeeSalaryInfoRequest = `employee/${employeeId}/salary`;
          employeeSalaryDetailsRequest = `employee/${employeeId}/payroll`;
        }

        dispatch(
          employeeSalaryInfo({
            request: employeeSalaryInfoRequest,
          })
        );
      }
    }
  }, []);

  return (
    <>
      {loadingAddEmployeeInfo == true ||
      loadingCalculateEmployeeSalary == true ||
      loadingGET_EMPLOYEE_Details == true ||
      loadingAdditionalInfo == true ||
      loadingEmployeeSalaryInfo == true ||
      loadingGET_EMPLOYEE_Details == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid item pb={5}>
        <Typography variant="input" fontSize="14px">
          SalaryDetails
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="row"
        // justifyContent="space-between"
        columnSpacing={5}
        rowSpacing={3}
        alignItems="center"
      >
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography
                variant="input"
                color={
                  nextButtonClick == true && employeeSalaryDetails.costToCustomer == "" ? "warning.main" : "info.alt"
                }
              >
                COST TO CUSTOMER *
              </Typography>
            </label>
            <TextField
              id="standard-name"
              // disabled
              size="small"
              name="costToCustomer"
              // type="number"
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              onChange={handleCostToCustomer}
              value={employeeSalaryDetails.costToCustomer}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} mt={4}>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={formData[data.name]}
                    size="small"
                    checked={employeeSalaryDetails.discount}
                    onChange={handleCheckbox}
                    // onChange={(event) => {
                    //     handleDataChange(
                    //         event,
                    //         data.name
                    //     );
                    // }}
                    name="discount"
                  />
                }
                label={"Discount"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4} mt={4}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              borderRadius: "5px",
            }}
            disabled={!calculateTaxFlag}
          >
            <Typography variant="h6" color="white">
              Calculate Taxes and Net Pay
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography
                variant="input"
                color={nextButtonClick == true && employeeSalaryDetails.edgeFee == "" ? "warning.main" : "info.alt"}
              >
                PLATFORM FEE *
              </Typography>
            </label>
            <TextField
              id="standard-name"
              size="small"
              name="edgeFee"
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              onChange={handlePlatformFee}
              value={employeeSalaryDetails.edgeFee}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography
                variant="input"
                color={
                  nextButtonClick == true &&
                  employeeSalaryDetails.discount &&
                  employeeSalaryDetails.discountPercentage == 0 &&
                  employeeSalaryDetails.flatDiscount == 0
                    ? "warning.main"
                    : "info.alt"
                }
              >
                {discountField.discountPercentageLabel}
              </Typography>
            </label>
            <TextField
              id="standard-name"
              disabled={discountField.disabled}
              size="small"
              name="discountPercentage"
              // type="number"
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              onChange={handleDiscount}
              value={employeeSalaryDetails.discountPercentage}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          {calculateTaxFlag ? (
            <FormControl variant="outlined" fullWidth>
              <label>
                <Typography variant="input">TAX</Typography>
              </label>
              <TextField
                id="standard-name"
                disabled
                size="small"
                // name="discountPercentage"
                // type="number"
                margin="dense"
                fullWidth={true}
                sx={textFiledStyle}
                value={calculateEmployeeSalary?.payload?.tax}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          ) : null}
        </Grid>

        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography variant="input" color={"info.alt"}>
                GROSS PAY
              </Typography>
            </label>
            <TextField
              id="standard-name"
              disabled
              size="small"
              name="grossPay"
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              onChange={handleEmployeeSalaryDetails}
              value={employeeSalaryDetails.grossPay}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography
                variant="input"
                color={
                  nextButtonClick == true &&
                  employeeSalaryDetails.discount &&
                  employeeSalaryDetails.discountPercentage == 0 &&
                  employeeSalaryDetails.flatDiscount == 0
                    ? "warning.main"
                    : "info.alt"
                }
              >
                {discountField.flatDiscountLabel}
              </Typography>
            </label>
            <TextField
              id="standard-name"
              disabled={discountField.disabled}
              size="small"
              name="flatDiscount"
              // type="number"
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              onChange={handleDiscount}
              value={employeeSalaryDetails.flatDiscount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          {calculateTaxFlag ? (
            <FormControl variant="outlined" fullWidth>
              <label>
                <Typography variant="input"> NET PAY (AFTER TAX)</Typography>
              </label>
              <TextField
                id="standard-name"
                disabled
                size="small"
                name="discountPercentage"
                // type="number"
                margin="dense"
                fullWidth={true}
                sx={textFiledStyle}
                value={calculateEmployeeSalary?.payload?.netSalaryAfterTax}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          ) : null}
        </Grid>

        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography variant="input">DEDUCTION</Typography>
            </label>
            <TextField
              id="standard-name"
              size="small"
              name="deduction"
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              onChange={handleEmployeeSalaryDetails}
              value={employeeSalaryDetails.deduction}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography
                variant="input"
                color={
                  nextButtonClick == true && employeeSalaryDetails.costToCustomer == "" ? "warning.main" : "info.alt"
                }
              >
                STARTING Month
              </Typography>
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year", "month"]}
                value={employeeSalaryDetails?.startingDate}
                disabled={discountField.disabled}
                onChange={(e) => {
                  callbackFromDatePicker(e);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        fontWeight: "500",
                        color: "primary",
                        marginTop: "8px",
                      },

                      "& .MuiSvgIcon-root": {
                        fill: "#1161D5",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={4}></Grid>

        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography variant="input" color={"info.alt"}>
                NET PAY
              </Typography>
            </label>
            <TextField
              id="standard-name"
              disabled
              size="small"
              name="netPayBeforeTax"
              // type="number"
              margin="dense"
              fullWidth={true}
              sx={textFiledStyle}
              // onChange={handleEmployeeSalaryDetails}
              value={calculateEmployeeSalary?.payload?.netSalary}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon fontSize="12" color="secondary.alt" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <label>
              <Typography
                variant="input"
                color={
                  nextButtonClick == true && employeeSalaryDetails.costToCustomer == "" ? "warning.main" : "info.alt"
                }
              >
                DISCOUNT DURATION
              </Typography>
            </label>
            <Autocomplete
              fullWidth={true}
              disabled={discountField.disabled}
              value={employeeSalaryDetails?.discountDuration}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  fontWeight: "500",
                  color: "primary",
                },

                "& .MuiSvgIcon-root": {
                  fill: "#1161D5",
                },
                width: "100%",
              }}
              options={discountDurationOptions}
              getOptionLabel={(option) => option?.value}
              onChange={(event, option) => {
                handleDropdownOptions(option);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    fullWidth={true}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    margin="dense"
                    variant="outlined"
                    sx={textFiledStyle}
                  />
                );
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default SalaryDetails;
