import { Modal, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiModal-backdrop": {
      backgroundColor: "#13159a33",
    },
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "1rem 2rem",
    maxWidth: 400,
    width: "100%",
    textAlign: "center",
    width: "24em",
    borderRadius: "12px",
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  title: {
    marginBottom: "0.5rem !important",
    textAlign: "left",
    fontFamily: "Poppins-Medium !important",
    fontSize: "20px !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    // paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",
  },
}));

const CustomModal = ({ open, children, title, width, sx }) => {
  const classes = useStyles();

  return (
    <Modal open={open} className={classes.modalContainer}>
      <Box className={classes.modalContent} sx={{ width: width, ...sx }}>
        <div className={classes.title} style={{ paddingLeft: "2rem" }}>
          {title}
        </div>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
