import { call, put, takeEvery } from "redux-saga/effects";
//actions
import {
  GetEmployeeDetailsSuccess,
  GetEmployeeDetailsFailure,
} from "../../actions/GetEmployeeDetails";
//constant
import { GET_EMPLOYEE_Details } from "../../constants/GetEmployeeDetails";
//request type
import getRequest from "../../../util/APIHelperGet";
//base url
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchGetEmployeeDetailsRequest = async (actions) => {
  return await getRequest(`${baseUrl}/${actions.payload.request}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchGetEmployeeDetails(params) {
  try {
    let response = yield call(fetchGetEmployeeDetailsRequest, params);

    yield put(GetEmployeeDetailsSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetEmployeeDetailsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_EMPLOYEE_Details, fetchGetEmployeeDetails);
}
