import React from "react";
import "./HrPoc.css";
import { Avatar, Stack, Typography } from "@mui/material";
import { getValue } from "../../../CommonUtils";
import { baseUrl } from "../../../../../util/APIBaseUrl";

function HrPoc(props) {
  const { employeeDetails, isProfilePage } = props;

  return (
    <Stack direction="column">
      {employeeDetails?.hrpoc && Object.keys(employeeDetails?.hrpoc).length ? (
        <>
          {isProfilePage ? (
            <Typography
              style={{
                padding: "10px 25px 0px 25px",
                lineHeight: "24px",
                fontSize: "16px",
                fontWeight: "500px",
                fontFamily: "Poppins-Medium",
                marginTop: "25px",
              }}
            >
              Edge Point Of Contact
            </Typography>
          ) : null}
          <div className="ep-hr-poc-card">
            <Avatar
              sx={{
                width: "112px",
                height: "112px",
                borderRadius: "12px",
              }}
              alt={getValue(employeeDetails?.hrpoc?.First_Name)}
              variant="square"
              src={`${baseUrl}/user/${employeeDetails?.hrpoc?.Edge_Employee_ID}/logo?id=${employeeDetails?.hrpoc?.Picture_Version}&variant=thumbnail`}
            />

            <Stack direction="column" spacing={2.5}>
              <Stack spacing={0.5}>
                <Typography className="ep-hr-poc-name" id="poc-hr-name">
                  {getValue(employeeDetails?.hrpoc?.First_Name) + " " + getValue(employeeDetails?.hrpoc?.Last_Name)}
                </Typography>
                <Typography className="ep-hr-poc-title" id="poc-contact-company">
                  {employeeDetails?.hrpoc?.Job_Title} | {employeeDetails?.companyName}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1}>
                <div
                  className="ep-hr-poc-email-div"
                  style={{
                    padding: "12px 24px 12px 24px ! important",
                  }}
                >
                  <svg width="16.5" height="13.5" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.75 6.03685V4.25H2.25V6.0365L9.00002 9.41184L15.75 6.03685ZM15.75 7.7139L8.99998 11.0889L2.25 7.71358V14.75H15.75V7.7139ZM2.25 2.75H15.75C16.5784 2.75 17.25 3.42157 17.25 4.25V14.75C17.25 15.5784 16.5784 16.25 15.75 16.25H2.25C1.42157 16.25 0.75 15.5784 0.75 14.75V4.25C0.75 3.42157 1.42157 2.75 2.25 2.75Z"
                      fill="#4950C7"
                    />
                  </svg>

                  <div className="ep-hr-poc-email" id="poc-email">
                    {getValue(employeeDetails?.hrpoc?.Email)}
                  </div>
                </div>

                <div
                  className="ep-hr-poc-email-div"
                  style={{
                    padding: "12px 24px 12px 24px ! important",
                  }}
                >
                  <svg width="10.5" height="16.5" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.25 1.25H12.75C13.5784 1.25 14.25 1.92157 14.25 2.75V16.25C14.25 17.0784 13.5784 17.75 12.75 17.75H5.25C4.42157 17.75 3.75 17.0784 3.75 16.25V2.75C3.75 1.92157 4.42157 1.25 5.25 1.25ZM7.5 2.75H5.25V16.25H12.75V2.75H10.5C10.5 3.16421 10.1642 3.5 9.75 3.5H8.25C7.83579 3.5 7.5 3.16421 7.5 2.75Z"
                      fill="#4950C7"
                    />
                  </svg>

                  <div className="ep-hr-poc-email" id="poc-contact">
                    {getValue(employeeDetails?.hrpoc?.Phone_Number)}
                  </div>
                </div>
              </Stack>
            </Stack>
          </div>
        </>
      ) : null}
    </Stack>
  );
}
export default HrPoc;
