import { Divider } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import { getDateInDashForCalendar } from '../../../helpers';

import './EmployeeDetailsEditView.css';
import { useSelector } from 'react-redux';

function EmployeeDetailsEditView(props) {
  // redux state
  const employeeDetails = useSelector(
    (state) => state.GetEmployeeDetials.employeeDetails
  );

  return (
    <div style={{ padding: '0  25px' }}>
      <div className="employee-edit-view-row">
        <div className="employee-edit-view-name">Phone Number</div>
        <div className="employee-edit-view-title">
          <input
            required
            style={{ fontSize: '14px' }}
            placeholder="Phone Number"
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={props.userAdditionalInfo.phoneNumber}
            onChange={(e) => props.onChange('phoneNumber', e.target.value)}
          ></input>
          <Box component="img" src="../assets/Images/call-phone.svg" />
        </div>
        <div className="employee-edit-view-helper">
          Add country code for e.g +92
        </div>
      </div>
      <Divider />
      <div className="employee-edit-view-row">
        <div className="employee-edit-view-name">Date of birth</div>
        <div className="employee-edit-view-title">
          <input
            style={{ fontSize: '14px' }}
            placeholder="Birth date"
            type="date"
            name="dateOfBirth"
            id="dateOfBirth"
            value={getDateInDashForCalendar(
              props.userAdditionalInfo.dateOfBirth
            )}
            onChange={(e) => props.onChange('dateOfBirth', e.target.value)}
          ></input>
          <Box component="img" src="../assets/Images/calendar-dates.svg" />
        </div>
        <div className="employee-edit-view-helper">
          Enter your date of birth
        </div>
      </div>
      <Divider />
      <div className="employee-edit-view-row">
        <div className="employee-edit-view-name">Email Address</div>
        <div className="employee-edit-view-title">
          <input
            style={{ fontSize: '14px' }}
            disabled
            placeholder="Email Address"
            type="email"
            id="email"
            name="email"
            value={props.userAdditionalInfo.email}
            onChange={(e) => props.onChange('email', e.target.value)}
          ></input>
          <Box component="img" src="../assets/Images/mail.svg" />
        </div>
        <div className="employee-edit-view-helper">
          Enter your Email Address
        </div>
      </div>
      <Divider />
      <div className="employee-edit-view-row">
        <div className="employee-edit-view-name">Residential Address</div>
        <div className="employee-edit-view-title">
          <input
            style={{ fontSize: '14px' }}
            placeholder="Address1"
            type="text"
            id="addressLine1"
            name="addressLine1"
            value={props.userAdditionalInfo.addressLine1}
            onChange={(e) => props.onChange('addressLine1', e.target.value)}
          ></input>
          <Box component="img" src="../assets/Images/home.svg" />
        </div>
        <div className="employee-edit-view-helper">
          Enter your Residential Address
        </div>
      </div>
    </div>
  );
}

export default EmployeeDetailsEditView;
