import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import EmployeeCard from "./EmployeeCover";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeExperience from "./EmployeeExperience";
import HrPoc from "components/Screens/DashBoard/EmployeeDashboardV2/HrPoc/HrPoc";
import PageToolBar from "components/Widgets/PageToolBar";
import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";

import { GetEmployeeById } from "store/actions/EmployeePortal/GetEmployeeDetials";
import { baseUrl } from "util/APIBaseUrl";

import "./EmployeeProfile.css";

function EmployeeProfile(props) {
  const { employeeId } = useParams();
  const experienceAtEdgeRef = useRef(null);
  const profileInfoRef = useRef(null);
  const [tab, setTab] = React.useState(1);
  const userDetails = JSON.parse(localStorage.getItem("loggedInUser"));
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  // constants
  const dispatch = useDispatch();

  // redux state
  const employeeDetails = useSelector((state) => state.GetEmployeeDetials.employeeDetails);
  const isLoadingEmployeeDetails = useSelector((state) => state.GetEmployeeDetials.isLoadingEmployeeDetails);
  // custom hook
  const { loggedInUser } = usePermissions();

  useEffect(() => {
    if (employeeId) {
      dispatch(
        GetEmployeeById({
          employeeId: employeeId,
        })
      );
    } else {
      dispatch(
        GetEmployeeById({
          employeeId: loggedInUser?.userId,
        })
      );
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Edge | Profile</title>
      </Helmet>
      {(loadingAdditionalInfo || isLoadingEmployeeDetails) && <PageLoader />}
      <div
        ref={profileInfoRef}
        style={{
          backgroundColor: "#FBFAFF",
          height: "calc(100vh - 46px)",
          overflow: "scroll",
        }}
      >
        <EmployeeCard
          pictureUrl={`${baseUrl}/employee/${employeeDetails?.employeeId}/logo?id=${employeeDetails?.pictureVersion}`}
          name={employeeDetails?.firstName}
        />
        <div className="ep-profile-main">
          <PageToolBar
            tab1Title={{
              title: "Personal",
              navLink: null,
            }}
            tab2Title={{
              title: "Experience",
              navLink: null,
            }}
            tab={tab}
            setTab={(val) => {
              setTab(val);
              if (val === 2) return experienceAtEdgeRef?.current.scrollIntoView();
              profileInfoRef?.current.scrollIntoView();
            }}
            backgroundColor="white"
            width="97%"
          />

          <div className="employee-profile-d22">
            <div>
              <div className="employee-profile-d22A">
                <EmployeeDetails
                  userAdditionalInfo={userAdditionalInfo}
                  userDetails={userDetails}
                  employeeDetails={employeeDetails}
                />
              </div>
              <br />
              <div className="employee-profile-d22A">
                <HrPoc employeeDetails={employeeDetails} isProfilePage />
              </div>
              <br />
              <div ref={experienceAtEdgeRef}>
                <EmployeeExperience employeeDetails={employeeDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeProfile;
