import React from "react";
//material ui
import Paper from "@material-ui/core/Paper";
import { Grid, Typography, Box } from "@mui/material";
//material ui table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableContainer } from "@mui/material";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
//router
import { useNavigate } from "react-router-dom";

export default function DataTableBasic(props) {
  const {
    rowsData,
    tableHeader,
    padding,
    title,
    subTitle,
    cellAlign,
    titleIcon,
    toolBar,
    navigateTo,
    handleSelectedRow,
    addNewUserButton,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const screenSize = useMediaQuery(theme.breakpoints.up("xs"));

  //component state
  const [headers, setHeaders] = React.useState([]);
  const [tableRowsData, setTableRowsData] = React.useState([]);

  //funcations
  const handleRowSelected = (id) => {
    if (id) {
      handleSelectedRow(id);
      // if (slectedRowNavigation != null) {
      //     navigate(`${slectedRowNavigation}/${id}`);
      // }
    }

    // setSelected(newSelected);
  };

  //methods
  React.useEffect(() => {
    if (rowsData != null && rowsData.length > 0) {
      setHeaders(Object.keys(rowsData[0]));
    }
    setTableRowsData(rowsData);
  }, [rowsData]);

  React.useEffect(() => {}, [tableRowsData]);

  return (
    <TableContainer
      component={Paper}
      sx={
        padding == null
          ? {
              px: 3,
              borderRadius: "7px !important",
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 4px !important",
            }
          : {
              px: 0,
              borderRadius: "7px !important",
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 4px !important",
            }
      }
    >
      {toolBar != false ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{ px: 2, py: 3 }}
        >
          <Grid item>
            <Box display="flex">
              {titleIcon ? (
                <img src={titleIcon} height={22} width={"auto"} />
              ) : null}
              <Typography variant="h2" style={{ fontWeight: "bold" }} pl={2}>
                {title != null ? title : null}
              </Typography>
              <Typography variant="paragraph" color="gray">
                {subTitle != null ? subTitle : null}
              </Typography>
            </Box>
          </Grid>
          {title != null ? (
            <Grid item>
              {addNewUserButton != null
                ? addNewUserButton != "customerEmployee"
                  ? addNewUserButton
                  : null
                : // <Typography
                  //     fontSize="13px"
                  //     fontFamily="Cairo-SemiBold"
                  //     style={{
                  //         color: "#c1c1c1",
                  //         cursor: "pointer",
                  //     }}
                  //     onClick={() => {
                  //         if (navigateTo)
                  //             navigate(`${navigateTo}`);
                  //     }}
                  // >
                  //     See All
                  // </Typography>
                  null}
            </Grid>
          ) : null}
        </Grid>
      ) : null}

      <Table
        aria-label="simple table"
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "0.01rem #f5f5f5 solid",
          },
        }}
      >
        <TableHead>
          <TableRow>
            {tableHeader.map((header, index) => {
              return (
                <TableCell align={header.align} key={header.id}>
                  <Typography variant="h5">{header.label}</Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRowsData.map((row, index) => {
            return (
              <TableRow
                key={row.index}
                hover={handleSelectedRow ? true : false}
                style={{ cursor: "pointer" }}
                onClick={() => handleRowSelected(row.id)}
              >
                {tableHeader.map((header, index) => {
                  return (
                    <TableCell
                      key={index}
                      component="th"
                      scope="row"
                      align={header.align ? header.align : "center"}
                      width={header.width ? header.width : "auto"}
                    >
                      {row[header.id]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
