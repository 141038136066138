import { BG_COLORS } from "constants";

export function convertTimezonesToDropdownOptions(value) {
  return value?.map((key) => ({
    label: key.timezone || key.countryCode,
    value: key.timezone || key.countryCode,
  }));
}

export function getOrdinalSuffix(date) {
  if (date >= 11 && date <= 13) {
    return "th";
  }
  const lastDigit = date % 10;
  if (lastDigit === 1) {
    return "st";
  }
  if (lastDigit === 2) {
    return "nd";
  }
  if (lastDigit === 3) {
    return "rd";
  }
  return "th";
}

export function numberToCurrencyFormat(number) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getBackgroundColor() {
  return BG_COLORS.find((color) => color.path === window.location.pathname)?.color || "#F5F5FF";
}
