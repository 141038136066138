import React from "react";
import { Typography, Grid, Button, Paper, Toolbar, Snackbar, Alert } from "@mui/material";
//widgets
import Header from "../../../../Header/Header";
//components
import SalaryDetails from "./SalaryDetails";
import { AddEmployee } from "../../../../../store/actions/AddEmployee";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { AddEmployeeData } from "../../../../../store/actions/AddEmployeeData";
import {
  AddEmployeeSalary,
  ResetAddEmployeeSalary,
} from "../../../../../store/actions/Workforce/AddEmployee/AddEmployeeSalary";
import SuccessfullAddedEmployee from "./SuccessfullAddedEmployee";
//router
import { useNavigate, useLocation } from "react-router-dom";
//enum
import { Page } from "./pages.enum";
//widget
import FormWidget from "../../../../Widgets/FormWidget";
import StepperBar from "./StepperBar";
import { ResetCalculateEmployeeSalary } from "../../../../../store/actions/Workforce/AddEmployee/CalculateEmployeeSalary";
import { baseUrl } from "../../../../../util/APIBaseUrl";
import { GetEmployeeDetails } from "../../../../../store/actions/GetEmployeeDetails";
import { getDateFromUtcFormat, getError, isEmptyObject } from "../../../CommonUtils";
import { ResetAddEmployeeSalaryData } from "../../../../../store/actions/Workforce/AddEmployee/AddEmployeeSalaryData";

import { employeeProfileFields } from "./ProfileInputsFields";
import { usePermissions } from "../../../../Hook";
import PageLoader from "../../../../common/PageLoader";

const profileData = {
  name: "",
  linkedUrl: "",
  firstName: "",
  lastName: "",
  email: "",
  jobTitle: "",
  birthday: new Date(),
  contactNumber: "",
  country: { key: null, value: "" },
  residentialAddress: "",
  state: "",
  city: "",
  gender: { key: "Male", value: "Male" },
  code: "",
  imageBlob: "",
  loadFromUrl: false,
  discount: false,
  discountPercentage: 0,
  flatDiscount: 0,
  startingDate: new Date(),
  discountDuration: { key: null, value: "" },
  edgeJoiningDate: new Date(),
  allowedForPayoneer: false,
};

const Months = [
  { key: "01", value: "January" },
  { key: "02", value: "February " },
  { key: "03", value: "March" },
  { key: "04", value: "April" },
  { key: "05", value: "May" },
  { key: "06", value: "June" },
  { key: "07", value: "July" },
  { key: "08", value: "August" },
  { key: "09", value: "September " },
  { key: "10", value: "October" },
  { key: "11", value: "November" },
  { key: "12", value: "December" },
];

const discountDurationOptions = [
  { key: "01", value: "One Month" },
  { key: "02", value: "Two Months" },
  { key: "03", value: "Three Months" },
  { key: "04", value: "Four Months" },
  { key: "05", value: "Five Months" },
  { key: "06", value: "Six Months" },
  { key: "07", value: "Seven Months" },
  { key: "08", value: "Eight Months" },
  { key: "09", value: "Nine Months" },
  { key: "10", value: "Ten Months" },
  { key: "11", value: "Eleven Months" },
  { key: "12", value: "One Year" },
];

const genderOptions = [
  { key: "Male", value: "Male" },
  { key: "Female", value: "Female" },
];

function index(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { navigatedFor, editEmployeeId, redirectTo } = state; // Read values passed on state

  //custom hook
  const { loggedInUser } = usePermissions();

  //component states
  const [page, setPage] = React.useState(Page.PROFILE);
  const [activeStep, setActiveStep] = React.useState(Page.PROFILE);
  const [nextButtonClick, setNextButtonClick] = React.useState(false);
  const [profilePic, setProfilePic] = React.useState({
    ok: true,
    message: null,
  });
  const [salaryErrorMessage, setSalaryErrorMesssage] = React.useState(false);
  const [disErrorMessage, setDisErrorMesssage] = React.useState(false);

  const [dropdownData_, setdropdownData_] = React.useState({});
  const [countryDropdwon, setCountryDropdown] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [discountField, setDiscountField] = React.useState({
    flatDiscountLabel: "FLAT DISCOUNT",
    requiredflatDiscount: false,
    discountPercentageLabel: "DISCOUNT %",
    requiredDiscountPercentage: false,
    disabled: true,
  });

  const [buttonClicked, setButtonClicked] = React.useState(null);
  const [employeeProfileData, setEmployeeProfileData] = React.useState(profileData);

  //redux state

  //add employee

  const addEmployeeInfo = useSelector((state) => state.AddEmployee.addEmployeeInfo);
  const loadingAddEmployeeInfo = useSelector((state) => state.AddEmployee.loadingAddEmployeeInfo);

  //employee salary
  const addEmployeeSalaryData = useSelector((state) => state.AddemployeeSalaryData.addEmployeeSalaryData);

  const calculateEmployeeSalary = useSelector((state) => state.CalculateEmployeeSalary.calculateEmployeeSalary);
  const loadingCalculateEmployeeSalary = useSelector(
    (state) => state.CalculateEmployeeSalary.loadingCalculateEmployeeSalary
  );

  const addEmployeeSalary = useSelector((state) => state.AddEmployeeSalary.addEmployeeSalary);
  const loadingAddEmployeeSalary = useSelector((state) => state.AddEmployeeSalary.loadingAddEmployeeSalary);

  //employee education
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const addEmployeeEducation = useSelector((state) => state.AddEmployeeEducation.addEmployeeEducation);
  const loadingAddEmployeeEducation = useSelector((state) => state.AddEmployeeEducation.loadingAddEmployeeEducation);

  //employee experience

  const addEmployeeExperience = useSelector((state) => state.AddEmployeeExperience.addEmployeeExperience);
  const loadingAddEmployeeExperience = useSelector((state) => state.AddEmployeeExperience.loadingAddEmployeeExperience);

  //functions
  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }

    // window.location.reload();
    return response;
  };

  const getValue = (value) => {
    if (value != undefined && value != null && value != "") {
      return value;
    } else {
      return "";
    }
  };

  const handleProfileData = (event, fieldName, fieldType) => {
    if (fieldType == "number") {
      const onlyNums = event.target.value.replace(/[^0-9\+]/g, "");

      setEmployeeProfileData({
        ...employeeProfileData,
        [event.target.name]: onlyNums,
      });
    } else if (fieldType == "checkBox") {
      setEmployeeProfileData({
        ...employeeProfileData,
        [event.target.name]: event.target.checked,
      });
    } else if (fieldType == "switch") {
      setEmployeeProfileData({
        ...employeeProfileData,
        [event.target.name]: event.target.checked,
      });
    } else {
      setEmployeeProfileData({
        ...employeeProfileData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const selectProfilePicture = (imageBlob, formData) => {
    setEmployeeProfileData({
      ...employeeProfileData,
      imageBlob: imageBlob,
      loadFromUrl: false,
      formData: formData,
    });
  };

  const callbackFromMultiSelect = (option, key) => {
    employeeProfileData[key] = option;
    setEmployeeProfileData({
      ...employeeProfileData,
    });
  };

  const callbackFromDatePicker = (targetName, newValue) => {
    setEmployeeProfileData({
      ...employeeProfileData,
      [targetName]: newValue,
    });
  };

  // const handleSave = () => {
  //     let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  //     if (page == Page.EXPERIENCE) {
  //         if (
  //             navigatedFor == "addEmployee" &&
  //             addEmployeeInfo?.payload?.employeeId != undefined
  //         ) {
  //             dispatch(
  //                 AddEmployeeExperience({
  //                     experienceData: addEmployeeExperienceData,
  //                     employeeId: addEmployeeInfo?.payload?.employeeId,
  //                 })
  //             );
  //         } else if (navigatedFor == "editEmployee") {
  //             dispatch(
  //                 AddEmployeeExperience({
  //                     experienceData: addEmployeeExperienceData,
  //                     employeeId: getEmployeeDetails?.payload?.employeeId,
  //                 })
  //             );
  //         }
  //     }
  // };

  const apiCallEmployeePersonalDetails = () => {
    if (navigatedFor == "addEmployee") {
      dispatch(
        AddEmployee({
          employeeProfileData: employeeProfileData,

          method: "POST",
          request: "employees",
        })
      );
    } else if (navigatedFor == "editEmployee") {
      dispatch(
        AddEmployee({
          employeeProfileData: employeeProfileData,
          employeeId: getEmployeeDetails?.payload.employeeId,
          method: "PATCH",
          request: `employee/${getEmployeeDetails?.payload.employeeId}`,
        })
      );
    }
  };

  const checkSalaryDiscountPerField = () => {
    if (
      addEmployeeSalaryData.employeeSalaryDetails.discountPercentage != 0 ||
      addEmployeeSalaryData.employeeSalaryDetails.flatDiscount != 0
    ) {
      apiCallEmployeeSalaryDetails();
    } else {
      setOpenAlert(true);
      setNextButtonClick(true);
    }
  };

  const apiCallEmployeeSalaryDetails = () => {
    if (navigatedFor == "addEmployee" && addEmployeeInfo?.payload?.employeeId != undefined) {
      dispatch(
        AddEmployeeSalary({
          salaryData: addEmployeeSalaryData.employeeSalaryDetails,
          employeeId: addEmployeeInfo?.payload?.employeeId,
        })
      );
    } else if (navigatedFor == "editEmployee") {
      dispatch(
        AddEmployeeSalary({
          salaryData: addEmployeeSalaryData.employeeSalaryDetails,
          employeeId: getEmployeeDetails?.payload?.employeeId,
        })
      );
    }
  };

  const handleAddEmployee = (button) => {
    setButtonClicked(button);

    if (page == Page.PROFILE) {
      if (
        employeeProfileData.firstName != "" &&
        employeeProfileData.lastName != "" &&
        employeeProfileData.email &&
        employeeProfileData.residentialAddress &&
        employeeProfileData.country.key != null
      ) {
        apiCallEmployeePersonalDetails();
      } else {
        setNextButtonClick(true);
        setOpenAlert(true);
      }
    } else if (page == Page.EMPLOYEE_ADD) {
      //dispatch(ResetAddEmployee({}));
      setPage(Page.SALARY);
    } else if (page == Page.SALARY) {
      if (
        addEmployeeSalaryData.employeeSalaryDetails.costToCustomer != "" &&
        addEmployeeSalaryData.employeeSalaryDetails.edgeFee != "" &&
        parseInt(addEmployeeSalaryData.employeeSalaryDetails.edgeFee) <
          parseInt(addEmployeeSalaryData.employeeSalaryDetails.costToCustomer) &&
        !disErrorMessage &&
        calculateEmployeeSalary?.ok
      ) {
        if (addEmployeeSalaryData.employeeSalaryDetails?.discount) {
          checkSalaryDiscountPerField();
        } else {
          apiCallEmployeeSalaryDetails();
        }
      } else {
        setNextButtonClick(true);
        setOpenAlert(true);
      }
    } else {
      if (page < Page.EXPERIENCE) setPage(page + 1);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const getNewActiveStep = (newActiveStep) => {
    if (navigatedFor == "editEmployee") {
      // setNextButtonClick(true);
      if (newActiveStep >= 1) {
        setActiveStep(newActiveStep + 1);
        setPage(newActiveStep + 1);
      } else {
        setActiveStep(newActiveStep);
        setPage(newActiveStep);
      }
    }
  };

  const getEmployeeId = () => {
    switch (navigatedFor) {
      case "editEmployee":
        return getEmployeeDetails?.payload?.employeeId;
      case "addEmployee":
        return addEmployeeInfo?.payload?.employeeId;
      default:
        return editEmployeeId;
    }
  };

  //methods

  React.useEffect(() => {
    let logoRequest = "";

    // if (isValidResponse(addEmployeeInfo)) {
    if (addEmployeeInfo?.ok) {
      // if (navigatedFor == "addEmployee") {
      // not working , cannot find employee id
      if (loggedInUser && addEmployeeInfo) {
        logoRequest = `employee/${getEmployeeId()}/logo`;
      }
      if (employeeProfileData?.formData && addEmployeeInfo) {
        postLogo({
          request: logoRequest,
          formData: employeeProfileData?.formData,
        }).then((res) => {
          if (res?.ok) {
            setNextButtonClick(false);
            if (page < Page.EXPERIENCE) {
              // setPage(page + 1);
              setPage(Page.EMPLOYEE_ADD);
            }

            setOpenAlert(false);
            setProfilePic({
              ok: false,
              message: "Profile Picture Not Uploaded !",
            });
          } else {
            setOpenAlert(true);
            setNextButtonClick(true);
            setProfilePic({
              ok: false,
              message: "Profile Picture Not Uploaded !",
            });
          }
        });
      } else {
        setNextButtonClick(false);
        if (page < Page.EXPERIENCE) {
          // setPage(page + 1);
          setPage(Page.EMPLOYEE_ADD);
        }

        setOpenAlert(false);
        setProfilePic({
          ok: true,
          message: null,
        });
      }
      // }
    } else if (addEmployeeInfo?.ok == false) {
      setNextButtonClick(true);
      setOpenAlert(true);
    }
    // }
  }, [addEmployeeInfo]);

  React.useEffect(() => {
    if (addEmployeeSalary != null && addEmployeeSalary != undefined && Object.keys(addEmployeeSalary).length) {
      if (addEmployeeSalary?.ok) {
        setNextButtonClick(false);
        dispatch(ResetAddEmployeeSalary({}));
        // dispatch(ResetCalculateEmployeeSalary({}));
        if (buttonClicked == "Done") {
          if (redirectTo == "workforce") {
            navigate("/main/workforce");
          } else if (redirectTo == "details") {
            // navigate(`/main/details/${editEmployeeId}`);
            navigate(`/main/details/${editEmployeeId}`, {
              state: {
                redirectFrom: "editEmployee",
              },
            });
          }
        } else {
          if (page < Page.EXPERIENCE) {
            // setPage(page + 1);
            setPage(Page.EDUCATION);
          }
        }

        setOpenAlert(false);
      } else {
        setNextButtonClick(true);
        setOpenAlert(true);
      }
    }
  }, [addEmployeeSalary]);

  React.useEffect(() => {
    if (Object.keys(addEmployeeSalaryData).length) {
      // dispatch(ResetCalculateEmployeeSalary({}));
      if (
        parseInt(addEmployeeSalaryData.employeeSalaryDetails.edgeFee) >=
        parseInt(addEmployeeSalaryData.employeeSalaryDetails.costToCustomer)
      ) {
        setSalaryErrorMesssage(true);
      } else {
        if (
          addEmployeeSalaryData.employeeSalaryDetails?.discount &&
          parseInt(addEmployeeSalaryData.employeeSalaryDetails?.flatDiscount) >
            parseInt(addEmployeeSalaryData?.employeeSalaryDetails?.edgeFee)
        ) {
          setDisErrorMesssage(true);
        } else {
          setDisErrorMesssage(false);
        }
        setOpenAlert(false);
        setSalaryErrorMesssage(false);
      }
    } else {
      setOpenAlert(false);
      setDisErrorMesssage(false);
      setSalaryErrorMesssage(false);
    }
  }, [addEmployeeSalaryData]);

  React.useEffect(() => {
    if (salaryErrorMessage) {
      setOpenAlert(true);
    }
  }, [salaryErrorMessage]);

  React.useEffect(() => {
    if (disErrorMessage) {
      setOpenAlert(() => true);
    } else {
      setOpenAlert(false);
    }
  }, [disErrorMessage]);

  React.useEffect(() => {
    if (page == Page.PROFILE) {
      setActiveStep(Page.PROFILE);
    }
    if (page == Page.SALARY) {
      setActiveStep(Page.SALARY);
    }
    if (page == Page.EDUCATION) {
      setActiveStep(Page.EDUCATION);
    }
    if (page == Page.EXPERIENCE) {
      setActiveStep(Page.EXPERIENCE);
    }
  }, [page]);

  React.useEffect(() => {
    let dropdownData = {};
    let countrySelected = [];
    let genderSelected = [];
    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    if (navigatedFor != null && navigatedFor != undefined && navigatedFor == "editEmployee" && getEmployeeDetails?.ok) {
      countrySelected = dropdownData?.payload?.country.filter(
        (country) => country?.countryCode == getEmployeeDetails?.payload?.country
      );

      genderSelected = genderOptions.filter((gender) => gender?.value == getEmployeeDetails?.payload?.gender);
      console.log({
        edgeJoiningDate: getDateFromUtcFormat(getEmployeeDetails?.payload?.edgeJoiningDate),
        simple: getEmployeeDetails?.payload?.edgeJoiningDate,
      });

      setEmployeeProfileData({
        firstName: getValue(getEmployeeDetails?.payload?.firstName),
        lastName: getValue(getEmployeeDetails?.payload?.lastName),
        linkedUrl: getValue(getEmployeeDetails?.payload?.Linkedin_Url),
        email: getValue(getEmployeeDetails?.payload?.email),
        jobTitle: getValue(getEmployeeDetails?.payload?.jobTitle),
        birthday: getDateFromUtcFormat(getEmployeeDetails?.payload?.dateOfBirth),
        edgeJoiningDate: getDateFromUtcFormat(getEmployeeDetails?.payload?.edgeJoiningDate),
        contactNumber: getValue(getEmployeeDetails?.payload?.phoneNumber),
        country: {
          key: countrySelected && countrySelected.length ? countrySelected[0]?.countryCode2 : null,
          value: countrySelected && countrySelected.length ? countrySelected[0]?.countryCode : null,
        },
        residentialAddress: getValue(getEmployeeDetails?.payload?.addressLine1),
        state: getValue(getEmployeeDetails?.payload?.state),
        city: getValue(getEmployeeDetails?.payload?.city),
        gender: {
          key: genderSelected[0]?.key,
          value: genderSelected[0]?.value,
        },
        code: getValue(getEmployeeDetails?.payload?.zip),
        imageBlob: `${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/logo?id=${getEmployeeDetails?.payload?.pictureVersion}`,
        loadFromUrl: true,
        allowedForPayoneer: getEmployeeDetails?.payload?.Allowed_For_Payoneer,
      });
    }
  }, [getEmployeeDetails]);

  React.useEffect(() => {
    let countries = [];
    let dropdownData = {};

    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    setPage(Page.PROFILE);
    setNextButtonClick(false);
    setOpenAlert(false);

    if (isEmptyObject(loggedInUser) && !Object.keys(getEmployeeDetails).length && editEmployeeId) {
      dispatch(
        GetEmployeeDetails({
          customerID: loggedInUser?.userId,
          employeeID: editEmployeeId,
          request: `employee/${editEmployeeId}`,
        })
      );
    }

    if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
      dropdownData?.payload?.country?.map((data) => {
        countries.push({
          key: data.countryCode2,
          value: data.countryCode,
        });
      });
      setCountryDropdown(countries);
      setdropdownData_({
        ...dropdownData_,
        countries: countries,
      });
    }
  }, []);

  return (
    <>
      {loadingAddEmployeeEducation == true ||
      loadingAddEmployeeExperience == true ||
      loadingAddEmployeeInfo == true ||
      loadingAddEmployeeSalary == true ||
      loadingCalculateEmployeeSalary == true ||
      loadingGET_EMPLOYEE_Details == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid container direction="column" rowSpacing={3}>
        <Header
          data={{
            heading: navigatedFor == "editEmployee" ? "Edit Employee" : "Add Employee",
            subHeader: null,
            profileWithIcon: null,
          }}
        />

        <Grid item>
          <Paper sx={{ p: 3 }}>
            <Grid item sx={{ mt: 3 }}>
              <StepperBar activeStep={activeStep} getNewActiveStep={getNewActiveStep} />
            </Grid>

            <Toolbar />
            <Grid
              item
              container
              direction="row"
              sx={{
                minHeight: "calc(54vh)",
                height: "100%",
                overflowY: "auto",
              }}
            >
              {page == Page.PROFILE ? (
                <FormWidget
                  nextButtonClick={nextButtonClick}
                  formData={employeeProfileData}
                  selectProfilePicture={selectProfilePicture}
                  handleDataChange={handleProfileData}
                  callbackFromMultiSelect={callbackFromMultiSelect}
                  callbackFromDatePicker={callbackFromDatePicker}
                  data={employeeProfileFields(
                    dropdownData_,
                    discountField,
                    discountDurationOptions,
                    Months,
                    genderOptions
                  )}
                />
              ) : // <Profile nextButtonClick={nextButtonClick} />
              page == Page.EMPLOYEE_ADD ? (
                <SuccessfullAddedEmployee navigatedFor={navigatedFor} />
              ) : page == Page.SALARY ? (
                <SalaryDetails nextButtonClick={nextButtonClick} />
              ) : // : page == Page.EDUCATION ? (
              //     <Education nextButtonClick={nextButtonClick} />
              // ) : page == Page.EXPERIENCE ? (
              //     <Experience />
              // )
              null}

              <Toolbar variant="dense" />
            </Grid>

            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                {page != Page.PROFILE && page != Page.EMPLOYEE_ADD ? (
                  <Button
                    variant="contained"
                    id="doneButton"
                    disableElevation
                    fullWidth
                    sx={{
                      width: "170px",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      if (page > Page.PROFILE && page <= Page.EXPERIENCE && page != Page.EMPLOYEE_ADD) {
                        setNextButtonClick(false);
                        setOpenAlert(false);
                        setPage(page - 1);
                        dispatch(ResetAddEmployeeSalary({}));

                        dispatch(ResetCalculateEmployeeSalary({}));
                      }
                    }}
                  >
                    <Typography variant="h6" color="white">
                      {page == Page.EMPLOYEE_ADD ? "DONE" : "BACK"}
                    </Typography>
                  </Button>
                ) : null}
              </Grid>
              <Grid item textAlign="center">
                <Snackbar
                  open={openAlert}
                  autoHideDuration={2500}
                  onClose={handleAlertClose}
                  sx={{
                    "&.MuiSnackbar-root": {
                      position: "relative",
                      bottom: 9,
                      left: 0,
                    },
                  }}
                >
                  <Alert onClose={handleAlertClose} severity="error">
                    {addEmployeeInfo?.ok == false
                      ? getError(addEmployeeInfo?.payload?.error)
                      : calculateEmployeeSalary?.ok == false
                      ? getError(calculateEmployeeSalary?.payload?.error)
                      : addEmployeeSalary?.ok == false
                      ? getError(addEmployeeSalary?.payload?.error)
                      : addEmployeeEducation?.ok == false
                      ? getError(addEmployeeEducation?.payload?.error)
                      : addEmployeeExperience?.ok == false
                      ? getError(addEmployeeExperience?.payload?.error)
                      : salaryErrorMessage
                      ? "Cost to customer must be greater than edge fee"
                      : disErrorMessage
                      ? "Flat Discount must be smaller than edge fee"
                      : profilePic.ok == false
                      ? profilePic.message
                      : "REQUIRED FIELD MISSING!"}
                  </Alert>
                </Snackbar>
              </Grid>
              <Grid item>
                <Grid item container direction="row" columnSpacing={3}>
                  <Grid item>
                    {page == Page.PROFILE ? (
                      <Button
                        variant="text"
                        fullWidth
                        disableElevation
                        sx={{
                          width: "70px",
                          borderRadius: "4px",

                          p: 1,
                        }}
                        onClick={() =>
                          // navigate("/main/workforce")
                          {
                            if (redirectTo == "workforce") {
                              navigate("/main/workforce");
                            } else if (redirectTo == "details") {
                              // navigate(
                              //     `/main/details/${editEmployeeId}`
                              // );
                              navigate(`/main/details/${editEmployeeId}`, {
                                state: {
                                  redirectFrom: "editEmployee",
                                },
                              });
                            }
                          }
                        }
                      >
                        <Typography variant="h6">Cancel</Typography>
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        id="doneButton"
                        disableElevation
                        fullWidth
                        sx={{
                          width: "170px",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          if (page == Page.EMPLOYEE_ADD) {
                            dispatch(AddEmployeeData({}));
                            if (redirectTo == "workforce") {
                              navigate("/main/workforce");
                            } else if (redirectTo == "details") {
                              navigate(`/main/details/${editEmployeeId}`, {
                                state: {
                                  redirectFrom: "editEmployee",
                                },
                              });
                            }
                          } else {
                            handleAddEmployee("Done");
                          }
                        }}
                      >
                        <Typography variant="h6" color="white">
                          {page == Page.SALARY ? "SAVE" : "DONE"}
                        </Typography>
                      </Button>
                    )}
                  </Grid>

                  <Grid item>
                    {page < Page.SALARY ? (
                      <Button
                        variant="contained"
                        id="nextButton"
                        disableElevation
                        fullWidth
                        sx={{
                          width: "170px",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          dispatch(ResetAddEmployeeSalary({}));
                          dispatch(ResetCalculateEmployeeSalary({}));
                          dispatch(ResetAddEmployeeSalaryData({}));
                          handleAddEmployee("Next");
                        }}
                      >
                        <Typography variant="h6" color="white">
                          NEXT
                        </Typography>
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default index;
