import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import AdminDashBoard from "./AdminDashBoard";
import CustomerDashBoard from "./CustomerDashBoardV2";
import EmployeeDashboard from "./EmployeeDashboardV2";
import { usePermissions } from "components/Hook";

import { DropdownData } from "store/actions/DropdownData";

function index(props) {
  const dispatch = useDispatch();
  const { loggedInUserType } = usePermissions();

  React.useEffect(() => {
    dispatch(DropdownData());
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Dashboard</title>
      </Helmet>
      {loggedInUserType === "edge" ? (
        <AdminDashBoard />
      ) : loggedInUserType === "customer" ? (
        <CustomerDashBoard />
      ) : loggedInUserType === "employee" ? (
        <EmployeeDashboard />
      ) : null}
    </>
  );
}

export default index;
