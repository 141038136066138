import React from "react";
//materail ui
import { Avatar, Badge, Box, Breadcrumbs, Grid, IconButton, Link, Typography } from "@mui/material";
import Button from "../../Button/Button";
import { MdOutlineArrowBack } from "react-icons/md";
import UserPermissions from "./UserPermissions";
// import UserPermissions from "./UserPermissions/UserPermission";
import AvatarIcon from "../../Icons/AvatarIcon";
import { baseUrl } from "../../../util/APIBaseUrl";
import EditIconLarge from "../../../assets/images/EditIconLarge.png";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { GetCustomerUserandCompanyProfileData } from "../../../store/actions/Settings/CustomerUser&CompanyProfileData";
import GeneralInfo from "./GeneralInfo";
import CompanyInfo from "./CompanyInfo";
import { usePermissions } from "../../Hook";
import { isEmptyObject } from "../CommonUtils";
import ProfilePicture from "./Profile/ProfilePicture";
import PageTitle from "../../Widgets/PageTitle";
import PageToolBar from "../../Widgets/PageToolBar";
import PageLoader from "../../common/PageLoader";

function CustomerSetting(props) {
  let photoInput = React.createRef();
  const dispatch = useDispatch();

  //component state
  const [tab, setTab] = React.useState(0);
  const [companyProfileUrl, setCompanyProfileUrl] = React.useState(null);
  const [companyImg, setCompanyImg] = React.useState({
    loadFromUrl: true,
  });

  //custom hook
  const { loggedInUser, loggedInUserType } = usePermissions();

  //redux state
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);
  //function
  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    // window.location.reload();
    const data = await response.json();
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let logoRequest = "";

    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let reader = new FileReader();
    let inFile = e.target.files[0];
    var form_data = new FormData();
    form_data.append("file", inFile);

    if (inFile.size > 77e6) {
      window.alert("Please upload a file smaller than 65 KB");
      return false;
    } else if (inFile.size < 76e6) {
      reader.onloadend = () => {
        setCompanyImg({
          ...companyImg,
          picFile: inFile,
          imagePreviewUrl: reader.result,

          imageBlob: reader.result.split(",")[1],
          formData: form_data,
          loadFromUrl: false,
        });
        if (loggedInUser?.type == "customer") {
          logoRequest = `customer/${loggedInUser?.customerId}/logo`;
        }
        postLogo({
          request: logoRequest,
          formData: form_data,
        });
      };

      // selectProfilePicture(reader.result.split(",")[1], form_data);
    }
    reader.readAsDataURL(inFile);
  };

  //methods

  React.useEffect(() => {
    let initialSelectedTab = {
      edge: 2,
      customer: 1,
    };
    setTab(initialSelectedTab[loggedInUserType]);

    if (isEmptyObject(loggedInUser)) {
      let getProfileDataRequest = {
        customer: `customerUser/${loggedInUser?.userId}`,
        edge: `user/${loggedInUser?.userId}`,
      };

      let companyProfileUrl = {
        customer: `${baseUrl}/customer/${loggedInUser?.customerId}/logo?id=${userAdditionalInfo?.customerPictureVersion}&variant=thumbnail`,
        edge: `${baseUrl}/edge/logo`,
      };

      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: getProfileDataRequest[loggedInUserType],
        })
      );

      setCompanyProfileUrl(companyProfileUrl[loggedInUserType]);
    }
  }, [loggedInUser, loggedInUserType]);

  return (
    <>
      {loadingAdditionalInfo && <PageLoader />}
      <Grid sx={{ background: "#FBFAFF", minHeight: "calc(100vh - 51px)" }}>
        <Grid
          container
          rowSpacing={3}
          sx={{
            marginTop: "5px",
          }}
        >
          <PageTitle
            background="#FBFAFF"
            page={{
              title: "Account Details",
              linkName: "Account",
              link: "",
            }}
          />

          <PageToolBar
            tab1Title={{
              title: loggedInUserType !== "edge" ? "Company Information" : null,
              navLink: null,
            }}
            tab2Title={{
              title: loggedInUserType !== "edge" ? "User Management" : "Manage Users",
              navLink: null,
            }}
            tab={tab}
            setTab={setTab}
          />
        </Grid>
        <Grid>
          {tab === 1 && (
            <>
              <Grid
                sx={{
                  maxWidth: "1076px",
                  width: "100%",
                  margin: "0px auto",
                  marginTop: "24px",
                  paddingTop: "0px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    gap: "16px",
                  }}
                >
                  <Grid sx={{ minWidth: "712px" }}>
                    <GeneralInfo title={"General Information"} />
                  </Grid>
                  <Grid sx={{ maxWidth: "348px", width: "100%" }}>
                    <ProfilePicture page="companyProfile" />
                    {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E0E0FF",
                      padding: "10px",
                      borderRadius: "12px",
                      height: "254px",
                      position: "relative",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={photoInput}
                      style={{ display: "none" }}
                    />
                    <Badge
                      variant="standard"
                      badgeContent={<img src={EditIconLarge} alt="edit icon" />}
                      overlap="rectangular"
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#F7F7F7",
                          minWidth: "50px",
                          height: "50px",
                          borderRadius: "25px",
                          boxShadow: "0px 4px 10px 0px #080D461F",
                          position: "absolute",
                          top: "170px",
                          right: "30px",
                        },
                      }}
                      onClick={() => {
                        uploadProfilePic();
                      }}
                    >
                      <Avatar
                        sx={{
                          width: "198px",
                          height: "198px",
                          borderRadius: "999px",
                        }}
                        src={
                          // companyProfileUrl
                          companyImg?.loadFromUrl !== false
                            ? companyProfileUrl
                            : `data:image/png;base64,${companyImg.imageBlob}`
                        }
                      />
                    </Badge>
                  </Box> */}
                  </Grid>
                </Grid>
                <Grid sx={{ maxWidth: "712px", marginTop: "16px" }}>
                  <CompanyInfo title={"Company Information"} />
                </Grid>
              </Grid>
            </>
          )}
          {tab === 2 && (
            <>
              <Grid
                sx={{
                  maxWidth: "1076px",
                  width: "100%",
                  margin: "24px auto",
                }}
              >
                <UserPermissions />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerSetting;
