/* eslint-disable camelcase */

import moment from "moment";

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTH_NAMES_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE_IN_MB = 5;
export const PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE = PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE_IN_MB * 1024 * 1024;
export const PRODUCT_FEEDBACK_FORM_FEEDBACK_DROPDOWN_VALUES = [
  { value: "Bug", label: "Bug" },
  { value: "Task", label: "Feature Improvement" },
];

export const TIMEOFF_REQUESTS = [
  {
    title: "Pending",
    link: "",
  },
  {
    title: "Scheduled",
    link: "",
  },
  {
    title: "Taken",
    link: "",
  },
  {
    title: "Rejected",
    link: "",
  },
  {
    title: "Cancelled",
    link: "",
  },
];

// eslint-disable-next-line camelcase
export const Employee_TIMEOFF_REQUESTS = [
  {
    title: "All",
    link: "",
  },
  {
    title: "Scheduled",
    link: "",
  },

  {
    title: "Rejected",
    link: "",
  },

  {
    title: "Pending",
    link: "",
  },

  {
    title: "Taken",
    link: "",
  },
  {
    title: "Cancelled",
    link: "",
  },
];
// eslint-disable-next-line camelcase
export const Employee_TIMEOFF_Details = [
  {
    title: "Overview",
    link: "",
  },
  {
    title: "Salary Details",
    link: "",
  },
  {
    title: "Documents",
    link: "",
  },
  {
    title: "Experience",
    link: "",
  },
  {
    title: "Notes",
    link: "",
  },
  // {
  //   title: "Time-off",
  //   link: "",
  // },
];

export const TIMEOFF_CUSTOMER = [
  {
    title: "Pending",
    link: "",
  },
  {
    title: "Scheduled",
    link: "",
  },
  {
    title: "Rejected",
    link: "",
  },
  {
    title: "Cancelled",
    link: "",
  },
  {
    title: "Taken",
    link: "",
  },
];

export const WORKFORCE_PROFILE_TABS = [
  {
    key: "overview",
    title: "Overview",
    link: "",
  },
  {
    key: "education",
    title: "Education",
    link: "",
  },
  {
    key: "experience",
    title: "Experience",
    link: "",
  },
  {
    key: "documents",
    title: "Documents",
    link: "",
  },
  {
    key: "payroll-history",
    title: "Payroll History",
    link: "",
  },
  {
    key: "time-off",
    title: "Time Off",
    link: "",
  },
];

export const getStartDateAndEndDate = (selectedKey) => {
  const today = moment().startOf("day");
  let startDate;
  let endDate;

  switch (selectedKey) {
    case "thisWeek":
      startDate = moment().startOf("week");
      endDate = moment().endOf("week");
      break;
    case "thisYear":
      startDate = moment().startOf("year");
      endDate = moment().endOf("year");
      break;
    case "thisMonth":
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
      break;
    case "last6Months":
      startDate = moment().subtract(6, "months").startOf("month");
      endDate = today;
      break;
    default:
      // Handle default case or provide a default range
      break;
  }

  return {
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
  };
};

export const USER_TYPES = {
  EMPLOYEE: "employee",
  CUSTOMER: "customer",
  EDGE: "edge",
};

export const LEAVE_STATUSES = {
  PENDING: "Pending",
  SCHEDULED: "Scheduled",
};

export const BG_COLORS = [
  { path: "/main/payment", color: "#FAFAFF" },
  { path: "/main/timeoff/admin/Utilisation-report", color: "#FEFEFE" },
  { path: "/main/timeoff", color: "#FEFEFE" },
  { path: "/main/timeoff/request", color: "#FEFEFE" },
];

export const COUNTRY_CODES = [
  { code: "+1", country: "United States", iso2: "US", preferred: true },
  { code: "+92", country: "Pakistan", iso2: "PK", preferred: true },
  { code: "+51", country: "Peru", iso2: "PE", preferred: true },
  { code: "+93", country: "Afghanistan", iso2: "AF" },
  { code: "+355", country: "Albania", iso2: "AL" },
  { code: "+213", country: "Algeria", iso2: "DZ" },
  { code: "+376", country: "Andorra", iso2: "AD" },
  { code: "+244", country: "Angola", iso2: "AO" },
  { code: "+672", country: "Antarctica", iso2: "AQ" },
  { code: "+54", country: "Argentina", iso2: "AR" },
  { code: "+374", country: "Armenia", iso2: "AM" },
  { code: "+61", country: "Australia", iso2: "AU" },
  { code: "+43", country: "Austria", iso2: "AT" },
  { code: "+994", country: "Azerbaijan", iso2: "AZ" },
  { code: "+973", country: "Bahrain", iso2: "BH" },
  { code: "+880", country: "Bangladesh", iso2: "BD" },
  { code: "+375", country: "Belarus", iso2: "BY" },
  { code: "+32", country: "Belgium", iso2: "BE" },
  { code: "+55", country: "Brazil", iso2: "BR" },
  { code: "+359", country: "Bulgaria", iso2: "BG" },
  { code: "+855", country: "Cambodia", iso2: "KH" },
  { code: "+1", country: "Canada", iso2: "CA" },
  { code: "+56", country: "Chile", iso2: "CL" },
  { code: "+86", country: "China", iso2: "CN" },
  { code: "+57", country: "Colombia", iso2: "CO" },
  { code: "+53", country: "Cuba", iso2: "CU" },
  { code: "+357", country: "Cyprus", iso2: "CY" },
  { code: "+420", country: "Czech Republic", iso2: "CZ" },
  { code: "+45", country: "Denmark", iso2: "DK" },
  { code: "+1-767", country: "Dominica", iso2: "DM" },
  { code: "+1-809", country: "Dominican Republic", iso2: "DO" },
  { code: "+20", country: "Egypt", iso2: "EG" },
  { code: "+372", country: "Estonia", iso2: "EE" },
  { code: "+251", country: "Ethiopia", iso2: "ET" },
  { code: "+358", country: "Finland", iso2: "FI" },
  { code: "+33", country: "France", iso2: "FR" },
  { code: "+995", country: "Georgia", iso2: "GE" },
  { code: "+49", country: "Germany", iso2: "DE" },
  { code: "+30", country: "Greece", iso2: "GR" },
  { code: "+852", country: "Hong Kong", iso2: "HK" },
  { code: "+36", country: "Hungary", iso2: "HU" },
  { code: "+354", country: "Iceland", iso2: "IS" },
  { code: "+91", country: "India", iso2: "IN" },
  { code: "+62", country: "Indonesia", iso2: "ID" },
  { code: "+98", country: "Iran", iso2: "IR" },
  { code: "+964", country: "Iraq", iso2: "IQ" },
  { code: "+353", country: "Ireland", iso2: "IE" },
  { code: "+44-1624", country: "Isle of Man", iso2: "IM" },
  { code: "+39", country: "Italy", iso2: "IT" },
  { code: "+1-876", country: "Jamaica", iso2: "JM" },
  { code: "+81", country: "Japan", iso2: "JP" },
  { code: "+962", country: "Jordan", iso2: "JO" },
  { code: "+7", country: "Kazakhstan", iso2: "KZ" },
  { code: "+254", country: "Kenya", iso2: "KE" },
  { code: "+850", country: "North Korea", iso2: "KP" },
  { code: "+82", country: "South Korea", iso2: "KR" },
  { code: "+371", country: "Latvia", iso2: "LV" },
  { code: "+965", country: "Kuwait", iso2: "KW" },
  { code: "+961", country: "Lebanon", iso2: "LB" },
  { code: "+218", country: "Libya", iso2: "LY" },
  { code: "+370", country: "Lithuania", iso2: "LT" },
  { code: "+352", country: "Luxembourg", iso2: "LU" },
  { code: "+853", country: "Macao", iso2: "MO" },
  { code: "+261", country: "Madagascar", iso2: "MG" },
  { code: "+60", country: "Malaysia", iso2: "MY" },
  { code: "+960", country: "Maldives", iso2: "MV" },
  { code: "+356", country: "Malta", iso2: "MT" },
  { code: "+52", country: "Mexico", iso2: "MX" },
  { code: "+976", country: "Mongolia", iso2: "MN" },
  { code: "+212", country: "Morocco", iso2: "MA" },
  { code: "+95", country: "Myanmar", iso2: "MM" },
  { code: "+977", country: "Nepal", iso2: "NP" },
  { code: "+31", country: "Netherlands", iso2: "NL" },
  { code: "+64", country: "New Zealand", iso2: "NZ" },
  { code: "+234", country: "Nigeria", iso2: "NG" },
  { code: "+47", country: "Norway", iso2: "NO" },
  { code: "+46", country: "Sweden", iso2: "SE" },
  { code: "+41", country: "Switzerland", iso2: "CH" },
  { code: "+971", country: "United Arab Emirates", iso2: "AE" },
  { code: "+44", country: "United Kingdom", iso2: "GB" },
];

export const REFERRAL_URLS = {
  Peru: "https://referral.onedge.co/access/?programidentifier=37030a9c-3397-4038-8c27-700f6a5c8a78&firstname=%7B%7Bcontact.firstname%7D%7D&lastname=%7B%7Bcontact.lastname%7D%7D&email=%7B%7Bcontact.email%7D%7D&utm_medium=direct&utm_source=employee-portal",
  Pakistan:
    "https://referral.onedge.co/access/?programidentifier=88c125e7-ef6f-451d-88e6-c32f065730e6&firstname=%7B%7Bcontact.firstname%7D%7D&lastname=%7B%7Bcontact.lastname%7D%7D&email=%7B%7Bcontact.email%7D%7D&utm_medium=direct&utm_source=employee-portal",
};
