import { call, put, takeEvery } from "redux-saga/effects";
import { fetchAdminSummarySuccess, fetchAdminSummaryFailure } from "store/actions/AdminSummary";
import { FETCH_ADMIN_SUMMARY_REQUEST } from "store/constants/AdminSummary";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

export const fetchAdminSummaryApi = async () => {
  return await getRequest(`${baseUrl}/admin/summary`)
    .then((response) => {
      console.log("API Response: ", response);
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

function* fetchAdminSummary() {
  try {
    const response = yield call(fetchAdminSummaryApi);
    yield put(fetchAdminSummarySuccess(response));
  } catch (error) {
    console.error(error);
    yield put(fetchAdminSummaryFailure(error));
  }
}

export default function* summarySaga() {
  yield takeEvery(FETCH_ADMIN_SUMMARY_REQUEST, fetchAdminSummary);
}
