import React from "react";

const HolidayCalendarIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3334 5.78809V3.03809H15.5V3.95475H13.6667V3.03809H6.33337V3.95475H4.50004V3.03809H2.66671V5.78809H17.3334ZM17.3334 7.62142H2.66671V16.7881H17.3334V7.62142ZM15.5 1.20475H17.3334C18.3459 1.20475 19.1667 2.02556 19.1667 3.03809V16.7881C19.1667 17.8006 18.3459 18.6214 17.3334 18.6214H2.66671C1.65419 18.6214 0.833374 17.8006 0.833374 16.7881V3.03809C0.833374 2.02556 1.65419 1.20475 2.66671 1.20475H4.50004V0.288086H6.33337V1.20475H13.6667V0.288086H15.5V1.20475Z"
        fill="#FBFAFF"
      />
    </svg>
  );
};

export default HolidayCalendarIcon;
