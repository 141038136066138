import { call, put, takeEvery } from "redux-saga/effects";
import {
  GetEmployeeByIdSuccess,
  GetEmployeeByIdFailure,
} from "../../actions/EmployeePortal/GetEmployeeDetials";
import { GET_EMPLOYEE_BY_ID } from "../../constants/EmployeePortal/GetEmployeeDetials";
import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchRequest = async (actions) => {
  return await getRequest(`${baseUrl}/employee/${actions.payload.employeeId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeeDetails(params) {
  try {
    let response = yield call(fetchRequest, params);
    yield put(GetEmployeeByIdSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(GetEmployeeByIdFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_EMPLOYEE_BY_ID, fetchEmployeeDetails);
}
