import React from "react";
//material ui
import { Avatar, Button, Grid, Paper, Typography } from "@mui/material";
//redux dispatch
import { useSelector } from "react-redux";
import { baseUrl } from "../../../../util/APIBaseUrl";
//Icons
import EditRoundedIcon from "@mui/icons-material/EditRounded";
//router
import { useNavigate } from "react-router-dom";
//router
import { useParams } from "react-router-dom";
import { convertDate, getValue } from "../../CommonUtils";
import PageLoader from "../../../common/PageLoader";

let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

function PersonalInfo(props) {
  const navigate = useNavigate();
  const params = useParams();

  //redux state
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  //methods
  React.useEffect(() => {}, [getEmployeeDetails]);
  return (
    <>
      {loadingGET_EMPLOYEE_Details && <PageLoader />}
      <Paper style={{ height: "60vh", overflowX: "hidden", overflowY: "auto" }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ p: 3, pb: 0 }}
        >
          <Grid item>
            <Typography variant="h2">Personal</Typography>
          </Grid>

          <Grid item>
            {loggedInUser?.type == "edge" ? (
              <Button
                size="small"
                id="edit"
                variant="outlined"
                endIcon={<EditRoundedIcon fontSize="small" />}
                sx={{
                  borderRadius: "10px",
                  fontFamily: "Cairo-Medium",
                  fontWeight: "Bold",
                }}
                onClick={() => {
                  if (getEmployeeDetails?.ok) {
                    navigate(`/main/addEmployee`, {
                      state: {
                        navigatedFor: "editEmployee",
                        paramId: params?.id,
                      },
                    });
                  }
                }}
              >
                Edit
              </Button>
            ) : null}
          </Grid>
        </Grid>

        <Grid item container direction="column" justifyContent="center" alignItems="center" rowSpacing={2} xs={12}>
          <Grid item>
            <Avatar
              style={{
                height: "140px",
                width: "130px",
                borderRadius: "15px",
              }}
              variant="rounded"
              src={`${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/logo?id=${getEmployeeDetails?.payload?.pictureVersion}`}
            />
          </Grid>
          <Grid item>
            <Typography variant="h2" color="secondary" sx={{ letterSpacing: "3px" }}>
              {getValue(getEmployeeDetails.payload?.firstName) + " " + getValue(getEmployeeDetails.payload?.lastName)}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          columnSpacing={5}
          sx={{ pt: 6, pb: 3 }}
        >
          <Grid item container direction="column" justifyContent="flex-end" alignItems="flex-end" rowSpacing={1} xs={6}>
            <Grid item>
              <Typography variant="h2">Platform Joining Date</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2">Date of Birth</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2">Gender</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2">Personal Email</Typography>
            </Grid>

            <Grid item>
              <Typography variant="h2">Residential Address</Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            rowSpacing={1}
            xs={6}
          >
            <Grid item>
              <Typography id="edge-joining-date" variant="h2">
                {convertDate(getEmployeeDetails.payload?.edgeJoiningDate)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography id="dob" variant="h2">
                {convertDate(getEmployeeDetails.payload?.dateOfBirth)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography id="gender" variant="h2">
                {getValue(getEmployeeDetails.payload?.gender)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography id="email" variant="h2">
                {getValue(getEmployeeDetails.payload?.email)}
              </Typography>
            </Grid>

            <Grid item>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "14rem",
                }}
              >
                <Typography variant="h2" id="address">
                  {getValue(
                    getEmployeeDetails.payload?.addressLine1 ? getEmployeeDetails.payload?.addressLine1 + ", \n" : "-"
                  ) +
                    (getEmployeeDetails.payload?.city ? getEmployeeDetails.payload?.city + "," : "") +
                    (getEmployeeDetails.payload?.state ? getEmployeeDetails.payload.state + ", " : "") +
                    (getEmployeeDetails.payload?.zip ? getEmployeeDetails.payload?.zip + "," : "") +
                    (getEmployeeDetails.payload?.country ? getEmployeeDetails.payload?.country : "-")}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default PersonalInfo;
