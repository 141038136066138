import { useState } from "react";

import { Box, Typography, FormControl, Chip } from "@mui/material";

import Button from "../../Button/Button";
import TextFieldV2 from "../../common/TextFieldV2/TextFieldV2";
import { baseUrl } from "../../../util/APIBaseUrl";
import postRequest from "../../../util/APIHelperPost";
import {
  PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE,
  PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE_IN_MB,
  PRODUCT_FEEDBACK_FORM_FEEDBACK_DROPDOWN_VALUES,
} from "../../../constants";

import "./Feedback.css";
import PageLoader from "../../common/PageLoader";

const ProductFeedbackForm = ({ onCancel }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [ErrorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ProductFeedbackFormData, setProductFeedbackFormData] = useState({
    type: "",
    title: "",
    details: "",
  });

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const { name, email } = loggedInUser;

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    const oversizedFiles = filesArray.filter((file) => file.size > PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE);

    if (oversizedFiles.length > 0) {
      setErrorText(`Files must be smaller than ${PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE_IN_MB} MB.`);
      return;
    }

    setErrorText("");
    setSelectedFiles(filesArray);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "details" && value.length > 3000) {
      setErrorText("Details must be a maximum of 3000 characters.");
    } else if (name === "title" && value.length > 300) {
      setErrorText("Title must be a maximum of 300 characters.");
    } else {
      setErrorText("");
      setProductFeedbackFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const isFormValid = () => {
    return (
      ProductFeedbackFormData.type && ProductFeedbackFormData.title && ProductFeedbackFormData.details && !ErrorText
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const apiUrl = `${baseUrl}/create-tickets`;
    const data = new FormData();

    data.append("name", name);
    data.append("email", email);
    data.append("type", ProductFeedbackFormData?.type);
    data.append("title", ProductFeedbackFormData?.title);
    data.append("details", ProductFeedbackFormData?.details);

    selectedFiles.forEach((file) => {
      data.append("files", file);
    });
    try {
      const response = await postRequest(apiUrl, data, true, "POST", "multipart/form-data");

      if (response.status === 200) {
        setSubmissionSuccess(true);
        setIsLoading(false);
      } else if (!response.ok) {
        setIsLoading(false);
        setErrorText("Error in submitting Feedback. Please try later!");
      }
    } catch (error) {
      console.error("Error submitting ticket:", error);
    }
  };

  const SuccessMessage = () => {
    return (
      <Box>
        <Typography variant="h5" className="successTitle">
          Thank You!
        </Typography>
        <Typography variant="body1" className="successTitle">
          Your feedback has been submitted successfully.
        </Typography>
        <Button
          type="button"
          variant="primary"
          size={"md"}
          style={{ marginRight: "auto", marginLeft: "auto" }}
          onClick={() => onCancel()}
        >
          Go back
        </Button>
      </Box>
    );
  };

  if (submissionSuccess) {
    return <SuccessMessage />;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <Box component="form" onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <TextFieldV2
            id="type"
            name="type"
            label="Type"
            required
            select={true}
            dropdownValues={PRODUCT_FEEDBACK_FORM_FEEDBACK_DROPDOWN_VALUES}
            value={ProductFeedbackFormData.type}
            onChange={handleChange}
            sx={{
              fontSize: "14px",
              borderRadius: "12px",
            }}
            InputProps={{
              notched: true,
              label: "Type",
            }}
          />
        </FormControl>
        <TextFieldV2
          margin="normal"
          required
          fullWidth
          label="Title"
          name="title"
          onChange={handleChange}
          InputProps={{
            style: {
              padding: "10px",
              fontSize: "1rem",
            },
          }}
        />
        <TextFieldV2
          margin="normal"
          required
          fullWidth
          label="Details"
          name="details"
          multiline
          rows={4}
          maxLength={20}
          onChange={handleChange}
          InputProps={{
            style: {
              padding: "10px",
              fontSize: "1rem",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              minHeight: "120px",
            },
          }}
        />
        <label htmlFor="file-upload">
          <Button
            type="button"
            variant="primary"
            size={"md"}
            fullWidth
            onClick={() => document.getElementById("file-upload").click()}
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          >
            Upload Files
          </Button>
          <input type="file" id="file-upload" multiple hidden onChange={handleFileChange} />
        </label>

        {ErrorText && <Typography color="error">{ErrorText}</Typography>}
        {selectedFiles.length > 0 && (
          <Box sx={{ display: "flex", gap: 0.5, mt: 1, flexWrap: "wrap", maxWidth: "300px !important" }}>
            {selectedFiles.map((file, index) => (
              <Chip
                key={index}
                label={file.name}
                onDelete={() => {
                  setSelectedFiles(selectedFiles.filter((f) => f !== file));
                }}
              />
            ))}
          </Box>
        )}
        <Box className="buttonsBox">
          <Button type="button" variant="secondary" size={"md"} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" size={"md"} disabled={!isFormValid()}>
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProductFeedbackForm;
