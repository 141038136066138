import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";

import EmailWithIcon from "components/Widgets/EmailWithIcon";
import DataTableBasic from "components/Widgets/DataTableBasic";
import PageLoader from "components/common/PageLoader";
import { AlertType } from "components/common/Snackbar";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import { showSnackbar } from "store/actions/Utility";
import { convertDate, currencyFormat, getCurrencySymbol, getMonthFromDate, getValue } from "components/Screens/CommonUtils";

import calendarIcon from "components/Icons/CalendarIcon.png";

const tableHeader = [
  {
    id: "month",
    label: "Month",
    align: "left",
  },
  {
    id: "payrollAmount",
    label: "Payroll Amount",
    align: "center",
  },
  {
    id: "paymentDate",
    label: "Payment Date",
    align: "center",
  },
  {
    id: "actions",
    label: "",
    align: "center",
  },
];

function SalaryDetails(props) {
  //component states
  const [tableRows, setTableRows] = React.useState([]);
  const [documentDownloadLoading, setDocumentDownloadLoading] = useState({ loader: false, documentId: null });
  //redux state

  const employeeSalaryDetails = useSelector((state) => state.EmployeeSalaryDetails.employeeSalaryDetails);
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingEmployeeSalaryDetils = useSelector((state) => state.EmployeeSalaryDetails.loadingEmployeeSalaryDetils);
  
  const dispatch = useDispatch();

  const handleDownloadSlip = async (payrollId) => {
    setDocumentDownloadLoading({ loader: true, documentId: payrollId });
    const response = await getRequest(
      `${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/payroll/${payrollId}?action=pdf`,
      true,
      "export"
    );

    if (!response?.ok) {
      const errorMessage = response?.payload?.error[0]?.description;
      if (errorMessage) {
        dispatch(
          showSnackbar({
            type: AlertType.error,
            message: response?.payload?.error[0]?.description,
            dispatch,
          })
        );
      }
      setDocumentDownloadLoading({ loader: false, documentId: null });
      return;
    }
    const url = window.URL.createObjectURL(response?.payload);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    setDocumentDownloadLoading({ loader: false, documentId: null });
    link.download = "Payroll Slip";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    // alert("your file has downloaded!");
  };

  //methods
  React.useEffect(() => {
    let salaryData = [];
    if (
      Object.keys(employeeSalaryDetails).length &&
      employeeSalaryDetails?.ok &&
      employeeSalaryDetails?.payload?.rows?.length
    ) {
      employeeSalaryDetails?.payload?.rows?.map((employeeSalary, index) => {
        salaryData.push({
          month: (
            <EmailWithIcon
              data={getMonthFromDate(employeeSalary?.paymentDate)}
              iconButton={true}
              variant="h3"
              fontsize="12px"
              icon={<img src={calendarIcon} height={20} width={"auto"} />}
            />
          ),
          payrollAmount: (
            <Typography variant="h5">
              {getCurrencySymbol("en-US", "USD", currencyFormat(getValue(employeeSalary?.payrollAmount)))}
            </Typography>
          ),
          paymentDate: <Typography variant="h3">{convertDate(employeeSalary?.paymentDate)}</Typography>,
          actions:
            documentDownloadLoading?.loader && documentDownloadLoading?.documentId === employeeSalary?.payrollId ? (
              <IconButton color="default" sx={{ p: 0, width: "100%", height: "21px" }}>
                <CircularProgress size={20} color="inherit" />
              </IconButton>
            ) : (
              <Typography
                variant="h3"
                sx={{
                  color: "#3d43bb",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => handleDownloadSlip(employeeSalary?.payrollId)}
              >
                Download
              </Typography>
            ),
        });
      });
    }

    setTableRows(salaryData);
  }, [documentDownloadLoading, employeeSalaryDetails]);

  return (
    <>
      {loadingEmployeeSalaryDetils && <PageLoader />}
      <Grid container direction="column" rowSpacing={3} id="salaryDetails">
        <Grid item xs={12}>
          <DataTableBasic rowsData={tableRows} tableHeader={tableHeader} />
        </Grid>
      </Grid>
    </>
  );
}

export default SalaryDetails;
