import { makeRequest } from "./APIHelper";
// import { useDispatch } from "react-redux";
// import { RegisterUser } from "../store/actions/RegisterUser";
// FOR POST REQUEST
const postRequest = async (
  path,
  body,
  loaderFlag,
  method,
  contentType,
  apiType
) => {
  // const dispatch = useDispatch();
  const options = {
    path: path,
    method: method ? method : "POST",
    body: body,
    contentType: contentType
      ? null
      : //   "multipart/form-data"
        //  boundary=----WebKitFormBoundaryEd0hMdVC1Io5xIPH"
        "application/json",
  };
  // Return the fetch response or throw the error.
  // dispatch(RegisterUser());
  // eslint-disable-next-line no-return-await
  return await makeRequest(
    options,
    loaderFlag != undefined ? loaderFlag : true,
    apiType
  );
};
export default postRequest;
