import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { employementInfoScreen } from ".././../EmployementInfo.pages.enums";

function UnAssignedEmployee(props) {
  const { handlePageChange } = props;

  const pageChange = () => {
    handlePageChange(employementInfoScreen.duringAssignToCustomer);
  };
  return (
    <Grid item container direction="column" justifyContent="center" alignItems="center" xs={12} rowSpacing={2}>
      <Grid item>
        <Typography variant="h2">Currently not working with anyone</Typography>
      </Grid>
      <Grid item>
        <Button
          id="assignToCustomer"
          variant="contained"
          disableElevation
          fullWidth
          sx={{
            width: "145px",
            borderRadius: "5px",
            px: 0,
            py: "8px",
          }}
          onClick={() => pageChange()}
        >
          <Typography variant="h6Sidebar" fontSize="11px" color="white">
            Assign to Customer
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default UnAssignedEmployee;
