import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  convertDate,
  getCountryFlag,
  getProfileImage,
  getSalaryWithoutBouns,
  getValue,
  isValidResponse,
} from "components/Screens/CommonUtils";

import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import YellowDotIcon from "assets/svgs/YellowDotIcon";
import avatarImage from "assets/images/fallback-image.svg";
import RedDotIcon from "assets/svgs/RedDotIcon";
import "./profile-strip.css";

function UserPersonalInfo({ userData, leaveStatus, edgePoc, image, showIcon }) {
  const [imageSrc, setImageSrc] = useState(avatarImage);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  const getPhoneNumber = (number) => number || "-";

  const hasEmployeeImage = isValidResponse(userData) && imageSrc[userData?.employeeId];
  const imageSource = hasEmployeeImage ? imageSrc[userData?.employeeId] : avatarImage;

  useEffect(() => {
    if (isValidResponse(userData)) {
      if (userData.picturePath) {
        getProfileImage(userData.employeeId, userData?.pictureVersion).then((img) => {
          setImageSrc(img);
        });
      } else {
        setImageSrc(avatarImage);
      }
    }
  }, [userData]);

  return (
    <div className="custom-grid-container">
      {image && (
        <div className="image-container">
          <img className="custom-card-media" src={imageSource} alt={userData?.firstName} />
          {showIcon && (
            <div className="icon-overlay">
              <CameraAltOutlinedIcon sx={{ color: "#3D43BB" }} />
            </div>
          )}
        </div>
      )}
      <div className="w-full">
        <div className="mt-10p">
          {edgePoc && <p className="overline-md text-headings-secondary">Edge Point of Contact</p>}
          <p className="heading-h4-semibold">{`${getValue(userData?.firstName)} ${getValue(userData?.lastName)}`}</p>
          <p className="heading-h5-medium color-text-primary">{getValue(userData?.jobTitle)}</p>
        </div>

        {userData?.leaveStatus?.status && leaveStatus && (
          <div className="custom-status-container mt-10p">
            {userData?.leaveStatus?.statusId === "Out till" || userData?.leaveStatus?.statusId === "Out today" ? (
              <RedDotIcon />
            ) : (
              userData?.leaveStatus?.statusId === "Out from" && <YellowDotIcon />
            )}

            <p className="para-body-s-regular">
              {`${userData?.leaveStatus?.statusId === "Out today" ? userData?.leaveStatus?.statusId : userData?.leaveStatus?.status}`}
            </p>
          </div>
        )}

        <div className="mt-15">
          <div className="custom-section-container">
            <div className="section-child">
              <p className="para-body-xs-regular pb-2p color-text-highlight">Contact</p>
              <p className="para-body-s-regular">{getPhoneNumber(userData?.phoneNumber)}</p>
            </div>
            <div className="section-child">
              <p className="para-body-xs-regular color-text-highlight">Country</p>
              <div className="flex">
                <div className="w-1 h-1">{getCountryFlag(userData?.country, 14, 14)}</div>
                <p className="para-body-s-regular pt-2p pl-025">{userData?.country}</p>
              </div>
            </div>
            <div className="section-child">
              <p className="para-body-xs-regular pb-2p color-text-highlight">Gender</p>
              <p className="para-body-s-regular">{getValue(userData?.gender)}</p>
            </div>
            <div className="section-child">
              <p className="para-body-xs-regular pb-2p color-text-highlight">Date of Birth</p>
              <p className="para-body-s-regular">{convertDate(getEmployeeDetails.payload?.startDate)}</p>
            </div>
            <div className="section-child">
              <p className="para-body-xs-regular pb-2p color-text-highlight">Salary</p>
              <p className="para-body-s-regular">
                $ {getSalaryWithoutBouns(userData?.costToCompany, userData?.incrementDetails?.bonus)}
              </p>
            </div>
            <div className="section-child">
              <p className="para-body-xs-regular pb-2p color-text-highlight">Department</p>
              <p className="para-body-s-regular">{getValue(userData?.department)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserPersonalInfo;
