import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { EmployeeDocumentsRequest } from "../../../../../store/actions/EmployeePortal/EmployeeDocuments";
import {
  postADocForWorkforce,
  ResetPostADocForWorkforce,
} from "../../../../../store/actions/Workforce/EmployeeDetils/Documents";
//mui
import { BsFileEarmarkCheck, BsFileEarmarkPlus } from "react-icons/bs";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { getError, isValidResponse } from "../../../CommonUtils";
import EmployeeDocuments from "../../../../common/Widgets/EmployeeDocuments";
import EditDocument from "./EditDocument";
import PageLoader from "../../../../common/PageLoader";

function EmployeeDocs(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const workerDocs = useSelector((state) => state.WorkforceDocs);
  const loadingWorkerDocs = useSelector((state) => state.WorkforceDocs.isLoading);

  const empDocs = useSelector((state) => state.EmployeeDocuments);
  const loadingEmpDocs = useSelector((state) => state.EmployeeDocuments.isLoading);

  // component state
  const [fileName, setFileName] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [shareWithEmployee, setShareWithEmployee] = React.useState(true);
  const [shareWithCustomer, setShareWithCustomer] = React.useState(true);
  const [fileSize, setFileSize] = React.useState(null);
  const [editDocument, setEditDocument] = React.useState();
  const [openEditDocument, setOpenEditDocument] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [uploadDocumentType, setUploadDocumentType] = React.useState("");

  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);

  const handleChooseFile = (e) => {
    e.preventDefault();
    let inFile = e.target.files[0];

    // setFileName(inFile.name);

    setFileSize(Math.floor(inFile.size));
    setSelectedFile(inFile);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleDocUpload = (e) => {
    let uploadDoc;
    e.preventDefault();
    if (selectedFile && selectedFile) {
      var form_data = new FormData();
      form_data.append("file", selectedFile);
      form_data.append("documentName", fileName ? fileName : selectedFile?.name ? selectedFile?.name : null);

      form_data.append("documentType", uploadDocumentType);
      form_data.append("shareWithEmployee", shareWithEmployee ? "1" : "0");
      form_data.append("shareWithCustomer", shareWithCustomer ? "1" : "0");

      uploadDoc = `employees/${params?.id}/document`;
      dispatch(
        postADocForWorkforce({
          request: uploadDoc,
          body: form_data,
        })
      );
    }
  };

  //methods
  React.useEffect(() => {
    // Only fetch if the details are not in redux store
    if (workerDocs?.documents?.status) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [workerDocs]);

  React.useEffect(() => {
    if (workerDocs?.documents?.ok) {
      setFileName("");
      setUploadDocumentType("");
      setSelectedFile(null);
      dispatch(EmployeeDocumentsRequest(`employees/${params?.id}/document`));

      dispatch(ResetPostADocForWorkforce({}));
    }
  }, [workerDocs]);

  React.useEffect(() => {
    dispatch(ResetPostADocForWorkforce({}));
    dispatch(EmployeeDocumentsRequest(`employees/${params?.id}/document`));
  }, []);

  return (
    <>
      {(loadingWorkerDocs || loadingEmpDocs || loadingplatformConfig || isLoading) && <PageLoader />}
      <Grid container direction="row" rowSpacing={3} columnSpacing={3}>
        <Grid item container direction="row" alignItems="center" columnSpacing={3}>
          <Grid item xs={4}>
            <input
              color="primary"
              accept="application/pdf"
              type="file"
              onChange={handleChooseFile}
              id="icon-button-file"
              style={{ display: "none" }}
            />

            <TextField
              value={selectedFile?.name ? selectedFile?.name : ""}
              readOnly
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  borderRadius: "6px",
                  fontFamily: "Poppins-Regular !important",
                  height: "35px",
                },
                "& .MuiInputBase-input": {
                  paddingTop: 1,
                  paddingLeft: 2,
                  paddingBottom: 1,

                  fontSize: "12px",
                },
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0px !important",
                },
              }}
              id="userName"
              variant={"outlined"}
              placeholder="Selected File Name"
              size="small"
              name="email"
              margin="normal"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Choose File">
                      <label htmlFor="icon-button-file">
                        <IconButton
                          component="span"
                          aria-label="icon-button-file"
                          // onChange={handleChooseFile}
                        >
                          <UploadFileIcon fontSize="20px" color="primary" />
                        </IconButton>
                      </label>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={8}></Grid>

          <Grid item xs={4} sx={{ marginBottom: "8px" }}>
            <TextField
              value={fileName}
              // onChange={(e) => setFileName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  borderRadius: "6px",
                  fontFamily: "Poppins-Regular !important",
                  height: "35px",
                },
                "& .MuiInputBase-input": {
                  paddingTop: 1,
                  paddingLeft: 2,
                  paddingBottom: 1,

                  fontSize: "12px",
                },
              }}
              id="userName"
              variant={"outlined"}
              onChange={(e) => setFileName(e.target.value)}
              placeholder="Enter File Name"
              size="small"
              name="email"
              fullWidth={true}
              margin="normal"
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>

          <Grid item>
            <Autocomplete
              value={uploadDocumentType}
              onChange={(event, option) => {
                setUploadDocumentType(option);
              }}
              id="controllable-states-demo"
              options={platformConfig?.payload?.DOCUMENT_TYPE?.split(",") || []}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "0px",
                      paddingLeft: "9px",
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      borderRadius: "6px",
                      fontFamily: "Poppins-Regular !important",
                      height: "35px",
                    },
                    "& .MuiInputBase-input": {
                      // paddingTop: 1,
                      // paddingLeft: 2,
                      // paddingBottom: 1,

                      fontSize: "12px",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareWithEmployee}
                  onChange={(event) => setShareWithEmployee(event.target.checked)}
                />
              }
              label={<Typography variant="h6">Share With Employee</Typography>}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareWithCustomer}
                  onChange={(event) => setShareWithCustomer(event.target.checked)}
                />
              }
              label={<Typography variant="h6">Share With Customer</Typography>}
            />
          </Grid>

          <Grid item>
            <Button
              disableElevation
              disabled={!selectedFile}
              variant="contained"
              fullWidth
              onClick={handleDocUpload}
              sx={{
                width: "140px",
                height: "35px",
                borderRadius: "5px",
                color: "white",
              }}
            >
              <Typography variant="h6" color="white">
                Upload File
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            pb: selectedFile && workerDocs.documents.ok == false && 4,
          }}
        >
          {selectedFile && (
            <Paper elevation={1}>
              <Grid
                Container
                display="flex"
                flexDirection="row"
                columnGap={1.2}
                px={5}
                pt={2.5}
                pb={2.5}
                mb={openAlert ? -3.5 : 0}
                xs={12}
              >
                <Grid item xs={4} display="flex" flexDirection="row">
                  <BsFileEarmarkCheck fontSize="28px" />
                  <Typography variant="h6" pl={2}>
                    {fileName ? fileName : selectedFile?.name ? selectedFile?.name : null}
                  </Typography>
                </Grid>

                <Grid item container display="flex" flexDirection="column" xs={7}>
                  <Grid item xs={7}>
                    {Object.keys(workerDocs).length && workerDocs?.documents?.status ? (
                      <Grid item>
                        <Collapse in={openAlert}>
                          <Alert
                            onClose={handleAlertClose}
                            variant="filled"
                            icon={false}
                            // severity="error"
                            sx={{
                              backgroundColor: workerDocs?.documents?.ok ? "primary1Color" : "warning.main",
                              // padding: "",

                              paddingTop: "0px",
                              paddingBottom: "0px",
                              fontSize: "12px",
                              textAlign: "center",

                              borderRadius: "5px !important",
                              color: "#ffffff !important",
                              "&.MuiPaper-root": {
                                color: "#ffffff !important",
                              },
                            }}
                          >
                            <Typography
                              color="white"
                              sx={{
                                fontFamily: "Poppins-Regular !important",
                                fontWeight: "400",
                                fontSize: "13px",
                                color: "#ffffff !important",
                              }}
                            >
                              {workerDocs?.documents?.ok
                                ? "File Uploaded Successfully "
                                : workerDocs?.documents?.status !== 200
                                ? getError(workerDocs?.documents?.payload?.error)
                                : "Some Thing Went Wrong"}
                            </Typography>
                          </Alert>
                        </Collapse>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="primary" variant="h6">{`${fileSize} KB`}</Typography>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>

        <Grid item xs={12}>
          {empDocs?.docs?.length ? (
            <EmployeeDocuments
              empDocs={empDocs}
              adminEmployeeId={params?.id}
              setEditDocument={setEditDocument}
              setOpenEditDocument={setOpenEditDocument}
              setIsLoading={setIsLoading}
            />
          ) : null}
        </Grid>

        {openEditDocument && (
          <EditDocument
            openEditDocument={openEditDocument}
            editDocument={editDocument}
            setOpenEditDocument={setOpenEditDocument}
          />
        )}
      </Grid>
    </>
  );
}

export default EmployeeDocs;
