import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Grid, Stack, Tab, Tabs, IconButton } from "@mui/material";
import { Helmet } from "react-helmet";

import Overview from "./Overview";
import CustomerSalaryDetails from "./SalaryDetails/SalaryDetails";
import EmployeeDocs from "./Documents";
import AmountDetails from "components/Screens/AmountDetails";
import Notes from "components/Screens/Workforce/UserDetailsV2/Notes";
import EmployeeExperienceCom from "components/Screens/Employee/EmployeeExperience";
import EmployeeAvatar from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/EmployeeAvatar";
import EmployeeTimeOffDetails from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails";
import BackIcon from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/Icons/BackIcon";
import PageLoader from "components/common/PageLoader";

import { employeeSalaryDetails } from "store/actions/Workforce/EmployeeDetils/SalaryDetails";
import { employeeSalaryInfo } from "store/actions/Workforce/EmployeeDetils/SalaryInfo";
import { GetEmployeeDetails } from "store/actions/GetEmployeeDetails";

import {
  checkUserAdditionalInfo,
  convertDate,
  currencyFormat,
  getCurrencySymbol,
  getValue,
} from "components/Screens/CommonUtils";
import { Employee_TIMEOFF_Details } from "constants";
import { baseUrl } from "util/APIBaseUrl";

function index() {
  const dispatch = useDispatch();
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);

  const tab = urlParams.get("tab");
  const navigateFrom = urlParams.get("navigateFrom");
  // const { redirectFrom } = state;

  let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  //component state
  const [currentTab, setCurrentTab] = useState("Overview");
  const [userData, setUserData] = useState({});
  const [tabs, setTabs] = useState(Employee_TIMEOFF_Details);

  //redux state
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const salaryInfo = useSelector((state) => state.SalaryInfo.employeeSalaryInfo);
  const loadingEmployeeSalaryInfo = useSelector((state) => state.SalaryInfo.loadingEmployeeSalaryInfo);

  //function
  const selectPageHeading = (opt) => {
    setCurrentTab(opt);
  };

  const handleChange = (event, newValue) => {
    window.location.hash = newValue;
    setCurrentTab(newValue);
  };

  const getGrossSalary = (value) => {
    if (value != undefined && value != null) {
      return value * 12;
    } else {
      return "-";
    }
  };

  const getPercentageorAmount = (percentageDiscount, flatDiscount) => {
    if (percentageDiscount) {
      return `${getValue(percentageDiscount)} %`;
    } else if (flatDiscount) {
      return getCurrencySymbol("en-US", "USD", currencyFormat(getValue(flatDiscount)));
    }
  };

  const handleBackButton = () => {
    if (navigateFrom) {
      return navigate(`/main/${navigateFrom}`);
    }
    return navigate("/main/workforce");
  };

  //methods
  useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let employeeEducationDetailsRequest = "";
    let employeeExperienceDetailsRequest = "";
    let employeeSalaryDetailsRequest = "";
    let employeeSalaryInfoRequest = "";

    let customerId = userAdditionalInfo?.customerId;
    let employeeId = getEmployeeDetails.payload?.employeeId;

    if (Object.keys(loggedInUser).length && loggedInUser.userId != undefined && getEmployeeDetails?.ok) {
      if (loggedInUser?.type == "customer") {
        employeeSalaryInfoRequest = `customer/${customerId}/employee/${employeeId}/salaryInfo`;
        employeeSalaryDetailsRequest = `customer/${customerId}/employee/${employeeId}/payroll`;
        // employeeExperienceDetailsRequest = `customer/${customerId}/employee/${getEmployeeDetails.payload?.employeeId}/experience`;
        // employeeEducationDetailsRequest = `customer/${customerId}/employee/${getEmployeeDetails.payload?.employeeId}/education`;
      } else if (loggedInUser?.type == "edge") {
        employeeSalaryInfoRequest = `employee/${employeeId}/salary`;
        employeeSalaryDetailsRequest = `employee/${employeeId}/payroll`;
        // employeeExperienceDetailsRequest = `employee/${getEmployeeDetails.payload?.employeeId}/experience`;
        // employeeEducationDetailsRequest = `employee/${getEmployeeDetails.payload?.employeeId}/education`;
      }
      dispatch(
        employeeSalaryDetails({
          request: employeeSalaryDetailsRequest,
        })
      );

      dispatch(
        employeeSalaryInfo({
          request: employeeSalaryInfoRequest,
        })
      );

      if (
        getEmployeeDetails?.payload?.timeoffFlag &&
        getEmployeeDetails?.payload?.customerId &&
        !tabs?.find((tab) => tab?.title == "Time-off")
      ) {
        setTabs([...tabs, { title: "Time-off", link: "" }]);
      }

      // dispatch(
      //     experienceInfo({
      //         request: employeeExperienceDetailsRequest,
      //     })
      // );

      // dispatch(
      //     educationInfo({
      //         request: employeeEducationDetailsRequest,
      //     })
      // );
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    let loggedInUser = {};

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    setUserData(loggedInUser);

    if (
      (Object.keys(loggedInUser).length &&
        loggedInUser.userId != undefined &&
        !Object.keys(getEmployeeDetails).length &&
        params.id != undefined &&
        params.id != null &&
        checkUserAdditionalInfo(userAdditionalInfo)) ||
      state?.redirectFrom == "editEmployee"
    ) {
      if (loggedInUser?.type == "customer") {
        dispatch(
          GetEmployeeDetails({
            customerID: loggedInUser?.userId,
            employeeID: params.id,
            request: `customer/${userAdditionalInfo?.customerId}/employee/${params.id}`,
          })
        );
      } else if (loggedInUser?.type == "edge") {
        dispatch(
          GetEmployeeDetails({
            customerID: loggedInUser?.userId,
            employeeID: params.id,
            request: `employee/${params.id}`,
          })
        );
      }
    }
  }, [userAdditionalInfo]);

  useEffect(() => {
    if (tab) setCurrentTab(tab);
  }, [tab]);

  return (
    <>
      <Helmet>
        <title>{`Edge | Employees - ${
          getValue(getEmployeeDetails.payload?.firstName) + " " + getValue(getEmployeeDetails.payload?.lastName)
        }`}</title>
      </Helmet>
      {loadingGET_EMPLOYEE_Details == true || loadingAdditionalInfo == true || loadingEmployeeSalaryInfo == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <div className="manage-timeoff-wrapper-admin">
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: "20px", padding: "16px 60px" }}>
          <IconButton onClick={() => handleBackButton()}>
            <BackIcon />
          </IconButton>

          <EmployeeAvatar
            img={`${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/logo?id=${getEmployeeDetails?.payload?.pictureVersion}&variant=thumbnail`}
            name={
              getValue(getEmployeeDetails.payload?.firstName) + " " + getValue(getEmployeeDetails.payload?.lastName)
            }
            jobTitle={getValue(getEmployeeDetails.payload?.jobTitle)}
          />
        </Stack>

        <div className="tabs-container">
          <Tabs
            value={currentTab}
            onChange={handleChange}
            sx={{
              ".MuiTabs-indicator": {
                height: "4px !important",
                zIndex: 2,
              },
              ".MuiTabs-flexContainer": {
                gap: "16px",
              },
              ".Mui-selected": {
                color: "#212231",
              },

              "& .MuiTabs-scroller": {
                paddingLeft: "50px",
              },
            }}
          >
            {tabs?.map((tab, index) => (
              <Tab label={tab?.title} value={tab?.title} key={index} disableRipple={true} className="holidays-tab" />
            ))}
          </Tabs>
          <span
            style={{
              display: "block",
              width: "100%",
              height: "1px",
              backgroundColor: "#B2B4E2",
              position: "absolute",
              bottom: "2px",
            }}
          ></span>
        </div>
      </div>

      <Grid container direction="column">
        {/* <Grid item container direction="row" xs={12} columnSpacing={5} pr={3.5}>
          <Grid item container xs={11} direction="column">
            <Grid item>
              <Header
                data={{
                  heading:
                    getValue(getEmployeeDetails.payload?.firstName) +
                    " " +
                    getValue(getEmployeeDetails.payload?.lastName),

                  subHeader: null,
                  profileWithIcon: null,
                }}
              />
            </Grid>
            <Grid Item>
              <Typography variant="h3Lite" fontSize="16px">
                {getValue(getEmployeeDetails.payload?.jobTitle)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <PageHeader
          pageHeadings={["Overview", "Salary Details", "Documents", "Experience", "Notes"]}
          selectPageHeading={selectPageHeading}
          setCurrentTab={currentTab}
        /> */}

        {currentTab === "Salary Details" && (
          <Grid item xs={12}>
            <AmountDetails
              payrollWidget={true}
              itemColumns={2}
              details={[
                loggedInUser?.type == "edge"
                  ? {
                      title: "Cost to Customer",
                      amount: getCurrencySymbol(
                        "en-US",
                        "USD",
                        currencyFormat(getValue(salaryInfo?.payload?.costToCustomer))
                      ),
                    }
                  : null,
                {
                  title: "Gross Monthly",
                  amount: getCurrencySymbol("en-US", "USD", currencyFormat(getValue(salaryInfo?.payload?.grossSalary))),
                },
                {
                  title: "Yearly",
                  amount: getCurrencySymbol(
                    "en-US",
                    "USD",
                    currencyFormat(getGrossSalary(salaryInfo?.payload?.grossSalary))
                  ),
                },
                {
                  title: "Bonus",
                  amount: getCurrencySymbol(
                    "en-US",
                    "USD",
                    currencyFormat(getValue(getEmployeeDetails.payload?.bonus))
                  ),
                },
              ]}
            />
          </Grid>
        )}
        {salaryInfo?.payload?.discounted && currentTab === "Salary Details" ? (
          <Grid item xs={12} sx={{ maxHeight: "115px" }}>
            <AmountDetails
              payrollWidget={true}
              itemColumns={2}
              details={[
                // loggedInUser?.type == "edge"
                // {
                //     title: "Discounted",
                //     amount: salaryInfo?.payload?.discounted,
                // },
                {
                  title: "Discounted Cost",
                  amount: getCurrencySymbol(
                    "en-US",
                    "USD",
                    currencyFormat(getValue(salaryInfo?.payload?.discountedCost))
                  ),
                },

                {
                  title: salaryInfo?.payload?.percentage ? "Discount Percentage" : "Flat Discount",
                  amount: getPercentageorAmount(salaryInfo?.payload?.percentage, salaryInfo?.payload?.flatAmount),
                },

                salaryInfo.payload?.startDate
                  ? {
                      title: "Starting Date",
                      amount: convertDate(salaryInfo.payload?.startDate),
                    }
                  : null,

                salaryInfo.payload?.duration
                  ? {
                      title: "Discount Duration",
                      amount: `${getValue(salaryInfo.payload?.duration)} Months`,
                    }
                  : null,
              ]}
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid
        container
        direction="row"
        id="employeeInformation"
        className="custom-scroll"
        sx={{
          maxHeight: "calc(100vh - 260px)",
          overflowY: currentTab == "Time-off" ? "clip" : "auto",
          paddingRight: "0px !important",
        }}
      >
        <Grid item xs={12} id="employeeInfo">
          {currentTab == "Overview" ? (
            <Overview />
          ) : currentTab == "Salary Details" ? (
            <CustomerSalaryDetails />
          ) : currentTab == "Documents" ? (
            <EmployeeDocs />
          ) : currentTab == "Experience" ? (
            <EmployeeExperienceCom employeeId={getEmployeeDetails?.payload?.employeeId} />
          ) : currentTab == "Notes" ? (
            <Notes />
          ) : currentTab == "Time-off" ? (
            <EmployeeTimeOffDetails />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default index;
