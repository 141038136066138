import { baseUrl } from "util/APIBaseUrl";
import getRequest from "util/APIHelperGet";

// eslint-disable-next-line import/prefer-default-export
export const getMissingPaymentMethodCustomersCount = async () => {
  const { payload } = await getRequest(
    `${baseUrl}/customers?${new URLSearchParams({
      limit: 10,
      page: 0,
      orderBy: JSON.stringify({}),
      filter: JSON.stringify({
        paymentMethodType: ["MISSING"],
      }),
    })}`
  );

  return !!payload?.count;
};
