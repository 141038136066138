import React from "react";
//material ui
import { Box, Grid, Paper, Toolbar, Typography } from "@mui/material";
//component
import PersonalInfo from "../PersonalInfo";
// import EmploymentInformation from "../EmploymentInfo";
// import NewPersonalInfo from "./NewPersonalInfo";
import NewPersonalInfo from "../NewPersonalInfo";

function index(props) {
  return (
    <Grid
      id="overview"
      item
      container
      direction="row"
      justifyContent="space-between"
      xs={12}
      columnSpacing={3}
      rowSpacing={3}
    >
      <Grid item xs={12} style={{ height: "100%" }}>
        <Paper
          style={{
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Grid item container direction="row" columnSpacing={5}>
            <Grid item xs={12}>
              <NewPersonalInfo />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default index;
