import React from "react";
//material ui
import { Grid, Typography, Button, Box } from "@mui/material";

//enum
import { Permission } from "../../../../../../permissions.enum";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { ResetAssignEmployee } from "../../../../../../store/actions/Workforce/AssignEmployee";
import { employementInfoScreen } from "../../EmployementInfo.pages.enums";
import { UnAssignEmployee, ResetUnAssignEmployee } from "../../../../../../store/actions/Workforce/UnAssignEmployee";
import { GetEmployeeDetails } from "../../../../../../store/actions/GetEmployeeDetails";
//Icons
import UnAssignedEmployee from "./UnAssignedEmployee";
import AssignToCustomer from "./AssingToCustomer";
import EmploymentInformation from "./EmploymentInformation";
import { AiOutlineEdit } from "react-icons/ai";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PageLoader from "../../../../../common/PageLoader";
import { usePermissions } from "components/Hook";

//cosntants
const assignToCustomerScreen = 1;

function index(props) {
  const dispatch = useDispatch();

  const { loggedInUserType } = usePermissions();
  // component state
  const [page, setPage] = React.useState(0);
  const [editAssignEmployee, setEditAssignEmployee] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [DropdownData_, setDropdownData_] = React.useState({});

  //redux state
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const assignEmployee = useSelector((state) => state.AssignEmployee.assignEmployee);
  const loadingAssignInfo = useSelector((state) => state.AssignEmployee.loadingAssignInfo);

  const unAssignEmployee = useSelector((state) => state.UnAssignEmployee.unAssignEmployee);
  const loadingUnAssignInfo = useSelector((state) => state.UnAssignEmployee.loadingUnAssignInfo);

  //functions

  const handleDisableCompanyName = (disable) => {
    setEditAssignEmployee(disable);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  //methods

  React.useEffect(() => {
    if (Object.keys(unAssignEmployee).length && unAssignEmployee.ok) {
      setPage(employementInfoScreen.beforeAssignToCustomer);
      dispatch(
        GetEmployeeDetails({
          request: `employee/${getEmployeeDetails?.payload?.employeeId}`,
          employeeID: getEmployeeDetails?.payload?.employeeId,
        })
      );
      dispatch(ResetUnAssignEmployee({}));
    }
  }, [unAssignEmployee]);

  React.useEffect(() => {
    if (Object.keys(assignEmployee).length && assignEmployee.ok) {
      setPage(employementInfoScreen.afterAssignToCustomer);
      dispatch(
        GetEmployeeDetails({
          request: `employee/${getEmployeeDetails?.payload?.employeeId}`,
          employeeID: getEmployeeDetails?.payload?.employeeId,
        })
      );
    }
  }, [assignEmployee]);

  React.useEffect(() => {
    if (page == employementInfoScreen.beforeAssignToCustomer) {
      dispatch(ResetAssignEmployee({}));
      dispatch(ResetUnAssignEmployee({}));
    }
  }, [page]);

  React.useEffect(() => {
    let countries = [];
    let customers = [];
    let dropdownData = {};
    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    let status = [
      { key: "unassigned", value: "UnAssigned" },
      { key: "assigned", value: "Assigned" },
      { key: "pending", value: "Pending" },
    ];

    setUserData(JSON.parse(localStorage.getItem("loggedInUser")));
    dispatch(ResetAssignEmployee({}));
    dispatch(ResetUnAssignEmployee({}));
    if (getEmployeeDetails?.payload?.status != "assigned") {
      setPage(employementInfoScreen.beforeAssignToCustomer);
    } else {
      setPage(employementInfoScreen.afterAssignToCustomer);
    }

    if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
      dropdownData?.payload?.country?.map((data, index) => {
        countries.push({
          key: data.countryCode2,
          value: data.countryCode2,
        });
      });

      dropdownData?.payload?.customer?.map((data, index) => {
        customers.push({
          key: data.customerId,
          value: data.companyName,
        });
      });
    }
    setDropdownData_({
      ...DropdownData_,
      countries,
      customers,
      status,
    });
  }, []);
  return (
    <>
      {loadingAssignInfo == true || loadingGET_EMPLOYEE_Details == true || loadingUnAssignInfo == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid item container direction="row" justifyContent="space-between" alignItems="center" xs={12} sx={{ pb: 3 }}>
        <Grid item xs={7}>
          <Box display="flex" flexDirection="column" rowGap={1.25}>
            <WorkOutlineOutlinedIcon sx={{ fontSize: "1.9rem !important" }} />
            <Typography variant="h3" fontSize="15px">
              Employment Information
            </Typography>
          </Box>
        </Grid>

        {Object.keys(userData).length &&
        userData?.type == "edge" &&
        (getEmployeeDetails.payload?.status == "assigned" || getEmployeeDetails.payload?.status == "pending") ? (
          <Grid item container xs={5} direction="row" columnSpacing={1} alignItems="flex-end" justifyContent="flex-end">
            <Grid item>
              <Button
                size="small"
                id="edit"
                variant="contained"
                disableElevation
                color="primary1Color"
                endIcon={<AiOutlineEdit />}
                sx={{
                  borderRadius: "5px",
                  // fontFamily: "Cairo-Medium",
                  // fontWeight: "Bold",
                }}
                onClick={() => {
                  setEditAssignEmployee(true);

                  if (getEmployeeDetails?.ok) {
                    setPage(employementInfoScreen.duringAssignToCustomer);
                  }
                }}
              >
                <Typography variant="h5">Edit</Typography>
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      {loggedInUserType == "edge" &&
      getEmployeeDetails.payload?.status == "unassigned" &&
      page == employementInfoScreen.beforeAssignToCustomer ? (
        <UnAssignedEmployee handlePageChange={handlePageChange} />
      ) : loggedInUserType == "edge" &&
        // getEmployeeDetails.payload?.status == "unassigned" &&
        page == employementInfoScreen.duringAssignToCustomer ? (
        <AssignToCustomer handlePageChange={handlePageChange} editAssignEmployee={editAssignEmployee} />
      ) : (
        <EmploymentInformation handleDisableCompanyName={handleDisableCompanyName} />
      )}

      {/* */}
    </>
  );
}

export default index;
