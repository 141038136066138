import React from "react";
import { useSelector } from "react-redux";

import { Avatar, Divider, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { getValue } from "components/Screens/CommonUtils";
import PageLoader from "components/common/PageLoader";
import PersonalInfo from "components/Screens/Workforce/UserDetails/NewPersonalInfo/PersonalInfo.js";
import LastActive from "components/common/LastActive";
import EmploymentInformation from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo";

import { baseUrl } from "util/APIBaseUrl";

function index(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const { lastActive, lastActiveEmail } = getEmployeeDetails?.payload || {};

  React.useEffect(() => {}, [getEmployeeDetails]);
  return (
    <>
      {loadingGET_EMPLOYEE_Details && <PageLoader />}
      <Grid
        item
        container
        direction="row"
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ px: 10, py: 10 }}
        columnSpacing={7}
        // rowSpacing={12}
      >
        <Grid item container direction="column" xl={5} lg={5} md={6} sm={12} xs={12} rowSpacing={10}>
          <Grid item container direction="row">
            <PersonalInfo />
          </Grid>
          <Grid item container direction="row">
            <EmploymentInformation />
          </Grid>
        </Grid>

        {!isMobile ? (
          <Grid item xl={2} lg={2} alignItems="center">
            <Divider orientation="vertical" variant="middle" flexItem sx={{ color: "#9C9EB5", height: "270px" }} />
          </Grid>
        ) : null}

        <Grid item container direction="column" alignItems="center" rowSpacing={2} xl={5} lg={5} md={4} sm={12} xs={12}>
          <Grid item>
            <Avatar
              style={{
                width: "27vh",
                height: "27vh",
                borderRadius: "50%",
              }}
              variant="rounded"
              src={`${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/logo?id=${getEmployeeDetails?.payload?.pictureVersion}`}
            />
          </Grid>
          <Grid item textAlign="center">
            <Typography variant="h2" color="secondary" sx={{ letterSpacing: "3px", color: "#3D43BB" }}>
              {getValue(getEmployeeDetails.payload?.firstName) + " " + getValue(getEmployeeDetails.payload?.lastName)}
            </Typography>
          </Grid>
          <LastActive {...{ lastActive, lastActiveEmail }} />
        </Grid>
      </Grid>
    </>
  );
}

export default index;
