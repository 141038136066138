import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Grid } from "@mui/material";

import { employeeSalaryDetails } from "../../../../store/actions/Workforce/EmployeeDetils/SalaryDetails";
import { employeeSalaryInfo } from "../../../../store/actions/Workforce/EmployeeDetils/SalaryInfo";

import Announcements from "./Announcements/Announcements";
import GreetingHeader from "../../../GreetingHeader/GreetingHeader";
import EarningsBanner from "./EarningsBanner";
import PayrollBar from "./PayrollHistory/PayrollBar";
import PayrollStubs from "./PayrollHistory/PayrollStubs";
import EmployeePeersCom from "./EmployeePeers/EmployeePeers";
import HrPoc from "./HrPoc/HrPoc";

import "./index.css";
import Notifications from "../CustomerDashBoardV2/Notifications";
import { GetEmployeeById } from "../../../../store/actions/EmployeePortal/GetEmployeeDetials";
import { usePermissions } from "../../../Hook";
import PageLoader from "../../../common/PageLoader";

function EmployeeDashboard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loggedInUser } = usePermissions();

  const userDetails = JSON.parse(localStorage.getItem("loggedInUser"));

  const payrollDetails = useSelector((state) => state.EmployeeSalaryDetails);
  const employeeDetails = useSelector((state) => state.GetEmployeeDetials.employeeDetails);
  const salaryInfo = useSelector((state) => state.SalaryInfo);

  const loadingEmployeeSalaryDetils = useSelector((state) => state.EmployeeSalaryDetails.loadingEmployeeSalaryDetils);
  const isLoadingEmployeeDetails = useSelector((state) => state.GetEmployeeDetials.isLoadingEmployeeDetails);
  const loadingEmployeeSalaryInfo = useSelector((state) => state.SalaryInfo.loadingEmployeeSalaryInfo);

  // Only fetch data if there is no data in the redux store
  React.useEffect(() => {
    // Only fetch if the details are not in redux store
    dispatch(
      employeeSalaryDetails({
        request:
          `employee/${loggedInUser.userId}/payroll?` +
          new URLSearchParams({
            orderBy: JSON.stringify({
              field: "paymentDate",
              direction: "asc",
            }),
          }),
      })
    );

    dispatch(
      GetEmployeeById({
        employeeId: loggedInUser?.userId,
      })
    );

    // Only fetch if the details are not in redux store
    if (!Object.keys(salaryInfo.employeeSalaryInfo).length) {
      let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      dispatch(
        employeeSalaryInfo({
          request: `employee/${loggedInUser.userId}/salary`,
        })
      );
    }
    if (localStorage.payoneerCode) {
      navigate("/main/edge-wallet");
    }
  }, []);
  return (
    <>
      {isLoadingEmployeeDetails == true || loadingEmployeeSalaryDetils == true || loadingEmployeeSalaryInfo == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <div className="ep-dashboard">
        <Grid
          container
          rowSpacing={3}
          mt={0}
          sx={{
            background: "#FBFAFF",
            width: "calc(100% - 328px)",
            display: "flex",
            flexDirection: "column",
            gap: "0px",
          }}
        >
          <GreetingHeader isEmployee />
          <div>
            <EarningsBanner userDetails={loggedInUser} salaryInfo={salaryInfo.employeeSalaryInfo} />
          </div>
          <div className="ep-payroll-widget">
            <div className="ep-payroll-h1">
              <div className="ep-payroll-h1a">Payroll History</div>
              <div
                className="ep-payroll-h1b"
                id="view-details-payroll-history"
                onClick={() => navigate(`/main/paystubs`)}
              >
                View Details
              </div>
            </div>
            <div className="ep-payroll-data-div">
              <PayrollBar
                payrollData={payrollDetails.employeeSalaryDetails}
                salaryInfo={salaryInfo.employeeSalaryInfo}
              />
              <PayrollStubs />
            </div>
          </div>
          <>
            <div className="ep-payroll-h1">
              <div className="ep-payroll-h1a">Peers</div>
              <div className="ep-payroll-h1b" id="view-all-peers" onClick={() => navigate(`/main/peers`)}>
                View All
              </div>
            </div>
            <>
              <EmployeePeersCom />
            </>
          </>
          {employeeDetails?.hrpoc && Object.keys(employeeDetails?.hrpoc).length && (
            <div>
              <div className="ep-payroll-h1">
                <div className="ep-payroll-h1a">Point of Contact1</div>
              </div>
              <div className="hr-poc-wrapper" id="hr-poc">
                <HrPoc employeeDetails={employeeDetails} page="dashboard" />
                <br />
              </div>
            </div>
          )}
        </Grid>
        <Grid
          sx={{
            width: "328px",
            height: "calc(100vh - 46px)",
            position: "fixed",
            background: "#F5F5FF",
            right: 0,
          }}
        >
          <Notifications isEmployee />
        </Grid>
      </div>
    </>
  );
}

export default EmployeeDashboard;
