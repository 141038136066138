import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";

import Header from "components/Header/Header";
import PaymentHistory from "./PaymentHistory";
import Users from "./Users";
import Overview from "./Overview";
import CustomerProfile from "./CustomerProfile";
import PaymentDetails from "./PaymentDetails";
import { getValue } from "components/Screens/CommonUtils";

import PageLoader from "components/common/PageLoader";

import { GetCustomerDetails } from "store/actions/Customers/GetCustomerDetails";
import { ResetGetCustomerPaymentSummary } from "store/actions/Customers/GetCustomerPaymentSummary";
import { ResetGetCustomerPaymentById } from "store/actions/Customers/GetCustomerPaymentById";
import PageHeader from "components/Screens/PageHeader";

const headersWithoutCustomerPaymentDetails = ["Profile", "Overview", "Payment History", "Users"];
const headersWithCustomerPaymentDetails = ["Profile", "Overview", "Payment History", "Users", "Payment Details"];

function ComponentMapper({ option, selectPageHeading, getSelectedPaymentId, customerId, selectedPaymentId }) {
  const components = {
    Profile: <CustomerProfile />,
    Overview: <Overview />,
    "Payment History": (
      <PaymentHistory selectPageHeading={selectPageHeading} getSelectedPaymentId={getSelectedPaymentId} />
    ),
    Users: <Users customerId={customerId} />,
    "Payment Details": <PaymentDetails selectedPaymentId={selectedPaymentId} />,
  };

  return components[option] || null;
}

function CustomerDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [option, selectedOption] = useState("Profile");
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);

  const customerDetails = useSelector((globalState) => globalState.GetCustomerDetails.customerDetails);
  const loadingCustomerDetails = useSelector((globalState) => globalState.GetCustomerDetails.loadingCustomerDetails);
  const customerPaymentById = useSelector((globalState) => globalState.GetCustomerPaymentById.customerPaymentById);
  const loadingCustomerPaymentById = useSelector(
    (globalState) => globalState.GetCustomerPaymentById.loadingCustomerPaymentById
  );

  const customerId = customerDetails?.payload?.customerId;

  const selectPageHeading = (opt) => {
    if (opt != "Payment Details") {
      dispatch(ResetGetCustomerPaymentById({}));
    }
    selectedOption(opt);
  };

  const getSelectedPaymentId = (paymentId) => {
    if (paymentId != undefined || paymentId != null) {
      setSelectedPaymentId(paymentId);
    }
  };

  useEffect(() => {
    if (params.id) {
      dispatch(ResetGetCustomerPaymentSummary({}));
      if (!Object.keys(customerDetails).length || state?.redirectFrom == "editCustomer") {
        dispatch(
          GetCustomerDetails({
            customerId: params.id,
          })
        );
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Edge | Customers - ${getValue(customerDetails?.payload?.companyName)}`}</title>
      </Helmet>
      {(loadingCustomerDetails || loadingCustomerPaymentById) && <PageLoader />}
      <Grid container direction="column" rowSpacing={3}>
        <Header
          data={{
            heading: getValue(customerDetails?.payload?.companyName),
            subHeader: null,
            letterSpacing: "15px",
            profileWithIcon: null,
          }}
        />
        <PageHeader
          pageHeadings={
            customerPaymentById?.ok ? headersWithCustomerPaymentDetails : headersWithoutCustomerPaymentDetails
          }
          selectPageHeading={selectPageHeading}
          selectedOption={option}
        />

        <Grid item container direction="row">
          <Grid item xs={12}>
            <ComponentMapper
              option={option}
              selectPageHeading={selectPageHeading}
              getSelectedPaymentId={getSelectedPaymentId}
              customerId={customerId}
              selectedPaymentId={selectedPaymentId}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerDetails;
