import React from "react";
import { Divider } from "@mui/material";

import "./Announcements.css";

function Announcements(props) {
  return (
    <div className="ep-dash-announce-main">
      <div className="ep-dash-announce-top">
        <div className="ep-dash-announce-h1">Announcements</div>
        <div className="ep-dash-announce-filter">
          <div className="ep-dash-announce-filter-item">Today’s</div>
          <div className="ep-dash-announce-filter-item">Community</div>
          <div className="ep-dash-announce-filter-item">Birthdays</div>
        </div>
      </div>
      <div className="ep-dash-announce-bottom">
        <div>
          {[1, 2, 3, 4, 5].map((item) => {
            return (
              <div className="ep-dash-announce-item">
                <img src={"../assets/Images/announce-icon.png"} />
                <div>
                  <div className="ep-dash-announce-title">{`${
                    item % 2 === 0
                      ? "Edge is introducing the new marketplace on 20th Oct’ 2022"
                      : "Ali and 2 more are joining today!"
                  }`}</div>
                  <div className="ep-dash-announce-hours">{`${item} hours ago`}</div>
                </div>
              </div>
            );
          })}
        </div>
        <Divider />
        <div className="ep-dash-explore">Explore in Community</div>
      </div>
    </div>
  );
}

export default Announcements;
