import React, { useEffect, useState } from "react";
import TimeLine from "components/common/Timeline";
import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";
import { convertDateToMonthAndYearShort as convertDate, getDurationFromTwoDates } from "components/Screens/CommonUtils";

function ExperienceTab({ employeeId }) {
  const [experience, setExperience] = useState(null);

  const getEmployeeExperience = async () => {
    const educationResp = await getRequest(`${baseUrl}/employee/${employeeId}/experience`);
    const { ok, payload } = educationResp;
    if (ok) {
      setExperience(payload);
    }
  };

  const getDuration = (startDate, endDate) => getDurationFromTwoDates(startDate, endDate);
  const getDate = (startDate, endDate) => convertDate(startDate, endDate);

  useEffect(() => {
    getEmployeeExperience();
  }, []);

  return (
    <div className="pt-15">
      <p className="heading-h4-semibold color-text-heading-primary">Experience at Edge</p>
      {experience?.edge?.map((item) => {
        return (
          <TimeLine
            title={item?.designation || "N/A"}
            company={item?.customer?.companyName || "N/A"}
            date={getDate(item?.joiningDate, item?.endDate)}
            duration={getDuration(item?.joiningDate, item?.endDate)}
          />
        );
      })}
      <p className="heading-h4-semibold color-text-heading-primary pt-15">Other Experience</p>
    </div>
  );
}

export default ExperienceTab;
