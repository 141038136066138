// react
import React from "react";

// mui
import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// components
import DocumentCard from "./DocumentCard";

// images and icons
import ContractIcon from "../../../../assets/svgs/ContractIcon";
import BackgroundDocumentsIcon from "../../../../assets/svgs/BackgroundDocumentsIcon";
import NdaIcon from "../../../../assets/svgs/NdaIcon";
import PersonalIdIcon from "../../../../assets/svgs/PersonalIdIcon";
import { BsFileEarmarkCheck, BsFileEarmarkPlus } from "react-icons/bs";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  ResetPostADocForWorkforce,
  postADocForWorkforce,
} from "../../../../store/actions/Workforce/EmployeeDetils/Documents";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDocumentSorted, getError } from "../../CommonUtils";
import { EmployeeDocumentsRequest } from "../../../../store/actions/EmployeePortal/EmployeeDocuments";
import { usePermissions } from "../../../Hook";
import getRequest from "../../../../util/APIHelperGet";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PageLoader from "components/common/PageLoader";

const DocumentsTab = () => {
  //constants
  const dispatch = useDispatch();
  const params = useParams();
  const documents = [
    {
      id: 1,
      image: <ContractIcon />,
      name: "Contract",
      name: "Contract",
      documentAdded: true,
    },
    {
      id: 2,
      image: <BackgroundDocumentsIcon />,
      name: "Background Checks",
      name: "Background Checks",
      documentAdded: true,
    },
    {
      id: 3,
      image: <NdaIcon />,
      name: "NDA",
      name: "NDA",
      documentAdded: true,
    },
    {
      id: 4,
      image: <PersonalIdIcon />,
      name: "Personal ID",
      documentAdded: false,
    },

    {
      id: 4,
      image: <PersonalIdIcon />,
      name: "Others",
      name: "Personal ID",
      documentAdded: false,
    },

    {
      id: 4,
      image: <PersonalIdIcon />,
      name: "Others",
      documentAdded: false,
    },
  ];

  //component state
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [fileSize, setFileSize] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [employeeDocuments, setEmployeeDocuments] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  //custom hook
  const { loggedInUserType, loggedInUser } = usePermissions();

  //redux state
  const workerDocs = useSelector((state) => state.WorkforceDocs);
  const loadingWorkerDocs = useSelector((state) => state.WorkforceDocs.isLoading);

  const empDocs = useSelector((state) => state.EmployeeDocuments);
  const loadingempDocs = useSelector((state) => state.EmployeeDocuments.isLoading);

  //functions
  const handleChooseFile = (e) => {
    e.preventDefault();
    dispatch(ResetPostADocForWorkforce({}));
    let inFile = e.target.files[0];

    // setFileName(inFile.name);

    setFileSize(Math.floor(inFile.size));
    setSelectedFile(inFile);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleDocUpload = (e) => {
    e.preventDefault();
    if (selectedFile && selectedFile) {
      var formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("documentName", fileName ? fileName : selectedFile?.name ? selectedFile?.name : null);

      PostDocumnet(formData);
    }
  };

  const PostDocumnet = (formData) => {
    let PostDocumnet = "";
    if (loggedInUserType == "edge") {
      PostDocumnet = `employees/${params?.id}/document`;
    }
    dispatch(
      postADocForWorkforce({
        request: PostDocumnet,
        body: formData,
      })
    );
  };

  const getDocumentList = () => {
    let getDocsList = "";
    if (loggedInUserType == "edge" || loggedInUserType == "customer") {
      getDocsList = `employees/${params?.id}/document`;
    }
    dispatch(EmployeeDocumentsRequest(getDocsList));
  };

  //methods
  React.useEffect(() => {
    if (empDocs?.docs) {
      const sortedGroupedDocuments = getDocumentSorted(empDocs);
      setEmployeeDocuments(sortedGroupedDocuments);
    }
  }, [empDocs?.docs]);

  React.useEffect(() => {
    if (empDocs?.docs) {
      const sortedGroupedDocuments = getDocumentSorted(empDocs);
      setEmployeeDocuments(sortedGroupedDocuments);
    }
  }, [empDocs?.docs]);

  React.useEffect(() => {
    // Only fetch if the details are not in redux store
    if (workerDocs?.documents?.status) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [workerDocs]);

  React.useEffect(() => {
    if (workerDocs?.documents?.ok) {
      setFileName("");
      setFileName("");
      setSelectedFile(null);
      getDocumentList();
      dispatch(ResetPostADocForWorkforce({}));
    }
  }, [workerDocs]);

  React.useEffect(() => {
    dispatch(ResetPostADocForWorkforce({}));
    getDocumentList();
  }, []);

  return (
    <>
      {(loadingWorkerDocs || loadingempDocs || isLoading) && <PageLoader />}
      <Grid container sx={{ gap: "12px", marginTop: "24px" }}>
        {Object.keys(employeeDocuments)?.length ? (
          Object.keys(employeeDocuments)?.map((documentType) => {
            return (
              <Accordion sx={{ width: "100%", background: "#E0E0FF" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>{documentType}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ background: "#E0E0FF" }}>
                  <Stack direction="row" sx={{ flexFlow: "wrap", gap: "15px" }}>
                    {empDocs?.docs && empDocs?.docs.length ? (
                      employeeDocuments[documentType]?.map((item) => (
                        <Grid sx={{ width: "150px" }}>
                          <DocumentCard data={item} setIsLoading={setIsLoading} />
                          {/* <EmployeeDocuments empDocs={empDocs} /> */}
                        </Grid>
                      ))
                    ) : (
                      <Grid sx={{ width: "150px" }}>
                        <DocumentCard data={documents[documents.length - 1]} />
                      </Grid>
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Grid sx={{ width: "150px" }}>
            <DocumentCard data={documents[documents.length - 1]} />
          </Grid>
        )}

        {loggedInUserType == "edge" ? (
          <>
            <Grid item container direction="row" alignItems="center" justifyContent="center" columnSpacing={3}>
              <Grid item xs={4}>
                <input
                  color="primary"
                  accept="application/pdf"
                  type="file"
                  onChange={handleChooseFile}
                  id="icon-button-file"
                  style={{ display: "none" }}
                />

                <TextField
                  value={selectedFile?.name ? selectedFile?.name : ""}
                  readOnly
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      borderRadius: "6px",
                      fontFamily: "Poppins-Regular !important",
                      height: "35px",
                    },
                    "& .MuiInputBase-input": {
                      paddingTop: 1,
                      paddingLeft: 2,
                      paddingBottom: 1,

                      fontSize: "12px",
                    },
                    "& .MuiOutlinedInput-root": {
                      paddingRight: "0px !important",
                    },
                  }}
                  id="userName"
                  variant={"outlined"}
                  placeholder="Selected File Name"
                  size="small"
                  name="email"
                  margin="normal"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <Tooltip title="Choose File">
                          <label htmlFor="icon-button-file">
                            <IconButton
                              component="span"
                              aria-label="icon-button-file"
                              // onChange={handleChooseFile}
                            >
                              <UploadFileIcon fontSize="20px" color="primary" />
                            </IconButton>
                          </label>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  value={fileName}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      borderRadius: "6px",
                      fontFamily: "Poppins-Regular !important",
                      height: "35px",
                    },
                    "& .MuiInputBase-input": {
                      // paddingTop: 1,
                      // paddingLeft: 2,
                      // paddingBottom: 1,

                      fontSize: "12px",
                    },
                  }}
                  id="userName"
                  variant={"outlined"}
                  onChange={(e) => setFileName(e.target.value)}
                  placeholder="Enter File Name"
                  size="small"
                  name="email"
                  fullWidth={true}
                  margin="normal"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>

              <Grid item>
                <Button
                  disableElevation
                  disabled={!selectedFile}
                  variant="contained"
                  fullWidth
                  onClick={handleDocUpload}
                  sx={{
                    width: "140px",
                    height: "35px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  <Typography variant="h6" color="white">
                    Upload File
                  </Typography>
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disableElevation
                  disabled={!selectedFile}
                  variant="contained"
                  fullWidth
                  onClick={handleDocUpload}
                  sx={{
                    width: "140px",
                    height: "35px",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  <Typography variant="h6" color="white">
                    Upload File
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                pb: selectedFile && workerDocs.documents.ok == false && 4,
              }}
            >
              {selectedFile && (
                <Paper elevation={1}>
                  <Grid
                    Container
                    display="flex"
                    flexDirection="row"
                    columnGap={1.2}
                    px={5}
                    pt={2.5}
                    pb={2.5}
                    mb={openAlert ? -3.5 : 0}
                    xs={12}
                  >
                    <Grid item xs={7}>
                      {Object.keys(workerDocs).length && workerDocs?.documents?.status ? (
                        <Grid item>
                          <Collapse in={openAlert}>
                            <Alert
                              onClose={handleAlertClose}
                              variant="filled"
                              icon={false}
                              // severity="error"
                              sx={{
                                backgroundColor: workerDocs?.documents?.ok ? "primary1Color" : "warning.main",
                                // padding: "",

                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontSize: "12px",
                                textAlign: "center",

                                borderRadius: "5px !important",
                                color: "#ffffff !important",
                                "&.MuiPaper-root": {
                                  color: "#ffffff !important",
                                },
                              }}
                            >
                              <Typography
                                color="white"
                                sx={{
                                  fontFamily: "Poppins-Regular !important",
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  color: "#ffffff !important",
                                }}
                              >
                                {workerDocs?.documents?.ok
                                  ? "File Uploaded Successfully "
                                  : workerDocs?.documents?.ok !== true
                                  ? getError(workerDocs?.documents?.payload?.error)
                                  : "Some Thing Went Wrong"}
                              </Typography>
                            </Alert>
                          </Collapse>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default DocumentsTab;
