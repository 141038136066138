import React from "react";
//material ui
import { FormControl, Grid, TextField, Toolbar, Typography } from "@mui/material";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
//components
import { AddEmployeeData } from "../../../../../store/actions/AddEmployeeData";

//constants
const textFiledStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "10px",
  },
};
const salaryDetails = {
  costToCustomer: "",
  edgeFee: "",
  grossPay: "",
  deduction: "",
};

function SuccessfullAddedEmployee(props) {
  //props
  const { navigatedFor } = props;

  const dispatch = useDispatch();
  //redux state
  const addEmployeeData = useSelector((state) => state.AddEmployeeData.addEmployeeData);
  //compnent state
  const [employeeSalaryDetails, setEmployeeSalaryDetails] = React.useState(salaryDetails);

  //functions
  const handleEmployeeSalaryDetails = (event) => {
    setEmployeeSalaryDetails({
      ...employeeSalaryDetails,
      [event.target.name]: event.target.value,
    });
  };

  //methods
  React.useEffect(() => {
    dispatch(
      AddEmployeeData({
        ...addEmployeeData,
        ...employeeSalaryDetails,
      })
    );
  }, [employeeSalaryDetails]);
  React.useEffect(() => {
    setEmployeeSalaryDetails(addEmployeeData);
  }, []);
  return (
    <>
      <Grid item>
        <Typography variant="h5" color="green">
          {navigatedFor == "addEmployee"
            ? "Employee Successfully Added"
            : navigatedFor == "editEmployee"
            ? "Employee Successfully Edited"
            : null}
        </Typography>
      </Grid>

      <Grid item container direction="row" justifyContent="center">
        <Typography variant="h5" color="gray">
          {navigatedFor == "addEmployee"
            ? "Click Next to Add Additional Information"
            : navigatedFor == "editEmployee"
            ? "Click Next to Edit Additional Information"
            : null}
        </Typography>
      </Grid>
    </>
  );
}

export default SuccessfullAddedEmployee;
