import React from "react";
//material ui
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Alert,
  List,
  ListItem,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
//icons
import { EmailOutlined, VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import GoogleIcon from "../../../Icons/googleIconSmall.png";

//enum
import { SignInScreenPermissions } from "../SignIn.enum";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import { AuthUser, ResetAuthUser } from "../../../../store/actions/AuthUser";
//router
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

//file
import GoogleSignup from "../SignUp/GoogleSignup";
import MicrosoftSignup from "../SignUp/MicrosoftSignup";
import { UserAdditionalInfo } from "../../../../store/actions/UserAdditionalInfo";
import { ResetGoogleSigunp } from "../../../../store/actions/Google/Google_signup";
import { GetPlatformConfig } from "../../../../store/actions/PlatformConfig";
import TextFieldV2 from "../../../common/TextFieldV2/TextFieldV2";

//constants
const userCredentials = {
  email: "",
  password: "",
  loginPortal: null,
};

// images
import loginImage from "../../../Icons/login-image.png";
import { baseUrl } from "../../../../util/APIBaseUrl";
import PageLoader from "../../../common/PageLoader";
import TermsAndPolicy from "components/common/TermAndPolicy";
import { USER_TYPES } from "constants";

function SignIn(props) {
  //props
  const { changeScreen } = props;
  const { handleSubmit } = useForm();

  const dispatch = useDispatch();
  let navigateTo = useNavigate();
  const location = useLocation();

  //component state
  const [showPassword, setShowPassword] = React.useState(false);
  const [showSetPassword, setShowSetPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [userCredential, setUserCredential] = React.useState(userCredentials);
  const [loginButtonClicked, setLoginButtonClicked] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  // redux state
  const authUserData = useSelector((state) => state.AuthUser.authUserData);
  const loadingAuthUserData = useSelector((state) => state.AuthUser.loadingAuthUserData);

  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const googleSignupInfo = useSelector((state) => state.GoogleSignup.googleSignupInfo);
  const loadingGoogleSignupInfo = useSelector((state) => state.GoogleSignup.loadingGoogleSignupInfo);

  // functions
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setUserCredential({
      ...userCredential,
      [event.target.name]: value,
    });
  };

  const handleTabChange = (value) => {
    setTab(value);
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              {(error?.msg ? error.msg : error?.description ? error.description : "Undefined Error") +
                " " +
                (error?.param ? error?.param : " ")}
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  const checkError = (field, view) => {
    if (loginButtonClicked) {
      if (view != "textField") {
        if (userCredential[field]?.key != null) {
          return false;
        } else return true;
      } else {
        if (userCredential[field] != "") {
          return false;
        } else return true;
      }
    }
  };

  // maps browerser route on to api call: Defaults to Customer  type login
  const routesMap = {
    0: "customer",
    1: "employee",
  };

  const loginHandler = (event) => {
    setLoginButtonClicked(true);
    dispatch(ResetGoogleSigunp({}));
    dispatch(ResetAuthUser({}));
    if (userCredential.email != "" && userCredential.password != "") {
      setOpenAlert(false);
      const loginPortal = routesMap[tab] ? routesMap[tab] : "admin";
      const request = `${baseUrl}/auth/${loginPortal}`;

      dispatch(
        AuthUser({
          userCredential,
          request,
          loginPortal,
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  //methods

  React.useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let accesstoken = localStorage.getItem("accessToken");
    let request = "";

    if (accesstoken && loggedInUser) {
      if (loggedInUser?.type == "customer") {
        request = `customer/${loggedInUser.customerId}/info`;
      } else if (loggedInUser?.type == "edge") {
        request = `user/${loggedInUser.userId}/summary`;
      } else if (loggedInUser?.type == "employee") {
        request = `employee/${loggedInUser.userId}`;
      }
      dispatch(UserAdditionalInfo(request));
    }

    if (Object.keys(authUserData).length > 0 && authUserData.ok != true) {
      setOpenAlert(true);
    }
  }, [authUserData]);

  React.useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let accesstoken = localStorage.getItem("accessToken");
    localStorage.setItem("communityLoginCheck", false);
    if (userAdditionalInfo && Object.keys(userAdditionalInfo).length && accesstoken && loggedInUser) {
      if (loggedInUser?.type == "customer") {
        if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          navigateTo("/addPaymentMethod");
        } else {
          navigateTo("/main/dashboard");
        }
      } else if (loggedInUser?.type == "edge") {
          navigateTo("/main/dashboard");
      
      } else if (loggedInUser?.type == "employee") {
          navigateTo("/main/dashboard");
      }
    }
    if (userAdditionalInfo && Object.keys(userAdditionalInfo).length > 0 && userAdditionalInfo.ok != true) {
      setOpenAlert(true);
    }
  }, [userAdditionalInfo]);

  React.useEffect(() => {
    dispatch(GetPlatformConfig({}));
    checkPayoneerCode();
  }, []);

  function checkPayoneerCode() {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    if (code) {
      localStorage.setItem("payoneerCode", code);
      navigateTo("/main/dashboard");
    }
  }

  return (
    <>
      {loadingAuthUserData == true ||
        loadingAdditionalInfo == true ||
        loadingplatformConfig == true ||
        loadingGoogleSignupInfo == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "#FFFFFF",
          overflowY: "auto",
        }}
      >
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding="20px 0px"
        >
          <Stack>
            <form onSubmit={handleSubmit(loginHandler)}>
              <Grid item container direction="column">
                <Grid item>
                  <Box
                    component="img"
                    sx={{
                      height: "auto",
                      maxWidth: "100%",
                    }}
                    alt="edge"
                    src="assets/Images/edge-logo.svg"
                  />
                </Grid>
                <Grid item>
                  <Box>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                      <Tab
                        label="Log In as Customer"
                        style={{
                          paddingLeft: "0px",
                          fontFamily: "Poppins-Medium",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        onClick={() => setTab(0)}
                      />
                      <Tab
                        label="Log In as Employee"
                        style={{
                          paddingLeft: "0px",
                          marginLeft: "14px",
                          fontFamily: "Poppins-Medium",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        onClick={() => setTab(1)}
                      />
                    </Tabs>
                  </Box>
                </Grid>

                <Grid sx={{ maxWidth: "318px", width: "100%" }}>
                  <Grid item sx={{ marginTop: "20px !important" }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium !important",
                        color: "#000000",
                        fontSize: "32px",
                        fontWeight: "600",
                        lineHeight: "44px",
                      }}
                    >
                      Sign In
                    </Typography>
                  </Grid>

                  <Grid item mt="20px">
                    <FormControl variant="outlined" fullWidth error>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #B2B4E2",
                          borderRadius: "12px",
                        }}
                      >
                        <EmailOutlined
                          sx={{
                            color: "#3D3E5C",
                            marginLeft: "18px",
                          }}
                        />
                        <TextFieldV2
                          label="Email Address"
                          variant="filled"
                          name="email"
                          id="email"
                          type="text"
                          onChange={handleInputChange}
                          error={checkError("email", "textField")}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid item mt="18px">
                    <FormControl variant="outlined" fullWidth>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #B2B4E2",
                          borderRadius: "12px",
                          paddingLeft: "4px",
                        }}
                      >
                        <TextFieldV2
                          label="Password"
                          variant="filled"
                          name="password"
                          id="password"
                          type={showPassword ? "text" : "password"}
                          onChange={handleInputChange}
                          error={checkError("password", "textField")}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOutlined
                                      sx={{
                                        color: "#3D3E5C",
                                      }}
                                    />
                                  ) : (
                                    <VisibilityOffOutlined
                                      sx={{
                                        color: "#3D3E5C",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid item mt="20px">
                    <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 22,
                                },
                              }}
                            />
                          }
                          label="Remember me"
                        />
                      </FormGroup>
                      <Link
                        sx={{
                          paddingTop: "5px",
                          color: "#000000",
                          fontSize: "12px",
                          fontWeight: "500",
                          fontFamily: "Poppins-Medium !important",
                          cursor: "pointer !important",
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          fontWeight={500}
                          color={"#000000"}
                          onClick={() => {
                            changeScreen(
                              tab === 0
                                ? SignInScreenPermissions.FORGOT_PASSWORD
                                : SignInScreenPermissions.FORGOT_PASSWORD_EMPLOYEE
                            );
                          }}
                          sx={{
                            fontFamily: "Poppins-Medium !important",
                            fontSize: "13px",
                            lineHeight: "8px",
                          }}
                        >
                          Forgot Password?
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>

                  {authUserData?.ok == false || googleSignupInfo?.ok == false ? (
                    <Grid item mt="20px">
                      <Alert
                        open={openAlert}
                        autoHideDuration={3500}
                        variant="filled"
                        icon={false}
                        sx={{
                          backgroundColor: "#F64A14",
                          padding: "initial",
                          px: "5px",
                          fontSize: "12px",
                          textAlign: "center",

                          borderRadius: "5px !important",
                          color: "#ffffff !important",
                          "&.MuiPaper-root": {
                            color: "#ffffff !important",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular !important",
                            fontWeight: "400",
                            fontSize: "13px",
                          }}
                        >
                          {authUserData?.ok == false
                            ? getError(authUserData?.payload?.error)
                            : googleSignupInfo?.ok == false
                              ? getError(googleSignupInfo?.payload?.error)
                              : "Enter all information before proceeding"}
                        </Typography>
                      </Alert>
                    </Grid>
                  ) : null}

                  <Grid item mt="20px">
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth={true}
                      sx={{
                        backgroundColor: "#3D43BB",
                        height: "54px",
                        fontFamily: "Poppins-Regular !important",
                        fontWeight: "500",
                        lineHeight: "20px",
                        borderRadius: "99px",
                        fontSize: "16px",
                        color: "white",
                        boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                      }}
                    >
                      Continue
                    </Button>
                  </Grid>

                  <Divider sx={{ margin: "20px 0px" }} />

                  <Grid item>
                    {platformConfig.ok && (
                      <Grid
                        item
                        sx={{
                          paddingTop: "8px !important",
                        }}
                      >
                        {location.pathname == "/" ? (
                          <GoogleSignup changeScreen={changeScreen} loginPortal={tab == 0 ? "customer" : "employee"} />
                        ) : null}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <Grid item container direction="column">
              <Grid sx={{ maxWidth: "318px", width: "100%" }}>
                <Grid item>
                  {platformConfig.ok && (
                    <Grid
                      item
                      sx={{
                        paddingTop: "12px !important",
                      }}
                    >
                      {location.pathname == "/" && platformConfig?.payload?.MICROSOFT_LOGIN_ENABLED === '1' && tab == 0 ? (
                        <MicrosoftSignup changeScreen={changeScreen} loginPortal={tab == 0 ? "customer" : "employee"} />
                      ) : null}
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ margin: "20px 0px" }} />
                <TermsAndPolicy userType={tab === 0 ? USER_TYPES.CUSTOMER : USER_TYPES.EMPLOYEE} />
                <Grid item mt="20px">
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      textAlign: "center",
                      color: "#B3B3B3",
                    }}
                  >
                    ©️ Copyright 2010 - 2023 Edge, LLC
                  </Typography>
                </Grid>
              </Grid>
              <Grid item textAlign="center">
                <Typography variant="h3Lite" fontSize="9px">
                  {process.env.REACT_APP_NAME}
                  {" " + process.env.REACT_APP_VERSION}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          backgroundColor="#3D43BB"
          minHeight="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              maxWidth: "528px",
              width: "100%",
              objectFit: "contain",
              backgroundColor: "#3D43BB",
              "@media screen and (min-width: 912px)": {
                objectFit: "cover",
                maxWidth: "400px",
                width: "100%",
              },
            }}
            alt="The house from the offer."
            src="assets/Images/login-image.png"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SignIn;