import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Grid,
  Typography,
  Snackbar,
  Alert,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  List,
  ListItem,
} from "@mui/material";

import { AssignEmployee, ResetAssignEmployee } from "../../../../../../store/actions/Workforce/AssignEmployee";
import { ResetUnAssignEmployee } from "../../../../../../store/actions/Workforce/UnAssignEmployee";
import { GetCustomersList, ResetGetCustomersList } from "../../../../../../store/actions/Customers/GetCustomerList";

import PageLoader from "../../../../../common/PageLoader";

import { getDateFromUtcFormat, getValue } from "../../../../CommonUtils";

import { employementInfoScreen } from "../../EmployementInfo.pages.enums";

const assignEmployeData = {
  companyName: { key: null, value: "" },
  dateOfJoining: new Date(),
  employementType: {
    key: "Full-Time",
    value: "Full Time",
  },
  department: { key: null, value: "None" },
  jobTitle: "",
};

const status = [
  { key: "unassigned", value: "UnAssigned" },
  { key: "assigned", value: "Assigned" },
  { key: "pending", value: "Pending" },
];

const employementTypeOptions = [
  { key: null, value: "None" },
  { key: "Full-Time", value: "Full Time" },
  { key: "Part-Time", value: "Part Time" },
];

const textFiledStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "5px",
    fontSize: "12px",
    fontFamily: "Poppins-Medium",
    color: "primary",
  },
};

function AssignToCustomer(props) {
  const dispatch = useDispatch();
  const { handlePageChange, editAssignEmployee } = props;

  //component state
  const [assignButtonClicked, setAssignButtonClicked] = React.useState(false);
  const [assignEmployeeData, setAssignEmployeeData] = React.useState(assignEmployeData);
  const [DropdownData_, setDropdownData_] = React.useState({});
  const [openAlert, setOpenAlert] = React.useState(false);
  const [timer, setTimer] = React.useState(null);

  const [openDropdownMenu, setOpenDropdownMenu] = React.useState(false);
  const [searchCustomer, setSearchCustomer] = React.useState(true);

  //redux state
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const assignEmployee = useSelector((state) => state.AssignEmployee.assignEmployee);
  const loadingAssignInfo = useSelector((state) => state.AssignEmployee.loadingAssignInfo);

  const getCustomerList = useSelector((state) => state.GetCustomerList.getCustomerList);
  const loadingGetCustomerList = useSelector((state) => state.GetCustomerList.loadingGetCustomerList);

  //functions

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const checkError = (field, view) => {
    if (assignButtonClicked) {
      if (view != "textField") {
        if (assignEmployeeData[field]?.key != null) {
          return false;
        } else return true;
      } else {
        if (assignEmployeeData[field] != "") {
          return false;
        } else return true;
      }
    }
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error, index) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              <Typography variant="h6" color="secondary" fontSize="12px">
                {(error?.msg ? error.msg : error?.description ? error.description : "") +
                  " " +
                  (error?.param ? error?.param : "")}
              </Typography>
            </ListItem>
          </List>
        );
      });
    } else {
      return null;
    }
  };

  const handleAssignEmployee = () => {
    let assignRequest = "assign";
    setAssignButtonClicked(true);

    if (
      assignEmployeeData?.companyName?.key != null &&
      assignEmployeeData?.employementType?.key != null &&
      assignEmployeeData?.department?.key != null &&
      assignEmployeeData?.jobTitle != ""
    ) {
      if (editAssignEmployee && getEmployeeDetails?.payload?.employeeId) {
        assignRequest = `editAssignment`;
      }
      dispatch(
        AssignEmployee({
          request: assignRequest,
          employeeId: getEmployeeDetails?.payload?.employeeId,
          assignEmployeeData: assignEmployeeData,
          customerId: assignEmployeeData.companyName.key,
          employmentType: assignEmployeeData.employementType.value,
          dateOfJoining: assignEmployeeData.dateOfJoining,
          department: assignEmployeeData.department.value,
          jobTitle: assignEmployeeData.jobTitle,
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  const handleDateChange = (date) => {
    setAssignEmployeeData({
      ...assignEmployeeData,
      dateOfJoining: date,
    });
  };

  const searchCompanyName = (event) => {
    let companyName = event.target.value;
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setSearchCustomer(companyName);

      if (companyName.length >= 3) {
        setOpenDropdownMenu(true);
        dispatch(
          GetCustomersList({
            customerName: companyName,
            loaderFlag: false,
          })
        );
      } else {
        setOpenDropdownMenu(false);
        dispatch(ResetGetCustomersList({}));
      }
    }, 500);
    setTimer(newTimer);
  };

  const callbackFromMultiSelect = (option, key) => {
    assignEmployeeData[key] = option;
    setOpenDropdownMenu(false);
    setAssignEmployeeData({
      ...assignEmployeeData,
    });

    if (option == null && key == "companyName") {
      dispatch(ResetGetCustomersList({}));
    }
  };

  const handleDataChange = (event) => {
    setAssignEmployeeData({
      ...assignEmployeeData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    // handleedit(false);
    handlePageChange(employementInfoScreen.beforeAssignToCustomer);
  };

  //methods

  React.useEffect(() => {
    if (Object.keys(assignEmployee).length && assignEmployee.ok) {
      setOpenAlert(false);
    } else if (Object.keys(assignEmployee).length && assignEmployee.ok == false) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
  }, [assignEmployee]);

  React.useEffect(() => {
    let customers = [];

    if (getCustomerList?.ok) {
      getCustomerList?.payload?.map((data, index) => {
        customers.push({
          key: data.customerId,
          value: data.companyName,
        });
      });
    }

    setDropdownData_({
      ...DropdownData_,

      customers,
    });
  }, [getCustomerList]);

  React.useEffect(() => {
    let countries = [];
    let customers = [];
    let departments = [];
    let companyNameSelectedOption = [{ key: null, value: "None" }];
    let employementTypeSelectedOption = [{ key: null, value: "None" }];
    let departmentSelectedOption = [{ key: null, value: "None" }];
    let dropdownData = {};
    dropdownData = JSON.parse(sessionStorage.getItem("dropdownData"));

    // setOpenAlert(false);
    dispatch(ResetAssignEmployee({}));
    dispatch(ResetUnAssignEmployee({}));

    if (dropdownData != undefined && Object.keys(dropdownData).length && dropdownData.ok) {
      dropdownData?.payload?.department?.map((data, index) => {
        departments.push({
          key: index,
          value: data.department,
        });
      });

      dropdownData?.payload?.country?.map((data, index) => {
        countries.push({
          key: data.countryCode2,
          value: data.countryCode2,
        });
      });
    }

    if (editAssignEmployee) {
      employementTypeSelectedOption = employementTypeOptions.filter(
        (employementType) => employementType?.value == getEmployeeDetails?.payload?.employmentType
      );

      departmentSelectedOption = departments.filter(
        (department) => department?.value == getEmployeeDetails?.payload?.department
      );

      setAssignEmployeeData({
        ...assignEmployeData,
        companyName: {
          // key: getEmployeeDetails?.payload?.customerInfo?.companyId,
          key: getEmployeeDetails?.payload?.customerInfo?.customerId,
          value: getEmployeeDetails?.payload?.customerInfo?.companyName,
        },
        // companyName: companyNameSelectedOption[0],
        dateOfJoining: getDateFromUtcFormat(getEmployeeDetails.payload?.startDate),
        employementType: employementTypeSelectedOption[0],
        department: departmentSelectedOption[0],
        jobTitle: getValue(getEmployeeDetails.payload?.jobTitle),
      });
    } else {
      setAssignEmployeeData(Object.assign({}, assignEmployeData));
    }

    setDropdownData_({
      ...DropdownData_,
      countries,
      customers,
      status,
      departments,
    });
  }, []);

  return (
    <>
      {loadingAssignInfo == true || loadingGET_EMPLOYEE_Details == true || loadingGetCustomerList == true ? (
        <PageLoader />
      ) : (
        <></>
      )}
      <Grid item container direction="row" rowSpacing={2} xs={12}>
        <Grid item container direction="row" columnSpacing={7} rowSpacing={2}>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <label>
                <Typography variant="h6" color="gray">
                  COMPANY NAME *
                </Typography>
              </label>
              <Autocomplete
                size="small"
                open={openDropdownMenu}
                // limitTags={0}
                id="companyName"
                clearOnBlur={false}
                disabled={editAssignEmployee}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "primary",
                  },

                  "& .MuiSvgIcon-root": {
                    fill: editAssignEmployee ? "grey" : "#1161D5",
                  },
                }}
                options={DropdownData_?.customers ? DropdownData_?.customers : []}
                getOptionLabel={(option) => option.value}
                onChange={(event, option) => {
                  callbackFromMultiSelect(option, "companyName");
                }}
                // onChange={(event, option) => {
                //     callbackFromMultiSelect(option, "companyName");
                // }}
                value={assignEmployeeData.companyName}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onChange={searchCompanyName}
                      value={searchCustomer}
                      clearOnBlur={false}
                      // inputProps={{
                      //     ...params.inputProps,
                      //     autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                      margin="dense"
                      variant="outlined"
                      sx={textFiledStyle}
                      error={checkError("companyName")}
                      fullWidth
                      placeholder="Type To Search Customer"
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <label>
                <Typography variant="h6" color="gray">
                  DEPARTMENT *
                </Typography>
              </label>
              <Autocomplete
                size="small"
                limitTags={0}
                id="department"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "2px",
                    borderRadius: "5px",
                    fontSize: "11px",
                  },

                  "& .MuiSvgIcon-root": {
                    fill: "#1161D5",
                  },
                }}
                options={DropdownData_?.departments ? DropdownData_?.departments : []}
                getOptionLabel={(option) => option.value}
                onChange={(event, option) => {
                  callbackFromMultiSelect(option, "department");
                }}
                value={assignEmployeeData.department}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      margin="dense"
                      variant="outlined"
                      sx={textFiledStyle}
                      error={checkError("department")}
                      fullWidth
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <label>
                <Typography variant="h6" color="gray">
                  JOB TITLE *
                </Typography>
              </label>
              <TextField
                sx={textFiledStyle}
                margin="dense"
                id="jobTitle"
                name="jobTitle"
                type="text"
                error={checkError("jobTitle", "textField")}
                value={assignEmployeeData.jobTitle}
                onChange={handleDataChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <label>
                <Typography variant="h6" color="gray">
                  DATE OF JOINING
                </Typography>
              </label>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["day", "month", "year"]}
                  id="dateOfJoining"
                  value={assignEmployeeData.dateOfJoining}
                  // disabled={editAssignEmployee}
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                  // minDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          fontSize: "11px",
                        },

                        "& .MuiSvgIcon-root": {
                          fill: editAssignEmployee ? "grey" : "#1161D5",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <label>
                <Typography variant="h6" color="gray">
                  EMPLOYEMENT TYPE *
                </Typography>
              </label>
              <Autocomplete
                size="small"
                limitTags={0}
                id="employementType"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "2px",
                    borderRadius: "5px",
                    fontSize: "11px",
                  },

                  "& .MuiSvgIcon-root": {
                    fill: "#1161D5",
                  },
                }}
                options={employementTypeOptions}
                getOptionLabel={(option) => option.value}
                onChange={(event, option) => {
                  callbackFromMultiSelect(option, "employementType");
                }}
                value={assignEmployeeData.employementType}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      margin="dense"
                      variant="outlined"
                      sx={textFiledStyle}
                      error={checkError("employementType")}
                      fullWidth
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={12}
          columnSpacing={5}
          // sx={{ mt: 2 }}
        >
          <Grid item>
            <Button
              id="cancel"
              fullWidth
              sx={{
                width: "100px",
                borderRadius: "5px",
              }}
              onClick={() => {
                handleCancel();
              }}
            >
              <Typography variant="h6">Cancel</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="assignEmployee"
              variant="contained"
              disableElevation
              fullWidth
              sx={{
                width: "100px",
                borderRadius: "5px",
              }}
              onClick={handleAssignEmployee}
            >
              <Typography variant="h6" color="white">
                {editAssignEmployee ? "Save" : "Assign"}
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item container direction="row" columnSpacing={3} justifyContent="flex-end">
          <Grid item textAlign="center">
            <Snackbar
              open={openAlert}
              autoHideDuration={2500}
              onClose={handleAlertClose}
              sx={{
                "&.MuiSnackbar-root": {
                  position: "relative",
                  bottom: 9,
                  left: 0,
                },
              }}
            >
              <Alert onClose={handleAlertClose} severity="error">
                {assignEmployee?.ok == false ? getError(assignEmployee?.payload?.error) : " REQUIRED FIELD MISSING!"}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AssignToCustomer;
