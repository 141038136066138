import * as React from "react";
import Box from "@mui/material/Box";
import { FormControl, Grid, Typography, Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function Filter(props) {
  const { data, callbackFromMultiSelect, selectedOptions, handleReset, searchCustomer } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const getSelectedOption = (options, selectedOption) => {
    if (selectedOption) {
      if (!options?.length) {
        return selectedOption;
      }
      return selectedOption.map((opt) => {
        const matchingOption = options.find((x) => x?.key === opt?.key);
        return matchingOption || opt;
      });
    }
    return [];
  };

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Grid item>
          <Typography
            variant="h3"
            color="#5c5c5cbd"
            letterSpacing="0.04rem"
            fontSize="13px"
            fontFamily="Poppins-SemiBold"
          >
            FILTERS
          </Typography>
        </Grid>
        <Grid
          item
          onClick={(event) => {
            handleReset(event);
          }}
        >
          <Typography
            variant="h3"
            color="primary"
            fontSize="13px"
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            RESET
          </Typography>
        </Grid>
      </Grid>
      {Object.keys(data).map((header) => {
        return (
          <Grid container direction="column" rowSpacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label>
                  <Typography variant="h6" color="info.alt" margin="normal" pb="0.2rem">
                    {" "}
                    {`Select ${header}`}
                  </Typography>
                </label>

                <Autocomplete
                  multiple
                  clearOnBlur={false}
                  size="small"
                  limitTags={0}
                  id="standard"
                  sx={{
                    mb: 1,
                    "& .MuiOutlinedInput-root": {
                      padding: "2px",
                      borderRadius: "5px",
                      fontSize: "12px",
                      height: "fit-content !important",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      height: "fit-content",
                    },
                  }}
                  options={data[header]}
                  value={getSelectedOption(data[header], selectedOptions?.[header])}
                  onChange={(event, option) => {
                    if (event && (event.key === "Enter" || event.keyCode === 13)) {
                      return; // Do nothing if Enter key was pressed
                    }
                    callbackFromMultiSelect(option, header);
                  }}
                  getOptionLabel={(option) => option.value}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.value}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      maxRows={1}
                      minRows={1}
                      variant="outlined"
                      onChange={(event) => {
                        if (header === "customers") searchCustomer(event.target.value);
                      }}
                      placeholder={
                        selectedOptions[header]?.length
                          ? null
                          : header == "customers"
                            ? "Type To Search Customer"
                            : header === "paymentMethodType"
                              ? "Payment Method Type"
                              : `Select ${header.replace(/^./, header[0].toUpperCase())}`
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}

export default Filter;
