//icon
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
export const employeeProfileFields = (
  dropdownData_,
  discountField,
  discountDurationOptions,
  Months,
  genderOptions
) => {
  return [
    {
      name: 'firstName',
      label: 'FIRST NAME *',
      type: 'text',
      maxLength: 30,
      lg: 4,
      required: true,
    },
    {
      name: 'lastName',
      label: 'LAST NAME *',
      type: 'text',
      maxLength: 30,
      lg: 4,
      required: true,
    },

    {
      name: 'email',
      label: 'EMAIL ADDRESS *',
      type: 'text',
      lg: 4,
      required: true,
    },
    {
      name: 'jobTitle',
      label: 'JOB TITLE',
      type: 'text',
      lg: 4,
    },
    {
      name: 'birthday',
      label: 'DATE OF BIRTH',
      type: 'datePicker',
      lg: 4,
      disableFuture: true,
      maxDate: new Date(),
    },

    {
      name: 'edgeJoiningDate',
      label: 'Edge Joining Date',
      type: 'datePicker',
      lg: 4,
      disableFuture: false,
    },

    {
      name: 'contactNumber',
      label: 'CONTACT NUMBER',
      type: 'number',
      maxLength: 20,
      lg: 4,
    },
    {
      name: 'gender',
      label: 'GENDER',
      type: 'dropdown',
      required: false,
      lg: 4,
      options: genderOptions,
    },
    {
      name: 'linkedUrl',
      label: 'Linkedin Url',
      type: 'text',
      lg: 4,
      required: false,
    },
    {
      name: 'residentialAddress',
      label: 'RESIDENTIAL ADDRESS *',
      type: 'text',
      maxLength: 100,
      lg: 12,
      required: true,
    },

    {
      name: 'city',
      label: 'CITY',
      type: 'text',
      lg: 4,
    },
    {
      name: 'state',
      label: 'STATE',
      type: 'text',
      lg: 4,
    },
    {
      name: 'code',
      label: 'ZIP',
      type: 'text',
      lg: 4,
    },
    {
      name: 'country',
      label: 'COUNTRY *',
      type: 'dropdown',
      required: true,
      lg: 4,
      options: dropdownData_.countries,
    },
    {
      name: 'allowedForPayoneer',
      label: 'Enable Edge Wallet',
      type: 'switch',
      lg: 4,
      required: false,
    },

    // {
    //     name: "discount",
    //     type: "checkBox",
    //     checkboxLabel: "Discount",
    //     lg: 4,
    // },

    // {
    //     type: "cascaded",
    //     lg: 4,
    //     fields: [
    //         {
    //             name: "discountPercentage",
    //             label: discountField.discountPercentageLabel,
    //             disabled: discountField.disabled,
    //             type: "number",
    //             lg: 6,
    //             required: discountField.requiredDiscountPercentage,
    //             // icon: "%",
    //         },
    //         {
    //             name: "flatDiscount",
    //             label: discountField.flatDiscountLabel,
    //             disabled: discountField.disabled,
    //             type: "number",
    //             lg: 6,
    //             required: discountField.requiredflatDiscount,

    //             icon: (
    //                 <AttachMoneyIcon fontSize="12" color="secondary.alt" />
    //             ),
    //         },
    //     ],
    // },

    // {
    //     name: "startingDate",
    //     label: "STARTING DATE",
    //     type: "datePicker",
    //     lg: 4,
    //     disabled: discountField.disabled,
    //     // disableFuture: true,
    //     // maxDate: new Date(),
    // },

    // {
    //     name: "discountDuration",
    //     label: "DISCOUNT DURATION",
    //     type: "dropdown",
    //     disabled: discountField.disabled,
    //     // required: true,
    //     lg: 4,
    //     options: discountDurationOptions,
    // },
  ];
};
